import { Component, OnInit, Input, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AppComponentClass } from '../../service/AppComponent.class';
import { DialerService } from '../dialer.service';
import { Scripts, FormScripts } from '../model/script.class';
import { Mortgage, MortgageForm } from '../model/mortgage.class';



@Component({
  selector: 'dialer-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})

export class CommentComponent extends AppComponentClass<Mortgage, MortgageForm>  {
    public form:FormGroup;
    private __data = new BehaviorSubject<Mortgage>(null);
    public solution = this.__data.asObservable();

    // public optmenu:CareerScripts[];
    public curscript: any;

    constructor(public edata: DialerService, protected router: Router, protected fb: FormBuilder, protected msg: MatSnackBar) {
        super(edata, router, fb, msg);
    }
    @Input() set editdata(v: Mortgage) {
        this.debug_log("Setting current data for script to ");
        console.log("vishal",v);
        this.__data.next(v);
    }

    ngOnInit() {
        this.solution.subscribe(res => {

            if (res == null) 
            {
                this.cdata = new MortgageForm(null);
                this.form=this.cdata.makeform(this.fb);
            }
            else {
                
                this.cdata= new MortgageForm(res);
                this.form=this.cdata.makeform(this.fb);
            }

        });
    }

    onSubmit()
    {
      if(this.cdata.saveForm(this.form))
        super.onSubmit();
    }
    

    warning(){
      if(this.cdata.comments_ != this.form.get("comments_").value)
      this.cdata.comments_ = this.form.get("comments_").value;
       
      
    }
    

    changeEvnt(key:string){
        console.log("ashdgkey",key);
        if(key == 'comments_')
      this.cdata.comments_ = this.form.get("comments_").value;

      localStorage.setItem("editmod", JSON.stringify(this.cdata));
      console.log("OnChange events called edit data : Key "+key+" Value :"+this.form.get(key).value);
      console.log(localStorage.getItem("editmod"));
       
      




      var editFromLocalStorage = JSON.parse(localStorage.getItem("edit"));
      editFromLocalStorage.comments_ = this.cdata.comments_;
      localStorage.setItem("edit", JSON.stringify(editFromLocalStorage));
      let current=parseInt(localStorage.getItem("current_lead"));

    let search_lead=parseInt(localStorage.getItem("search_lead"));
    if(search_lead!=0)
    {
      current = search_lead;
    }
    console.log("current_lead: "+current);

     // update contact localstorage data
     var contactsFromLocalStorage = JSON.parse(localStorage.getItem("contacts"));
     contactsFromLocalStorage[current].edit = JSON.parse(localStorage.getItem("edit"));
     localStorage.setItem("contacts",JSON.stringify(contactsFromLocalStorage));

      
    }

    

}
