import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Option } from "../model/model.class";
import { Scripts, Dialer } from "../../mortgage/model/model.class";

@Injectable()
export class Theme {
  private __data = new BehaviorSubject<any>("default");
  public themecolor = this.__data.asObservable();

  private __event = new BehaviorSubject<number>(0);
  public event = this.__event.asObservable();

  private __phone = new BehaviorSubject<boolean>(false);
  public phone = this.__phone.asObservable();

  private __lead = new BehaviorSubject<number>(-1);
  public lead = this.__lead.asObservable();

  private __newlead = new BehaviorSubject<boolean>(false);
  public newlead = this.__newlead.asObservable();

  private __searchlead = new BehaviorSubject<string>("");
  public searchlead = this.__searchlead.asObservable();

  private __showmenu = new BehaviorSubject<boolean>(true);
  public showmenu = this.__showmenu.asObservable();

  private __getbalance = new BehaviorSubject<boolean>(false);
  public getbalance = this.__getbalance.asObservable();

  private __updatebalance = new BehaviorSubject<boolean>(false);
  public updatebalance = this.__updatebalance.asObservable();

  private __currentdispo = new BehaviorSubject<number>(0);
  public currentdispo = this.__currentdispo.asObservable();

  private __sipdata = new BehaviorSubject<any>(null);
  public sipjsdata = this.__sipdata.asObservable();

  private __sipsession = new BehaviorSubject<any>(null);
  public sipjssession = this.__sipsession.asObservable();

  private __callerid = new BehaviorSubject<any>(null);
  public callerid = this.__callerid.asObservable();

  private __savesms = new BehaviorSubject<any>(null);
  public savesms = this.__savesms.asObservable();

  private __dataemail = new BehaviorSubject<Option[]>(null);
  public emailsolution = this.__dataemail.asObservable();

  private __datasms = new BehaviorSubject<Option[]>(null);
  public smsolution = this.__datasms.asObservable();

  private __datavd = new BehaviorSubject<Option[]>(null);
  public vdsolution = this.__datavd.asObservable();

  private __datascript = new BehaviorSubject<Scripts[]>(null);
  public scriptsolution = this.__datascript.asObservable();

  private __datasemail = new BehaviorSubject<any>(null);
  public saveemail = this.__datasemail.asObservable();

  private __datavdsms = new BehaviorSubject<any>(null);
  public savevdmsg = this.__datavdsms.asObservable();

  private __autodial = new BehaviorSubject<number>(null);
  public solautodial = this.__autodial.asObservable();

  private __startautodial = new BehaviorSubject<number>(null);
  public startautodial = this.__startautodial.asObservable();

  private __stopautodial = new BehaviorSubject<number>(null);
  public stopautodial = this.__stopautodial.asObservable();

  private __callback = new BehaviorSubject<Dialer>(null);
  public callback = this.__callback.asObservable();

  private __blockautodial = new BehaviorSubject<boolean>(true);
  public blockautodial = this.__blockautodial.asObservable();

  private __showdialpad = new BehaviorSubject<boolean>(false);
  public showdialpad = this.__showdialpad.asObservable();

  public CallBackLead(v: Dialer) {
    this.__callback.next(v);
  }

  public StopAutodial(v: number) {
    this.__stopautodial.next(v);
  }

  public StartAutodial(v: number) {
    this.__startautodial.next(v);
  }

  public ChangeAutodial(v: number) {
    this.__autodial.next(v);
  }

  public SaveVdSms(v: any) {
    if (v != this.__datavdsms.getValue()) this.__datavdsms.next(v);
  }

  public SaveEmail(v: any) {
    if (v != this.__datasemail.getValue()) this.__datasemail.next(v);
  }

  public ChangeScript(v: Scripts[]) {
    this.__datascript.next(v);
  }

  public emmailtmpdata(v: Option[]) {
    this.__dataemail.next(v);
  }

  public vdtmp(v: Option[]) {
    this.__datavd.next(v);
  }

  public smsdata(v: Option[]) {
    this.__datasms.next(v);
  }

  public ChangeCallerID(v: string) {
    this.__callerid.next(v);
  }

  public ChangeSipsession(v: string) {
    this.__sipsession.next(v);
  }

  public ChangeSipData(v: string) {
    this.__sipdata.next(v);
  }

  public ChangeColor(v: string) {
    this.__data.next(v);
  }

  public ChangeEvent(v: number) {
    this.__event.next(v);
  }

  public ChangePhone(v: boolean) {
    this.__phone.next(v);
  }

  public ChangeLead(v: number) {
    this.__lead.next(v);
  }

  public NewLead(v: boolean) {
    this.__newlead.next(v);
  }

  public SearchLead(v: string) {
    this.__searchlead.next(v);
  }

  public GetBalance(v: boolean) {
    this.__getbalance.next(v);
  }

  public UpdateBalance(v: boolean) {
    this.__updatebalance.next(v);
  }

  public showMenu(v: boolean) {
    this.__showmenu.next(v);
  }

  public currentDispo(v: number) {
    this.__currentdispo.next(v);
  }

  public changeSms(v: any) {
    if (v != this.__savesms.getValue()) this.__savesms.next(v);
  }

  public blockAutodial(v: boolean) {
    this.__blockautodial.next(v);
  }

  public showDialpad(v: boolean) {
    this.__showdialpad.next(v);
  }

  constructor() {}
}
