import { Component, OnInit, Input } from '@angular/core';
import { environment } from '../../../environments/environment';
import { GraphReportService } from './graph-report.service';


import { ViewChild} from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
  {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  {position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C'},
  {position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N'},
  {position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O'},
  {position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F'},
  {position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne'},
  {position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na'},
  {position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg'},
  {position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al'},
  {position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si'},
  {position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P'},
  {position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S'},
  {position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl'},
  {position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar'},
  {position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K'},
  {position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca'},
];

@Component({
  selector: 'app-graph-report',
  templateUrl: './graphical-reports.component.html',
  styleUrls: ['./graphical-reports.component.css']
})

export class GraphicalReportsComponent implements OnInit {
  private debug=environment.production;

  @Input() displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];  // using this tabel static data 
  @Input() columnsToDisplay: string[] = this.displayedColumns.slice();   // for colums data show in tabel 
  // @input() column : any;

  @Input() iswhere:boolean=false;
  @Input() isfilter:boolean=false;
  @Input() mod:string;
  @Input() where:string;
  @Input() filter:string[];
  @Input() type:string;

  /* Chart Variables */
  @Input() title: string;
  @Input() column:string[]=[];
  @Input() chartType:any;
  @Input() chartColors:any;
  @Input() chartOptions:any= {
    responsive: true
  };

  @Input() legend:boolean=true;
  public graphlabel:any;
  public graphdata:any;
  private col:number=1;
  /* Chart Variables */
  
  constructor(protected data: GraphReportService) { }

  public debug_log(log:any)
  {
    if(!this.debug)
      console.log(JSON.stringify(log));
  }

  
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

  loadDataSource(type:string)
  {
    if(this.isfilter)
    {
      this.data.getServiceParam(this.type, this.filter).subscribe(res=>{
        this.debug_log(res);
        this.makeGraph(type, res);
      });
    }
    else
    {
      this.data.getService(this.type).subscribe(res=>{
        this.debug_log(res);
        this.makeGraph(type, res);
      });
    }

    if(this.iswhere)
    {
      this.data.getService(this.type,this.where).subscribe(res=>{
        this.debug_log(res);
        this.makeGraph(type, res);
      });
    }
    else
    {
      this.data.getService(this.type).subscribe(res=>{
        this.debug_log(res);
        this.makeGraph(type, res);
      });
    }
  }

  makeGraph(type:string, data:any)
  {
    if(type=="pie")
        this.pie_graph(data);
    if(type=="line")
        this.line_graph(data);
    if(type=="bar")
        this.bar_graph(data);
    if(type=="area")
        this.area_graph(data);
  }

  pie_graph(displaydata)
  {
      let tmplabel:string[]=[];
      let tmpdata:string[]=[];
      displaydata.forEach(element=>{
          tmplabel.push(element.label);
          tmpdata.push(element.data);
      });

      this.graphdata=tmpdata;
      this.graphlabel=tmplabel;
  }

  line_graph(displaydata)
  {
    let tmplabel:string[]=[];
    let tmpdata1:string[]=[];
    let tmpdata2:string[]=[];
    let tmpdata3:string[]=[];
    let tmpdata4:string[]=[];
    let tmpdata5:string[]=[];
    
    displaydata.forEach(element=>{
        tmplabel.push(element.label);
        if(this.col>=1)
          tmpdata1.push(element.line1);
        if(this.col>=2)
          tmpdata2.push(element.line2);
        if(this.col>=3)
          tmpdata3.push(element.line3);
        if(this.col>=4)
          tmpdata4.push(element.line4);
        if(this.col>=5)
          tmpdata5.push(element.line5);
    });
    console.log(tmpdata1);
    let tmp:any[]=[];

    if(this.col>=1)
      tmp.push({data : tmpdata1, label: this.column[0]});
    if(this.col>=2)
      tmp.push({data : tmpdata2, label: this.column[1]});
    if(this.col>=3)
      tmp.push({data : tmpdata3, label: this.column[2]});
    if(this.col>=4)
      tmp.push({data : tmpdata4, label: this.column[3]});
    if(this.col>=5)
      tmp.push({data : tmpdata5, label: this.column[4]});
    
    console.log(tmp);
    this.graphdata=tmp;
    this.graphlabel=tmplabel;
  }

  area_graph(displaydata)
  {

  }

  bar_graph(displaydata)
  {
    let tmplabel:string[]=[];
    let tmpdata1:string[]=[];
    let tmpdata2:string[]=[];
    let tmpdata3:string[]=[];
    let tmpdata4:string[]=[];
    let tmpdata5:string[]=[];

    displaydata.forEach(element=>{
        tmplabel.push(element.label);
        if(this.col>=1)
          tmpdata1.push(element.line1);
        if(this.col>=2)
          tmpdata2.push(element.line2);
        if(this.col>=3)
          tmpdata3.push(element.line3);
        if(this.col>=4)
          tmpdata4.push(element.line4);
        if(this.col>=5)
          tmpdata5.push(element.line5);
    });
    let tmp:any[]=[];

    if(this.col>=1)
      tmp.push({data : tmpdata1, label: this.column[0]});
    if(this.col>=2)
      tmp.push({data : tmpdata2, label: this.column[1]});
    if(this.col>=3)
      tmp.push({data : tmpdata3, label: this.column[2]});
    if(this.col>=4)
      tmp.push({data : tmpdata4, label: this.column[3]});
    if(this.col>=5)
      tmp.push({data : tmpdata5, label: this.column[4]});
    
    console.log(tmp);
    this.graphdata=tmp;
    this.graphlabel=tmplabel;
  }

  ngOnInit()
  {
      /* this.data.frm_label.subscribe(res=>{this.tag_label=res;});
      this.data.status.subscribe(res=>{this.state=res;}); */

      this.data.mod(this.mod);
      this.col=this.column.length;

      if(this.chartType=="line" || this.chartType=="bar")
      {
        this.chartColors= [
          {
              backgroundColor: 'rgba(220,220,220,0.2)',
              borderColor: 'rgba(220,220,220,1)',
              borderWidth: 2,
              pointBackgroundColor: 'rgba(220,220,220,1)',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgba(220,220,220,1)'
          },
          {
              backgroundColor: 'rgba(151,187,205,0.2)',
              borderColor: 'rgba(151,187,205,1)',
              borderWidth: 2,
              pointBackgroundColor: 'rgba(151,187,205,1)',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgba(151,187,205,1)'
          }
        ];
      }

      if(this.chartType=="pie")
      {
        this.chartColors=[{
          hoverBorderColor: ['rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)', 'rgba(0, 0, 0, 0.1)'],
          hoverBorderWidth: 0,
          backgroundColor: ["#F7464A", "#46BFBD", "#FDB45C", "#949FB1", "#4D5360"],
          hoverBackgroundColor: ["#FF5A5E", "#5AD3D1", "#FFC870", "#A8B3C5","#616774"]
        }];  
      }

      this.loadDataSource(this.chartType);


      this.dataSource.paginator = this.paginator; // using this for table
  }




  // using for table - data show 

  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  @ViewChild(MatPaginator) paginator: MatPaginator;

 
}
