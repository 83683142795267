import { FormBuilder, FormGroup} from "@angular/forms";
import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent
  } from 'angular-calendar';

  
  // Starting  PersonalInfo Module

  export interface PersonalInfo{
    birth_date : string,
    spouse_birth_date : string,
    age : string,
    spouse_age : string, 
    occupation : string,
    spouse_occupation : string,
    height : string,
    spouse_height : string,
    weight : string,
    spouse_weight : string,
    smoker : string,
    spouse_smoker : string,
    health_issue : string,
    spouse_health_issue : string,
    lender : string,
    beneficiary : string,
    mortgage_amount : string,
    lead_level : string,
    purchase_refi : string,
    assign_date : string,
    home_value : string,
    status : string,
    mortgage_term : string,
    referrer : string,
    monthly_mortage_payment : string,
    reason:string
}

export interface HTTPPersonalInfo
{
    birth_date : string,
    spouse_birth_date : string,
    age : string,
    spouse_age : string, 
    occupation : string,
    spouse_occupation : string,
    height : string,
    spouse_height : string,
    weight : string,
    spouse_weight : string,
    smoker : string,
    spouse_smoker : string,
    health_issue : string,
    spouse_health_issue : string,
    lender : string,
    beneficiary : string,
    mortgage_amount : string,
    lead_level : string,
    purchase_refi : string,
    assign_date : string,
    home_value : string,
    status : string,
    mortgage_term : string,
    referrer : string,
    monthly_mortage_payment : string,
    reason:string
}

export class PersonalInfoForm implements HTTPPersonalInfo{
    public  birth_date : string;
    public  age : string;
    public occupation : string;
    public spouse_occupation : string;
    public  spouse_birth_date : string;
    public  spouse_age : string;
    public  lender : string;
    public mortgage_amount : string;
    public purchase_refi : string;
    public  home_value : string;
    public  mortgage_term : string;
    public  monthly_mortage_payment : string;
    public   beneficiary : string;
    public  smoker : string;
    public  height : string;
    public  weight : string;
    public  health_issue : string;
    public  spouse_smoker : string;
    public  spouse_height : string;
    public  spouse_weight : string;
    public   spouse_health_issue : string;
    public  lead_level : string;
    public  assign_date : string;
    public   status : string;
    public   referrer : string;
    public reason:string;
 


    constructor(data : PersonalInfo) 
    {
        if(data==null)
        {
            this.birth_date="";
            this.age="";
            this.occupation="";
            this.spouse_occupation="",
            this.spouse_birth_date="";
            this.spouse_age="";
            this.lender="";
            this.mortgage_amount="";
            this.purchase_refi="";
            this.home_value="";
            this.mortgage_term="";
            this.monthly_mortage_payment="";
            this.beneficiary="";
            this.smoker="";
            this.height="";
            this.weight="";
            this.health_issue="";
            this.spouse_smoker="";
            this.spouse_height="";
            this.spouse_weight="";
            this.spouse_health_issue="";
            this.lead_level="";
            this.assign_date="";
            this.status="";
            this.referrer="";
            this.reason="";
      
        }
        else
        {
            this.birth_date            = data.birth_date;
            this.age                   = data.age;
            this.occupation            = data.occupation;
            this.spouse_occupation     = data.spouse_occupation;
            this.spouse_birth_date     = data.spouse_birth_date;
            this.spouse_age            = data.spouse_age;

            this.lender                = data.lender;
            if(data.lender)
                this.lender            = (data.lender).replace(/\&amp;/g, '&');

            this.mortgage_amount       = data.mortgage_amount;
            this.purchase_refi         = data.purchase_refi;
            this.home_value            = data.home_value;
            this.mortgage_term         = data.mortgage_term;
            this.monthly_mortage_payment= data.monthly_mortage_payment;
            this.beneficiary            = data.beneficiary;
            this.smoker                 = data.smoker;

            this.height                 = data.height;
            if(data.height)
                this.height             = (data.height).replace(/\&quot;/g, '"');

            this.weight                 = data.weight;
            this.health_issue           = data.health_issue;
            if(data.health_issue)
                this.health_issue           = (data.health_issue).replace(/\&amp;/g, '&');
            this.spouse_smoker          = data.spouse_smoker;

            this.spouse_height              = data.spouse_height;
            if(data.spouse_height)
                this.spouse_height          = (data.spouse_height).replace(/\&quot;/g, '"');

            this.spouse_weight          = data.spouse_weight;

            this.spouse_health_issue    = data.spouse_health_issue;
            if(data.spouse_health_issue)
                this.spouse_health_issue    = (data.spouse_health_issue).replace(/\&amp;/g, '&');

            this.lead_level             = data.lead_level;
            this.assign_date            = data.assign_date;
            this.status                 = data.status;
            this.referrer               = data.referrer;
            this.reason                 = data.reason;
        }
    }

    public makeform(fb:FormBuilder):FormGroup
    {
        return fb.group({
          
            birth_date             :[this.birth_date],
            age             :[this.age],
            occupation             :[this.occupation],
            spouse_occupation             :[this.spouse_occupation],
            spouse_birth_date             :[this.spouse_birth_date],
            spouse_age             :[this.spouse_age],
            lender             :[this.lender],
            mortgage_amount             :[this.mortgage_amount],
            purchase_refi             :[this.purchase_refi],
            home_value             :[this.home_value],
            mortgage_term             :[this.mortgage_term],
            monthly_mortage_payment             :[this.monthly_mortage_payment],
            beneficiary             :[this.beneficiary],
            smoker             :[this.smoker],
            height             :[this.height],
            weight             :[this.weight],
            health_issue             :[this.health_issue],
            spouse_smoker             :[this.spouse_smoker],
            spouse_height             :[this.spouse_height],
            spouse_weight             :[this.spouse_weight],
            spouse_health_issue             :[this.spouse_health_issue],
            lead_level             :[this.lead_level],
            assign_date             :[this.assign_date],
            status             :[this.status],
            referrer             :[this.referrer],
            reason             :[this.reason]         
        });
    }

    public saveForm(fg:FormGroup):boolean
    {
        if(fg.valid)
        {

            this.birth_date                = fg.get("birth_date").value;
           
            return true;
        }
        else
        {
            return false;
        }
    }
  }

   //End PersonalInfo form