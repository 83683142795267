import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Theme } from '../service/AutoTheme';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor( public theme:Theme) { }
  @Output()  Schedule = new EventEmitter();

  ngOnInit() {
    
  }


  onClick(sum)
  {
    console.log("footer page dispo click:"+ sum)
    this.theme.currentDispo(sum);
  }
}
