import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.css']
})
export class ToggleComponent {
  @Input() selected : boolean=true;
  @Input() value1  : string = 'Start Autodial';
  @Input() value2  : string = 'Click-To-Call';
  @Input() value3 : string = "Autodial Running";
  @Input() value4 : string = "Stop Autodial";
  
  @Output() OnChange: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    console.log(this.selected);
  }

  OnClick(event:number)
  {
    console.log(event);
    this.OnChange.emit(event);
  }

}
