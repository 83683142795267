import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Dialer } from '../dialer/model/dialer.class';
import { MortgageService } from '../../mortgage/mortgage.service';
//import {ContactSearchPipe} from './keypipe/searchpipe';


@Component({
  selector: 'app-leadlist',
  templateUrl: './leadlist.component.html',
  styleUrls: ['./leadlist.component.css']
})
export class LeadlistComponent implements OnInit {
  comments: any[];
  sms: any[];
  contacts: any;

  @Input() data: Dialer[];
  @Output() rowclick: EventEmitter<Dialer> = new EventEmitter();
  searchtext = "";

  constructor(private mortgageService: MortgageService,) {
      
  }

  ngOnInit() {
    this.searchtext = "";
  }

  onLeadClick(data: Dialer) {
    //console.log("this leadliist component"+JSON.stringify(data));
    this.rowclick.emit(data);
    this.searchtext = "";
    this.mortgageService.getSMS().subscribe((sms) => {
      this.sms = sms;
    },
      (error) => {
        this.sms = [];
      }
    );
    this.mortgageService.getComments().subscribe((comments) => {
      this.comments = comments;
    },
      (error) => {
        this.comments = [];
      }
    );
    
    this.mortgageService.getEmail().subscribe((contacts) => {
      this.contacts = contacts;
    },
      (error) => {
        this.contacts = [];
      }
    );


  }
}
