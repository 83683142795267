import { Component, OnInit, Input } from '@angular/core';
import { PersonalInfo } from '../model/personal.class';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'dialer-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.css']
})
export class PersonalComponent implements OnInit {

  careerpersonal : boolean = true;
  insurancepersonal : boolean = false;
  private __data = new BehaviorSubject<PersonalInfo>(null);
  public solution= this.__data.asObservable();
  @Input() type : string = "";
  @Input() personaldata : any;

  constructor() { }
  /* @Input() set personaldata(v : any) {
    console.log("Setting current data");
    this.__data.next(v);
  } */

  ngOnInit() {
  }

}
