  // Starting Script Form

  export interface Scripts
  {
       id : number,
       script_name:string,
       script:string
  }


export interface HTTPScripts
{
   id : number,
   script_name:string,
   script:string
}

export class FormScripts implements HTTPScripts{
 public  id : number;
 public script_name:string;
 public script:string;

   constructor(data : Scripts) 
   {
       
    //    console.log("Script current data",data);
       if(data==null)
       {
           this.id=-1;
           this.script_name="";
           this.script="";
       }
       else
       {
           this.id  = data.id;
           this.script_name=data.script_name;
           this.script=data.script;
       }
   }
 }

  //End Script form

  /* Objection Button Model Start */
  export interface Objection
  {
      id      ?: any[],
      iduser : number,
      script_name ?: any[],
      abbr : string,
      script_body : string
  }
  export interface HTTPObjection
  {
    id      ?: any[],
    iduser : number,
    script_name   ?: any[],
    abbr : string,
    script_body : string
  }
  export class ObjectionForm implements HTTPObjection{
    public id      ?: any[];
    public iduser : number;
    public script_name ?: any[];
    public abbr : string;
    public script_body : string;
      constructor(data : Objection) 
      {
          if(data==null)
          {
              this.id                = [];
              this.script_name  = [];
          }
          else
          {
              this.id                = [];
              this.script_name  = [];
          }
      }
     }
  /* Objection Button Model Stop */


  
export interface CareerScripts
{
     id : number,
     script_name:string,
     career_script_body:string
}


export interface HTTPCareerScripts
{  
 id : number,
 script_name:string,
 career_script_body:string 
}

export class FormCareerScripts implements HTTPCareerScripts{
 public id : number;
 public script_name:string;
 public career_script_body:string; 
 constructor(data:CareerScripts)
 {
     if(data==null)
     {
         this.id=-1;
         this.script_name="";
         this.career_script_body="";
     }
     else
     {
         this.id=data.id;
         this.script_name=data.script_name;
         this.career_script_body=data.career_script_body;
     }
 }
}
