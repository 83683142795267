
import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppComponentClass } from '../../../shared/service/AppComponent.class';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { DialerService } from '../dialer.service';
import { Comments, CommentsForm } from '../model/comments.class';



@Component({
    selector: 'dialer-conference',
    templateUrl: './conference.component.html',
    styleUrls: ['./conference.component.css']
})

export class ConferenceComponent extends AppComponentClass<Comments, CommentsForm> {
    private __data = new BehaviorSubject<Comments>(null);
    public solution = this.__data.asObservable();

    public form: FormGroup;

    constructor(public data: DialerService, protected router: Router, protected fb: FormBuilder, protected msg: MatSnackBar) {
        super(data, router, fb, msg);
    }

    @Input() set commentsdata(v: Comments) {
        console.log("Current Comments Data :: ");
        console.log(v);
        this.__data.next(v);
    }

    ngOnInit() {

        this.solution.subscribe(res => {
            console.log(res);
            if (res == null) {
                this.cdata = new CommentsForm(null);
                this.form = this.cdata.makeform(this.fb);
            }
            else {
                this.cdata = new CommentsForm(res);
                this.form = this.cdata.makeform(this.fb);
            }
        });
    }

    onSubmit() {
        if (this.cdata.saveForm(this.form))
            super.onSubmit();
    }

    warning() {
        if (this.cdata.comments != this.form.get("comments").value)
            this.cdata.comments = this.form.get("comments").value;
    }

    changeEvnt(key: string) {
        console.log(key);
        if (key == 'comments')
            this.cdata.comments = this.form.get("comments").value;

        localStorage.setItem("comment", JSON.stringify(this.cdata));
        console.log("OnChange events called comment data : Key " + key + " Value :" + this.form.get(key).value);
        console.log(localStorage.getItem("comment"));
    }

}
