import { Component, OnInit, Input } from '@angular/core';
import { PersonalInfo , PersonalInfoForm } from '../../model/personal.class';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { AppComponentClass } from '../../../service/AppComponent.class';
import { DialerService } from '../../dialer.service';

@Component({
  selector: 'dialer-insurancepersonal',
  templateUrl: './insurancepersonal.component.html',
  styleUrls: ['./insurancepersonal.component.css']
})


export class InsurancepersonalComponent extends AppComponentClass<PersonalInfo, PersonalInfoForm> {
  private __data = new BehaviorSubject<PersonalInfo>(null);
  public solution= this.__data.asObservable();
  public form:FormGroup;
  
  constructor(public edata: DialerService, protected router: Router, protected fb: FormBuilder, protected msg: MatSnackBar)
  {
    super(edata, router, fb , msg); 
  }

  @Input() set compdata(v : PersonalInfo) {
    this.debug_log("Setting current data");
    this.__data.next(v);
  }

  ngOnInit() {
    this.solution.subscribe(res=>{
      console.log(res);
      if(res==null)
      {
        this.cdata= new PersonalInfoForm(null);
        this.form=this.cdata.makeform(this.fb);
      }
      else
      {
        this.cdata= new PersonalInfoForm(res);
        this.form=this.cdata.makeform(this.fb);
      }
    });
  }

  onSubmit()
  {
    if(this.cdata.saveForm(this.form))
      super.onSubmit();
  }

  changeEvnt(key:string)
  { 
    console.log(key);
    if(key == 'birth_date')
      this.cdata.birth_date = this.form.get("birth_date").value;
    if(key == 'spouse_birth_date')
      this.cdata.spouse_birth_date = this.form.get("spouse_birth_date").value;
    if(key == 'age')
      this.cdata.age = this.form.get("age").value;
    if(key == 'spouse_age')
      this.cdata.spouse_age = this.form.get("spouse_age").value;
    if(key == 'occupation')
      this.cdata.occupation = this.form.get("occupation").value;
    if(key == 'spouse_occupation')
      this.cdata.spouse_occupation = this.form.get("spouse_occupation").value;
    if(key == 'height')
      this.cdata.height = this.form.get("height").value;
    if(key == 'spouse_height')
      this.cdata.spouse_height = this.form.get("spouse_height").value;
    if(key == 'weight')
      this.cdata.weight = this.form.get("weight").value;
    if(key == 'spouse_weight')
      this.cdata.spouse_weight = this.form.get("spouse_weight").value;   
    if(key == 'smoker')
      this.cdata.smoker = this.form.get("smoker").value;
    if(key == 'spouse_smoker')
      this.cdata.spouse_smoker = this.form.get("spouse_smoker").value;
    if(key == 'health_issue')
      this.cdata.health_issue = this.form.get("health_issue").value; 
    if(key == 'spouse_health_issue')
      this.cdata.spouse_health_issue = this.form.get("spouse_health_issue").value; 
    if(key == 'lender')
      this.cdata.lender = this.form.get("lender").value; 
    if(key == 'beneficiary')
      this.cdata.beneficiary = this.form.get("beneficiary").value; 
    if(key == 'mortgage_amount')
      this.cdata.mortgage_amount = this.form.get("mortgage_amount").value; 
    if(key == 'lead_level')
      this.cdata.lead_level = this.form.get("lead_level").value; 
    if(key == 'purchase_refi')
      this.cdata.purchase_refi = this.form.get("purchase_refi").value; 
    if(key == 'assign_date')
      this.cdata.assign_date = this.form.get("assign_date").value; 
    if(key == 'home_value')
      this.cdata.home_value = this.form.get("home_value").value; 
    if(key == 'status')
      this.cdata.status = this.form.get("status").value; 
    if(key == 'mortgage_term')
      this.cdata.mortgage_term = this.form.get("mortgage_term").value; 
    if(key == 'referrer')
      this.cdata.referrer = this.form.get("referrer").value; 
    if(key == 'monthly_mortage_payment')
      this.cdata.monthly_mortage_payment = this.form.get("monthly_mortage_payment").value; 
    if(key == 'reason')
      this.cdata.reason = this.form.get("reason").value; 
    if(key == 'spouse_smoker')
      this.cdata.spouse_smoker = this.form.get("spouse_smoker").value; 
    
    localStorage.setItem("personaldata", JSON.stringify(this.cdata));
    console.log("OnChange events called on personaldata : Key "+key+" Value :"+this.form.get(key).value);
    console.log(localStorage.getItem("personaldata"));
  }

}
