import { Injectable } from '@angular/core';
import { GraphicalReport } from './graph-report.model';
import { AppService } from '../../shared/service/AppService.class';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GraphReportService extends AppService<GraphicalReport> {
  constructor(protected http:HttpClient) 
  {
    super(http);
    this.appmod="reports/";
  }

  public mod(v : string) {
    this.appmod = v;
  } 
}