import { BehaviorSubject, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import * as SIP from "sip.js";
import { Theme } from "./AutoTheme";
import { AUDIOURL } from "../../app-routing.module";

interface Response {
  type: string;
  message: any;
  cause: any;
}

/* let count=14;  */
let myTimer;

@Injectable()
export class SipJsService {
  private __crmdata = new BehaviorSubject<any>(null);
  public crmdata = this.__crmdata.asObservable();

  private __message = new BehaviorSubject<Response>({
    type: "",
    message: "",
    cause: "",
  });
  public message = this.__message.asObservable();

  userAgent: SIP.UA;
  session: SIP.Session;
  audio: any;

  remoteAudio: any = document.getElementById("remoteAudio");
  localAudio: any = document.getElementById("localAudio");

  constructor(private theme: Theme) {
    this.audio = new Audio();
    this.audio.src = AUDIOURL + "ring11.mp3";
  }

  public RegisterUA(user: string, pwd: string): void {
    var config = {
      uri: user + "@" + localStorage.getItem("sipuri"),
      ws_servers: "wss://" + localStorage.getItem("sipuri") + ":7443",
      authorizationUser: user,
      password: pwd,
      transportOptions: {
        traceSip: true,
        wsServers: "wss://" + localStorage.getItem("sipuri") + ":7443",
      },
      registerExpires: 3600,
    };

    this.userAgent = new SIP.UA(config);
  }

  public MakeOutboundCall(phone: number) {
    let prefdata = JSON.parse(localStorage.getItem("preferences"));
    let call_recording = prefdata.call_recording;
    var randomno = Math.floor(
      100000000000000 + Math.random() * 900000000000000
    );
    var recording_file =
      localStorage.getItem("LeadID") +
      "_" +
      localStorage.getItem("current_number") +
      "_" +
      randomno;
    //localStorage.setItem("current_number", phone.toString());
    var options = {
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: false,
        },
      },
      extraHeaders: [
        "X-CallerID: " + localStorage.getItem("callerid"),
        "X-minute: " + localStorage.getItem("minutes"),
        "X-buyminute: " + localStorage.getItem("buy_minutes"),
        "X-extraminute: " + localStorage.getItem("extra_minutes"),
        "X-rate: " + localStorage.getItem("rate"),
        "X-leadid: " + localStorage.getItem("LeadID"),
        "X-agentid: " + localStorage.getItem("userid"),
        "X-recording: " + recording_file,
        "X-callrecording: " + call_recording,
        "X-tbl_cdr: tbl_cdr",
      ],
    };
    //var options = {extraHeaders: ["X-Header: 2000", "X-pin: 2000","X-destination: xxxxxxxx"] };
    console.log(
      "Make call has been Initiated :: " +
        localStorage.getItem("current_number")
    );

    this.session = this.userAgent.invite(
      localStorage.getItem("current_number") +
        "@" +
        localStorage.getItem("sipuri"),
      options
    );

    this.session.on("progress", (response) => {
      console.log(
        "/@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@" +
          response.status_code +
          "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@/"
      );
      console.log(response.reason_phrase);
      localStorage.setItem("isdial", (0).toString());
      if (response.status_code == 100) {
        this.theme.ChangeColor("bluedark");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is Trying"
        );
      }
      if (response.status_code == 183) {
        this.audio.load();
        this.audio.play();
        this.theme.ChangeColor("purple");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is Ringing"
        );
        let prefdata = JSON.parse(localStorage.getItem("preferences"));
        let ringingtime = parseInt(prefdata.ringing_time);
        if (parseInt(localStorage.getItem("isautodial")) == 1) {
          let count = ringingtime;
          myTimer = setInterval(() => {
            count = count - 1;
            console.log("Count Down Start :: " + count);
            if (count == 0) {
              clearInterval(myTimer);
              this.theme.StopAutodial(1);
            }
          }, 1000);
        }
      }
      console.log(response.status_code);
      console.log(
        "/@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@/"
      );
    });

    this.session.on("rejected", (response, cause) => {
      console.log(
        "/@@@@@@@@@@@@@@@@@@@@@@@@" +
          response.status_code +
          "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@/"
      );
      console.log(response.reason_phrase);
      console.log(response.status_code);
      localStorage.setItem("isdial", (1).toString());
      clearInterval(myTimer);
      this.audio.pause();
      if (response.status_code == 480) {
        this.theme.ChangeColor("warning");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is Rejected"
        );
        this.theme.showDialpad(false);
        if (parseInt(localStorage.getItem("isdial")) == 1) {
          this.theme.StartAutodial(1);
        }
      }
      if (response.status_code == 503) {
        this.theme.ChangeColor("warning");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is Rejected"
        );
        this.theme.showDialpad(false);
        if (parseInt(localStorage.getItem("isdial")) == 1) {
          this.theme.StartAutodial(1);
        }
      }
      if (response.status_code == 408) {
        this.theme.ChangeColor("warning");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is Unavailable"
        );
        this.theme.showDialpad(false);
        if (parseInt(localStorage.getItem("isdial")) == 1) {
          this.theme.StartAutodial(1);
        }
      }
      if (response.status_code == 488) {
        this.theme.ChangeColor("warning");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is Unavailable"
        );
        this.theme.showDialpad(false);
        if (parseInt(localStorage.getItem("isdial")) == 1) {
          this.theme.StartAutodial(1);
        }
      }
      if (response.status_code == 486) {
        this.theme.ChangeColor("warning");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is No Answer"
        );
        this.theme.showDialpad(false);
        if (parseInt(localStorage.getItem("isdial")) == 1) {
          this.theme.StartAutodial(1);
        }
      }

      if (response.status_code == 487) {
        this.theme.ChangeColor("warning");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is No Answer"
        );
        this.theme.showDialpad(false);
        if (parseInt(localStorage.getItem("isdial")) == 1) {
          this.theme.StartAutodial(1);
        }
      }

      if (response.status_code == 400) {
        this.theme.ChangeColor("warning");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is Unavailable"
        );
        this.theme.showDialpad(false);
        if (parseInt(localStorage.getItem("isdial")) == 1) {
          this.theme.StartAutodial(1);
        }
      }

      if (response.status_code == 401) {
        this.theme.ChangeColor("warning");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is Unauthorized"
        );
        this.theme.showDialpad(false);
        if (parseInt(localStorage.getItem("isdial")) == 1) {
          this.theme.StartAutodial(1);
        }
      }

      if (response.status_code == 403) {
        this.theme.ChangeColor("warning");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is Wrong Number"
        );
        this.theme.showDialpad(false);
        if (parseInt(localStorage.getItem("isdial")) == 1) {
          this.theme.StartAutodial(1);
        }
      }

      if (response.status_code == 404) {
        this.theme.ChangeColor("warning");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is Wrong Number"
        );
        this.theme.showDialpad(false);
        if (parseInt(localStorage.getItem("isdial")) == 1) {
          this.theme.StartAutodial(1);
        }
      }

      if (response.status_code == 484) {
        this.theme.ChangeColor("warning");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is Unavailable"
        );
        this.theme.showDialpad(false);
        if (parseInt(localStorage.getItem("isdial")) == 1) {
          this.theme.StartAutodial(1);
        }
      }
      console.log(
        "/@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@/"
      );
    });

    this.session.on("cancel", () => {
      localStorage.setItem("isdial", (1).toString());
      clearInterval(myTimer);
      this.theme.ChangeColor("warning");
      this.theme.ChangeSipData(
        localStorage.getItem("current_number") + " is Rejected"
      );
      this.theme.showDialpad(false);
      /* if(parseInt(localStorage.getItem("isdial"))==1)
          {
            this.theme.StartAutodial(1);
          } */
    });

    this.session.on("bye", (request) => {
      console.log(
        "/@@@@@@@@@@@@@@@@@@@@@@@@@@@" +
          request.method +
          "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@/"
      );
      console.log(request.method);
      localStorage.setItem("isdial", (1).toString());
      clearInterval(myTimer);
      if (request.method == "BYE") {
        this.theme.ChangeColor("red");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is Disconnected"
        );
        this.theme.showDialpad(false);
        this.theme.GetBalance(true);
        if (parseInt(localStorage.getItem("isdial")) == 1) {
          this.theme.StartAutodial(1);
        }
      }
      console.log(request.status_code);
      console.log(
        "/@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@/"
      );
    });

    this.session.on("trackAdded", () => {});

    this.session.on("accepted", (data) => {
      console.log(
        "/@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@" +
          data.status_code +
          "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@/"
      );
      console.log(data.reason_phrase);
      console.log(data.status_code);
      clearInterval(myTimer);
      var pc = this.session.sessionDescriptionHandler.peerConnection;
      localStorage.setItem("isdial", (0).toString());
      if (data.status_code == 200) {
        this.audio.pause();
        this.theme.ChangeColor("green");
        this.theme.ChangeSipData(
          localStorage.getItem("current_number") + " is Connected"
        );
        this.theme.showDialpad(true);

        var remoteStream = new MediaStream();
        pc.getReceivers().forEach(function (receiver) {
          remoteStream.addTrack(receiver.track);
        });
        this.remoteAudio.srcObject = remoteStream;
        this.remoteAudio.play();

        // Gets local tracks
        var localStream = new MediaStream();
        pc.getSenders().forEach(function (sender) {
          localStream.addTrack(sender.track);
        });
        this.localAudio.srcObject = localStream;
        this.localAudio.play();
        localStorage.setItem("phonetype", "0");
      }
      console.log(
        "/@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@/"
      );
    });
  }

  public HangupCall(number: number) {
    if (this.session) {
      this.session.terminate();
      this.theme.ChangeColor("red");
      this.audio.pause();
      this.theme.ChangeSipData(
        localStorage.getItem("current_number") + " is Disconnected"
      );
    }
    localStorage.setItem("isdial", (1).toString());
    this.theme.showDialpad(false);
    this.theme.GetBalance(true);
  }

  public SendDTMF(number: number) {
    console.log("DTMF");
    console.log(number);
    this.session.dtmf(number);
    this.theme.ChangeSipData(
      "Send " + number + " IVR to " + localStorage.getItem("current_number")
    );
  }

  public UnregisterUA() {
    this.userAgent.unregister();
  }

  public RecieveSipjsEvents() {
    let data = new BehaviorSubject<any>("");
    let value = data.asObservable();
    this.session.on("progress", (response) => {
      data.next(response);
      // console.log(response);
      return value;
      //this.__message.next({type:"progress", message:response, cause:""});
    });
    return value;
  }

  private handleError(error) {
    console.error("server error:", error);
    if (error.error instanceof Error) {
      let errMessage = error.error.message;
      return Observable.throw(errMessage);
    }
    return Observable.throw(error || "SIP.JS server error");
  }
}
