import {Component, Input} from '@angular/core';
import { FormScripts,Scripts } from '../../model/script.class';
import { BehaviorSubject } from 'rxjs';
import { DialerForm } from '../../../../mortgage/model/model.class';
import { EmailDialogComponent } from '../../../../lead/emaildialog/email-dialog.component';
import { MatDialog } from '@angular/material';

interface ListItem{
    image:string;
    name:string;
    description:string;
    time:string;
  }
interface SMSItem {
    profile: string;
    message: string;
    date: string;
    role:string;
    name:string;
  }

  export interface EmailTemplateElement {
    id:number;
    name: string;
    email: string;
    number:number,
    message: string;
  }


  const EMAIL_ELEMENT_DATA: EmailTemplateElement[] = [
    {  id: 1,name: 'Vishal', email: 'mahesh@gmail.com',number:9876543210, message: 'H'},
    { id: 2,name: 'Mahesh', email: 'vishal@gmail.com',number:8976543210, message: 'He'},
    { id: 3,name: 'Nitin', email: 'nitin@gmail.com',number:9643098645, message: 'Li'},
    { id: 4,name: 'Parimal', email: 'parimal@gmail.com',number:9451239870, message: 'Li'},
  ];

@Component({
  selector: 'tab-section',
  templateUrl: './tab.component.html',
  styleUrls:['./tab.component.css'],
})
export class tabComponent {
  id: number;
  data: any;

  emailTemplateColumns: string[] = ['name', 'email','number' ,'message'];
  emailTemplateData = EMAIL_ELEMENT_DATA;

  script_name: string = "";
    cdata = null;
    showQueue = false;
    scriptmenu: Scripts[];

    private __data = new BehaviorSubject<Scripts>(null);
    public solution = this.__data.asObservable();
    public curscript: any;
    public curscripttitle: String;


    selectedTabIndex: number = 1;
    constructor(public dialog: MatDialog) {
      this.cdata = new DialerForm(null);
    }

    @Input() set scriptdata(v: Scripts) {
      this.__data.next(v);
  }

  ngOnInit() {
    this.solution.subscribe(res => {
        if (res != null) {
            this.cdata = new FormScripts(res);
            // console.log("Current on script ts " + this.cdata.id);
            // console.log(res);
            this.curscript = res.script;
            this.curscripttitle = res.script_name;
        }
        else {
            console.log("NUll Script has been called");
            this.cdata = new FormScripts(res);
            this.curscript = null;
            this.curscripttitle = '';
        }
    });
}



    items:ListItem[] = [
        {
          image: '../../../assets/images/user.png',
          name: 'Vishal Patel',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin fringilla mi diam, vel aliquam massa sagittis in. Nunc iaculis erat sit amet enim malesuada ultrices. Aenean in sapien non odio fermentum malesuada nec a felis',
          time: '3 days ago'
        },
        {
          image: '../../../assets/images/user.png',
          name: 'Mahesh Kuamr',
          description: 'Suspendisse porta odio ac lorem tristique tristique. Aliquam blandit nisi tellus. Aliquam malesuada gravida urna, vitae imperdiet metus interdum non',
          time: 'Yesterday',
        },
        {
          image: '../../../assets/images/user.png',
          name: 'Prabhat Patel',
          description: 'Integer volutpat nunc eget sem venenatis, et tempus nulla dapibus. Ut semper sapien quis elit scelerisque ultrices.',
          time: 'A few minutes ago',
        },
      ];


    smsItems: SMSItem[] = [
        {
          profile: '../../../assets/images/user.png',
          message: 'Hey, are we still on for today?',
          date: 'Today',
          role:'LIFE_NAVIGATOR',
          name:'Vishal',
        },
        {
          profile: '../../../assets/images/user.png',
          message: 'Your subscription has been renewed successfully.',
          date: 'Yesterday',
          role:'CLIENT',
          name:'Mahesh'
    
        },
        {
          profile: '../../../assets/images/user.png',
          message: 'Your subscription has been renewed successfully.',
          date: 'Yesterday',
          role:'LIFE_NAVIGATOR',
          name:'Vishal',
        },
        {
          profile: '../../../assets/images/user.png',
          message: 'Integer volutpat nunc eget sem venenatis, et tempus nulla dapibus. Ut semper sapien quis elit scelerisque ultrices.',
          date: 'Yesterday',
          role:'CLIENT',
          name:'mahesh',
        },
        {
          profile: '../../../assets/images/user.png',
          message: 'Hello',
          date: 'Yesterday',
          role:'LIFE_NAVIGATOR',
          name:'mahesh',
        },
      ];





  onTabClick(index: number) {
    this.selectedTabIndex = index;
  }
  openDialog( id:number) {
    
    const emailData = EMAIL_ELEMENT_DATA.find(item => item.id === id);
    console.log("emailData",emailData)
    const dialogRef = this.dialog.open(EmailDialogComponent,{
      data: emailData,
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
  
}


