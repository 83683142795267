import {Component, ElementRef, ViewChild, AfterViewChecked, Input,Pipe, PipeTransform} from '@angular/core';
import { FormScripts,Scripts } from '../../model/script.class';
import { BehaviorSubject } from 'rxjs';
import { DialerForm } from '../../../../mortgage/model/model.class';
import { EmailDialogComponent } from '../../../../lead/emaildialog/email-dialog.component';
import { MatDialog} from '@angular/material';
import { MortgageService } from '../../../../mortgage/mortgage.service';
import { Theme } from '../../../service/AutoTheme';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from "@angular/material/snack-bar"; // {{ edit_1 }}




interface ListItem{
    image:string;
    name:string;
    description:string;
    time:string;
  }
interface SMSItem {
    profile: string;
    message: string;
    date: string;
    role:string;
    name:string;
  }

  export interface EmailTemplateElement {
    id:number;
    email: string;
    message: string;
}
  @Pipe({
  name: 'stripHtml'
})



@Component({
  selector: 'tab-section',
  templateUrl: './tab.component.html',
  styleUrls:['./tab.component.css'],
  providers: [DatePipe]
})
export class tabComponent {
  id: number;
  data: any;
  contacts: any[];
  comments: any[];
  sms: any[];
  userId: number;
  EMail:EmailTemplateElement[] = [];

  emailTemplateColumns: string[] = [ 'email' ,'message'];
  displayedColumns: string[] = ['email', 'message'];

  script_name: string = "";
    cdata = null;
    showQueue = false;
    scriptmenu: Scripts[];

    private __data = new BehaviorSubject<Scripts>(null);
    public solution = this.__data.asObservable();
    public curscript: any;
    public curscripttitle: String;
    selectedTabIndex: number = 1;
    newCommentMessage: string = ''; 
    newSMS:string='';

    @ViewChild('commentsContainer') commentsContainer: ElementRef;

    ngAfterViewChecked() {
      this.scrollToBottom();
    }

    
    scrollToBottom(): void {
      try {
        if (this.commentsContainer && this.commentsContainer.nativeElement) {
          this.commentsContainer.nativeElement.scrollTop = this.commentsContainer.nativeElement.scrollHeight;
        }
      } catch(err) {
        console.error('Error scrolling to bottom:', err);
      }
    }
    
        constructor(public dialog: MatDialog,private mortgageService: MortgageService, private datePipe: DatePipe,protected theme: Theme, private snackBar: MatSnackBar) {this.cdata = new DialerForm(null);}

    @Input() set scriptdata(v: Scripts) {
      this.__data.next(v);

      
  }

  stripHtml(html: string): string {
  let doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
}

  ngOnInit() {
    this.mortgageService.getComments().subscribe(comments => {
      this.comments = comments; 
      
    });

    this.mortgageService.getSMS().subscribe(sms => {
      this.sms = sms; 
      
    });
    
    this.solution.subscribe(res => {
        if (res != null) {
            this.cdata = new FormScripts(res);
            this.curscript = res.script;
            this.curscripttitle = res.script_name;
        }
        else {
            console.log("NUll Script has been called");
            this.cdata = new FormScripts(res);
            this.curscript = null;
            this.curscripttitle = '';
        }
    });

    const userIdString = Number(localStorage.getItem("userid"));
    this.userId = userIdString ? +userIdString : null;
    this.mortgageService.getEmail().subscribe(contacts => {
      this.contacts = contacts;
    });
}
formatTime(dateString: string): string {
  // Format the date to 'h:mm a' (e.g., '9:52 AM')
  return this.datePipe.transform(dateString, 'h:mm a');
}

  onTabClick(index) {
    // alert(index);
    if (index === 4) {
      this.mortgageService.getEmail().subscribe((contacts) => {this.contacts = contacts; },
    (error) => {
      this.contacts = []; 
      console.error('Error fetching contacts:', error);
       this.snackBar.open('No Email Data ', 'Close', { duration: 2000 });
  }
);
    }
    if (index === 2) {
      this.mortgageService.getSMS().subscribe((sms) => {
        this.sms = sms; 
      //  alert(JSON.stringify(this.sms))
      },
        (error) => {
          this.sms = [];
          console.error('Error fetching contacts:', error);
          this.snackBar.open('No SMS Data ', 'Close', { duration: 2000 });
        }
      );
    }
    if (index === 3) {
       this.mortgageService.getComments().subscribe((comments) => {
         this.comments = comments; 
       },
         (error) => {
           this.comments = [];
           console.error('Error fetching comments:', error);
          this.snackBar.open('No Comments Data', 'Close', { duration: 2000 });
         }
       );
    }

    this.selectedTabIndex = index;
    
  }
  // openDialog( id:number) {
    
  //   const emailData = EMAIL_ELEMENT_DATA.find(item => item.id === id);
  //   console.log("emailData",emailData)
  //   const dialogRef = this.dialog.open(EmailDialogComponent,{
  //     data: emailData,
  //   });
  
  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }


  submitSMS() {
    if (this.newSMS.trim()) {
      const newsms = {
        sender_id:localStorage.getItem("userid"),
        receive_id:"101",
        lead_id:localStorage.getItem("LeadID"),
        sms: this.newSMS,
      };
      this.mortgageService.addSMS(newsms).subscribe((response:any) => {
        this.newSMS = '';
        //console.log('Comment added successfully:', response);
        this.mortgageService.getSMS().subscribe(sms => {
          this.sms = sms; 
        });
        this.snackBar.open('SMS sent successfully', 'Close', {
          duration: 1000,
      });
         this.sms.push(response);  // Assuming response contains the new comment object
        
        

        
      }, error => {
        console.error('Error adding comment:', error);
      });
    }
  }



  submitComment() {
    if (this.newCommentMessage.trim()) {
      const newComment = {
        user_id: localStorage.getItem("userid"),
        lead_id: localStorage.getItem("LeadID"),
        comments_message: this.newCommentMessage,
      };
      this.mortgageService.addComment(newComment).subscribe(response => {
        // Add the new comment to the comments array
        this.newCommentMessage = '';
        this.mortgageService.getComments().subscribe(comments => {
          this.comments = comments; 
        });
        this.comments.push(response);  // Assuming response contains the new comment object
        this.snackBar.open('Comment sent successfully', 'Close', {
          duration: 1000,
      });
        // Fetch updated comments after adding a new one
        
      }, error => {
        console.error('Error adding comment:', error);
      });
    }
  
}
}


