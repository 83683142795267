
    // Starting Comment Form

    import { FormBuilder, FormGroup} from "@angular/forms";
import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent
  } from 'angular-calendar';

  
export interface Comments{
    comments : string
}

export interface HTTPComments
{
    comments : string,
}

export class CommentsForm implements HTTPComments{
    public  comments : string;

    constructor(data : Comments) 
    {
        if(data==null)
        {
            this.comments="";
        }
        else
        {
            this.comments  = data.comments;
            
        }
    }

    public makeform(fb:FormBuilder):FormGroup
    {
        return fb.group({
        
        comments  : [this.comments],
        
        });
    }

    public saveForm(fg:FormGroup):boolean
    {
        if(fg.valid)
        {

            this.comments  = fg.get("comments").value;
        
            return true;
        }
        else
        {
            return false;
        }
    }
}
 
    //End Comment form



    
export interface CareerComments{
    comments : string
}


export interface HTTPCareerComments
{
   comments : string,
}

export class CareerCommentsForm implements HTTPCareerComments{
   public  comments : string;
   constructor(data:CareerComments)
   {
       if(data==null)
       {
           this.comments="";           
       }
       else
       {
           this.comments=data.comments;            
       }
   }

   
   public makeform(fb:FormBuilder):FormGroup
   {
       return fb.group({
         
           comments  : [this.comments],
        
        });
   }

   public saveForm(fg:FormGroup):boolean
   {
       if(fg.valid)
       {

           this.comments  = fg.get("comments").value;
        
           return true;
       }
       else
       {
           return false;
       }
   }
}