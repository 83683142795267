import { Component, ElementRef, Inject, ViewChild ,ChangeDetectorRef} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar"; 
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { MortgageService } from "../../../../mortgage/mortgage.service";
import { Theme } from "../../../service/AutoTheme";


@Component({
    selector: 'email_dialog',
    templateUrl: './email_model.component.html',
    styleUrls: ['./email_model.component.css']
})

export class Email_DialogComponent {
      public emailtemp = [];
    SMStempate:any[];
  selectedTemplate: string = '';
  editorContent: string = '';
  selectedTemplateId: string = '';

@ViewChild('selectElement') selectElement: ElementRef;
  @ViewChild('textareaElement') textareaElement: ElementRef;

  
  ngAfterViewInit() {
    this.selectElement.nativeElement.addEventListener('change', () => {
        const selectedTemplate = this.emailtemp.find(template => template.value === Number(this.selectElement.nativeElement.value));
      if (selectedTemplate) {
        this.editorContent = selectedTemplate.message;
        this.selectedTemplateId = selectedTemplate.value; 
        this.cdRef.detectChanges();
      }
    });
  }

    constructor(public dialogRef: MatDialogRef<Email_DialogComponent>,  @Inject(MAT_DIALOG_DATA) public data: any,private mortgageService: MortgageService,private snackBar: MatSnackBar,protected theme: Theme,private cdRef: ChangeDetectorRef){
       console.log(data)
    }
    ngOnInit(): void{
        this.theme.emailsolution.subscribe((res) => {
      console.log("email option");
      console.log(res);
      if (res && res != null && res.length > 0) {
        this.emailtemp = res;
      } else {
        this.emailtemp = [{ label: "No option", value: 0 }];
      }
    });
        this.mortgageService.getSMStempateuserid().subscribe(SMStempate=>{
            this.SMStempate = SMStempate
          });
    }
    onSubmit() {
        let sdata = {
            id_lead: localStorage.getItem("LeadID"),
            id_template:this.selectedTemplateId,
            to_email: this.data.email,
            message: this.editorContent,
      };
        this.theme.SaveEmail(JSON.stringify(sdata));
        this.theme.saveemail.subscribe(
            res => { 
            this.dialogRef.close();
            this.snackBar.open('Email sent successfully!', 'Close', {
            duration: 2000,
            });
             },
            err => { alert('mail failed') },
            () => { alert('completed') }
        )
        
    }
}