import { Component, AfterViewInit,OnInit  } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APIURL } from '../../app-routing.module';
import { Theme } from '../service/AutoTheme';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../../authentication/authentication.service';
import { SipJsService } from '../service/sipjsServices.class';
import { ServerSocket } from '../service/Activity.socket';

interface NotificationMessage{
    useravatar: string,
    status: string,
    from: string,    
    subject: string,
    time: string,
    idlead: number,
    leaddata: string
}
@Component({
  selector: 'ap-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit, AfterViewInit {

    public dialerstatus:any;
    public callstatus:any;
    public minutes=JSON.parse(localStorage.getItem("minutes")) || 0;
    public buy_minutes=JSON.parse(localStorage.getItem("buy_minutes")) || 0;
    public extra_minutes=JSON.parse(localStorage.getItem("extra_minutes")) || 0;
    public total_minutes=this.minutes + this.buy_minutes + this.extra_minutes;
    public callerid=localStorage.getItem("callerid") || "None";
    public extuser=localStorage.getItem("extuser")|| "NA";
    public extpass=localStorage.getItem("extpass") || "NA";  
    public current_number=localStorage.getItem("current_number") || "NA";
    public number_type=localStorage.getItem("number_type") || "NA";
    public agcall=false;
    public agentdialer:boolean=true;
    public leadid=localStorage.getItem("LeadID") || -1;
    public currscript=localStorage.getItem("currscript") || -1;
    public extaccount=localStorage.getItem("extaccount") || "None";
    public __autohangup:boolean=false;
    public  menu :  boolean;

    public mymessages: NotificationMessage[] = [];
    public totalmsg=this.mymessages.length;
    public cbdata= [];

    private __notification = new BehaviorSubject<NotificationMessage[]>([]);

    public notification= this.__notification.asObservable();

    public dial_type:number = 0;
    public sipstatus:string="";
    public callcounter:number=0;
    public callicon:any;
    public gotnotification: boolean = false;
    public blockautodial:boolean = true;

    public status=[{icon:"mdi-timer-sand", value:this.total_minutes, title:"Remaining Minutes"}, {icon:"mdi-phone-outgoing", value:this.callerid, title:"Caller Id"}];
  

    reportEvent = [{ icon: "DH", text: "Disposition History", type: 11 }, { icon: "CH", text: "Call History", type: 12, menu: [{}] }, { icon: "AS", text: "Appointment Scheduled", type: 15 }, { icon: "ED", text: "Email Details", type: 16 }, { icon: "SD", text: "SMS Details", type: 17 }, { icon: "OH", text: "Objection History", type: 18 }];

  	constructor(protected http:HttpClient, public theme:Theme, protected auth:AuthenticationService, protected sipdata:SipJsService, protected iosocket:ServerSocket) {   
        
    }

    ngOnInit()
    {         
        this.theme.callerid.subscribe(res => {
            this.status=[{icon:"mdi-timer-sand", value:this.total_minutes, title:"Remaining Minutes"}, {icon:"mdi-phone-outgoing", value:res, title:"Caller Id"}];
            localStorage.setItem('callerid', res);
            //this.callcounter = JSON.parse(localStorage.getItem("totalcallattempts"));
        });

        this.theme.sipjsdata.subscribe(ress =>{
            console.log("Call Response has been Accepted");
            console.log(ress);
            if(ress !=null)
            {
                this.callicon = localStorage.getItem("number_type");
                this.sipstatus=ress;
                this.callcounter = parseInt(localStorage.getItem("totalcallattempts"));
                this.dragElement(document.getElementById("callStatus"));
            }
            //this.sipdata.RecieveSipjsEvents(); 
        });
        this.theme.showmenu.subscribe(res=>{
            this.menu= res;
        });

        this.iosocket.openSocket("callback",localStorage.getItem("userid"));

        this.iosocket.RecieveEvents("callback_lead").subscribe(res4 =>{
            //console.log("callback_lead: "+res4);
            if(res4)
            {
                this.gotnotification=true;
                let cb_data = JSON.parse(res4);
                cb_data = cb_data.lead;               
                console.log(cb_data);
                let lead_id = cb_data.id_lead;  
                let lead_data = JSON.stringify(cb_data.lead_data);           
                if(this.cbdata.indexOf(lead_id) === -1){
                    this.mymessages.push({
                        useravatar: 'assets/images/users/2.jpg',
                        status: 'online',
                        from: cb_data.leadname,    
                        subject: 'Callback Lead',
                        time: cb_data.cb_time,
                        idlead: cb_data.id_lead,
                        leaddata: lead_data
                    });
                    this.cbdata.push(lead_id);
                
                    this.__notification.next(this.mymessages);
                }             
            }
        });

        // this.iosocket.RecieveEvents("rem_minutes").subscribe(res5 =>{
        //     console.log("current_rem_minutes: "+res5);
        //    if(res5.minute !=null || res5.minute !=undefined){
        //         localStorage.setItem('minutes', res5.minute);                
        //         this.minutes=localStorage.getItem("minutes");
        //         this.current_number=localStorage.getItem("current_number");
        //         this.callerid=localStorage.getItem("callerid");
        //         this.status=[{icon:"mdi-timer-sand", value:this.minutes, title:"Remaining Minutes"}, {icon:"mdi-phone-incoming", value:localStorage.getItem('callerid'), title:"Caller Id"}];
        //     } 
        // });

        this.notification.subscribe(res=>{
            this.mymessages=res;
            this.totalmsg=res.length;
        });
       

        this.theme.event.subscribe(res=>{
            console.log("Current changed dial type is : "+res);
            if(res==0) //Click To Call
            {
                this.agentdialer=true;
                this.__autohangup=false;
            } 
            this.dial_type = res;
        });

        
        this.theme.newlead.subscribe(nres=>{
            console.log("new leads : "+nres);
            if(nres)
            {
                if(this.dialerstatus!=1)
                {
                    this.minutes=JSON.parse(localStorage.getItem("minutes"));
                    this.buy_minutes=JSON.parse(localStorage.getItem("buy_minutes"));
                    this.extra_minutes=JSON.parse(localStorage.getItem("extra_minutes"));
                    this.total_minutes = this.minutes + this.buy_minutes + this.extra_minutes;
                    this.current_number=localStorage.getItem("current_number");
                    this.callerid=localStorage.getItem("callerid");
                    this.status=[{icon:"mdi-timer-sand", value:this.total_minutes, title:"Remaining Minutes"}, {icon:"mdi-phone-incoming", value:this.callerid, title:"Caller Id"}];
                }
            }
        }); 

        this.theme.updatebalance.subscribe(tres=>{
            console.log(tres);
            console.log("Update BALANCE");
            if(tres)
            {
                this.minutes=JSON.parse(localStorage.getItem("minutes"));
                this.buy_minutes=JSON.parse(localStorage.getItem("buy_minutes"));
                this.extra_minutes=JSON.parse(localStorage.getItem("extra_minutes"));
                this.total_minutes = this.minutes + this.buy_minutes + this.extra_minutes;
                if(this.total_minutes>0)
                {
                    this.current_number=localStorage.getItem("current_number");
                    this.callerid=localStorage.getItem("callerid");
                    this.status=[{icon:"mdi-timer-sand", value:this.total_minutes, title:"Remaining Minutes"}, {icon:"mdi-phone-incoming", value:this.callerid, title:"Caller Id"}];
                    this.theme.UpdateBalance(false);
                    //Refresh callback notifications
                    this.mymessages=[];
                    this.cbdata= [];
                    this.__notification.next(this.mymessages);
                    this.gotnotification=false;
                    setTimeout (() => {
                        this.iosocket.openSocket("callback",localStorage.getItem("userid"));
                    }, 6000);
                }
                else
                {
                    alert("You have insufficient balance!");
                    this.auth.logout(); 
                }
            }
          });

            this.theme.blockautodial.subscribe(tres=>{
                //this.theme.blockAutodial(true);
                console.log("BLOCK AUTODIAL");
                console.log(tres);
                if(tres)
                    this.blockautodial=true;
                else
                    this.blockautodial=false;
            });

    }
      
    Hangup() {
        localStorage.setItem('isautodial', (0).toString());
        this.theme.ChangeAutodial(0);
        this.theme.StopAutodial(1);
        this.theme.ChangeEvent(0);       
    }

    UserLogout()
    {
        this.auth.logout();       
    }

    LoadLead(data:any)
    {
        let datalead = JSON.parse(data.leaddata);
        this.theme.CallBackLead(datalead);       
    }

    RefreshLead()
    {
        console.log("Get Contact");
        this.theme.ChangeLead(-4);
        this.mymessages=[];
        this.cbdata= [];
        this.__notification.next(this.mymessages);
        this.gotnotification=false;
        this.iosocket.openSocket("callback",localStorage.getItem("userid"));

        // Dummy Callback Notifications
        // let lead_data = JSON.stringify({"id":103253,"lead_level":"1A","salse_rep_name":"James Spencer","created_date":"05/09/2018 06:59 AM","disposition":"NC","queued":1,"didpick":0,"dispoattempts":46,"callback_time":0,"dispodate":"02/18/2019 06:00 PM","edit":{"first_name":"THADDEUS","last_name":"GRAY","spouse_full_name":"","street_address_1":"2796 TIMBERLINE DR","city":"CORTLAND","state":"OH","zip":"44410","county":"TRUMBULL","email":"","cell_phone":"13202076470","phone":"13202076470","work_phone":"13202076470"},"personalinfo":{"birth_date":"","age":"39","occupation":"","spouse_occupation":"","spouse_birth_date":"","spouse_age":"0","lender":"FIRST FED'L S&amp;amp;L/LAKEWOOD","mortgage_amount":"238425","purchase_refi":"","home_value":"","mortgage_term":"","monthly_mortage_payment":"","beneficiary":"","smoker":"No","height":"","weight":"","health_issue":"","spouse_smoker":"No","spouse_height":"","spouse_weight":"","spouse_health_issue":"","lead_level":"1A","assign_date":"2/5/18","status":"New Lead","referrer":""},"current_script":{"id":"53"},"comments":{"comments":""}});
        // this.mymessages.push({
        //     useravatar: 'assets/images/users/2.jpg',
        //     status: 'online',
        //     from: 'Pavan kumar',    
        //     subject: 'Just see the my admin!',
        //     time: '9:30 AM',
        //     idlead: 103208,
        //     leaddata:lead_data
        //   });
        // this.__notification.next(this.mymessages);
        // this.gotnotification=true;
    }   

    onSearch(search_text:any)
    {
        console.log("Search Data");
        console.log(search_text);
        console.log("Search Contact");
        this.theme.SearchLead(search_text);
    }

    enterSearch(event:any, search_text:any)
    {
        if(event.keyCode == 13){
            console.log("Search Data");
            console.log(search_text);
            console.log("key event");
            console.log(event.keyCode);
            console.log("Search Contact");
            this.theme.SearchLead(search_text);
        }
    }

    SaveLead()
    {
        console.log("Save Contact");
        this.theme.ChangeLead(-5);
    }   

    autodialing(event:number)
    {
        console.log('autodialing ' +event);
        this.theme.ChangeEvent(event);
        if(event==1) //Auto Dialing
        {
            this.agentdialer=false;
            this.__autohangup=true;
        }
        else //Click To Call
        {
            this.agentdialer=true;
            this.__autohangup=false;
        }
        localStorage.setItem('isautodial', event.toString());
        this.theme.ChangeAutodial(event);      
        console.log("Current URI : "+APIURL+"custom/changedialertype");      
    }
  
  	changeShowStatus(){
    	//this.showHide = !this.showHide;
    }

    // This is for Mymessages
        
    ngAfterViewInit() {

        $(function () {
            $(".preloader").fadeOut();
        });

        var set = function () {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            var topOffset = 70;
            if (width < 4170) {
                $("body").addClass("mini-sidebar");
                $("body").addClass("mini-sidebar1");
                $('.navbar-brand span').hide();
                $("body").addClass("postion");
                $(".scroll-sidebar, .slimScrollDiv").css("overflow-x", "visible").parent().css("overflow", "visible");
                $(".sidebartoggler i").addClass("ti-menu");
            } else {
                $("body").removeClass("mini-sidebar1");
                $("body").removeClass("mini-sidebar");
                $("app-rightview aside").removeClass("postion");
               
                $("body").removeClass("postion");
                $('.navbar-brand span').show();
                $(".sidebartoggler i").removeClass("ti-menu");
            }

            var height = ((window.innerHeight > 0) ? window.innerHeight : this.screen.height) - 1;
            height = height - topOffset;
            if (height < 1) height = 1;
            if (height > topOffset) {
                $(".page-wrapper").css("min-height", (height) + "px");
            }

        };
        $(window).ready(set);
        $(window).on("resize", set);

        $(document).on('click', '.mega-dropdown', function (e) {
            e.stopPropagation();
        });
        
        $(".search-box a, .search-box .app-search .srh-btn").on('click', function () {
            $(".app-search").toggle(200);
        });

        (<any>$('[data-toggle="tooltip"]')).tooltip();
        
        (<any>$('.scroll-sidebar')).slimScroll({
            position: 'left',
            size: "5px",
            height: '100%',
            color: '#dcdcdc'
        });

        (<any>$('.right-sidebar')).slimScroll({
            height: '100%',
            position: 'right',
            size: "5px",
            color: '#dcdcdc'
        });

        (<any>$('.message-center')).slimScroll({
            position: 'right',
            size: "5px",
            color: '#dcdcdc'
        });

        $("body").trigger("resize");
    }

    openSidebarNav(num: number) {
        let classname: String = '.btn' + num;
        if(document.querySelector(classname.toString()) !== null) {
            $(classname).click();
        }
        else {
            let classname: String = '.action-' + num;
            $(classname).click();
        }
        
    }
    isLoading = false;



    dragElement(elmnt: any) {
        var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        if (document.getElementById(elmnt.id + "header")) {
          /* if present, the header is where you move the DIV from:*/
          document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
        } else {
          /* otherwise, move the DIV from anywhere inside the DIV:*/
          elmnt.onmousedown = dragMouseDown;
        }
      
        function dragMouseDown(e) {
          e = e || window.event;
          e.preventDefault();
          // get the mouse cursor position at startup:
          pos3 = e.clientX;
          pos4 = e.clientY;
          document.onmouseup = closeDragElement;
          // call a function whenever the cursor moves:
          document.onmousemove = elementDrag;
        }
      
        function elementDrag(e) {
          e = e || window.event;
          e.preventDefault();
          // calculate the new cursor position:
          pos1 = pos3 - e.clientX;
          pos2 = pos4 - e.clientY;
          pos3 = e.clientX;
          pos4 = e.clientY;
          // set the element's new position:
          elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
          elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
        }
      
        function closeDragElement() {
          /* stop moving when mouse button is released:*/
          document.onmouseup = null;
          document.onmousemove = null;
        }
        
      }

      
}
