import { Injectable } from '@angular/core';
import { AppService } from '../shared/service/AppService.class';
import { HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';
import { Authentication} from './model/model.class';
import { APIURL } from '../app-routing.module';

@Injectable()
export class AuthenticationService extends AppService<Authentication>{
  constructor(protected http:HttpClient, protected router:Router) {
    super(http);
    this.appmod="login";
  }

  logout()
  {
    this.http.get(APIURL+"login").subscribe(res=>{
      /* 
      localStorage.removeItem('LeadID'); 
      localStorage.removeItem('minutes');
      localStorage.removeItem('callerid');
      localStorage.removeItem('callstatus');
      localStorage.removeItem('comment');
      localStorage.removeItem('contacts');
      localStorage.removeItem("current_address");
      localStorage.removeItem("current_lead");
      localStorage.removeItem("current_number");
      localStorage.removeItem("currscript");
      localStorage.removeItem("disposition");
      localStorage.removeItem('edit');
      localStorage.removeItem('email');
      localStorage.removeItem('extaccount');
      localStorage.removeItem('extpass');
      localStorage.removeItem('extuser');
      localStorage.removeItem('firstname');
      localStorage.removeItem('isLoggedin');
      localStorage.removeItem('number_type');
      localStorage.removeItem('pagetrack');
      localStorage.removeItem('personaldata');
      localStorage.removeItem('preferences');
      localStorage.removeItem('scripts');
      localStorage.removeItem("search_lead");
      localStorage.removeItem('token');
      localStorage.removeItem('type');
      localStorage.removeItem('userid');
      localStorage.removeItem('editmod');
      localStorage.removeItem('phone');
      localStorage.removeItem('account');
      localStorage.removeItem('exten');
      localStorage.removeItem('password');
      localStorage.removeItem('buy_minutes');
      localStorage.removeItem('extra_minutes');
      localStorage.removeItem('callattampts');
      localStorage.removeItem('isautodial');
      localStorage.removeItem('isdial');
      localStorage.removeItem('phonetype');
      localStorage.removeItem('rate');
      localStorage.removeItem('totalcallattempts'); 
      */

      localStorage.clear();
      
      console.log("User Logged Out Successfully");
      window.location.href="/";
      location.reload(); 
      window.close();     
    });
  }
}
