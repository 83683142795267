import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { environment } from '../environments/environment';
import { AuthGuard } from './authentication/guard/auth.guard';
import { LeadComponent } from './lead/lead-components/lead.component';
import { LeadlistComponent } from './shared/leadlist/leadlist.component';

var appuri, logouturi, leadupload, dncupload, fileupload, gallerydir, tiffdir, pngdir, downloaddir,appurl : string;
if(environment.production)
{
  appuri= environment.SERVER;
  logouturi = environment.SERVER+'logout';
  leadupload = environment.SERVER+'leads';
  dncupload = environment.SERVER+'dnc';
  fileupload = environment.SERVER+'fileupload';
  appurl = environment.SERVERURL;
  gallerydir= environment.SERVERURL+"file/";  
}
else
{
  appuri= environment.SERVER;
  logouturi = environment.SERVER+'logout';
  leadupload = environment.SERVER+'leads';
  dncupload = environment.SERVER+'dnc';
  fileupload = environment.SERVER+'fileupload';
 appurl = environment.SERVERURL;
  gallerydir= environment.SERVERURL+"file/";
}

export const APIURL=appuri;
export const LogoutURL = logouturi;
export const LeadsUpload = leadupload;
export const DncUpload = dncupload;
export const FileUpload = fileupload;
export const AUDIOURL = appurl;

//export const GalleryPath= gallerydir;
//export const TiffPath= tiffdir;
//export const PngPath= pngdir;
//export const DownloadPath= downloaddir;

export const AppRoutes : Routes = [
    {
    path: '',
    component: AppBlankComponent,    
    children: [{ 
      path: '', 
      redirectTo: 'login', 
      pathMatch: 'full' 
    },{
      path: 'login',
      loadChildren: './authentication/authentication.module#AuthenticationModule'
    }]
  },
  {
    path: 'main',
    component: FullComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: "always",
    children: [{
       path: '',
      redirectTo: 'dashboard', 
      pathMatch: 'full' ,
    }, 
    {
      path: 'mortgage',
      loadChildren: './mortgage/mortgage.module#MortgageModule'
   },
   {
    path: 'setting',
    loadChildren: './setting/setting.module#SettingModule'
    },
    {
      path: 'calendar',
      loadChildren: './calendar/calendar.module#CalendarsModule'
    }, 
   
    {
      path: 'leads',
      loadChildren: './lead/lead.module#LeadsModule',
    },
    
    /* {
      path: 'dashboard',
      loadChildren: './dashboard/dashboard.module#DashboardModule'
    },
  {
        path: 'dialer',
        loadChildren: './dialer/dialer.module#DialerModule'
    },
    {
      path: 'setting',
      loadChildren: './setting/setting.module#SettingModule'
  },
    {
      path: 'dashboard',
      loadChildren: './dashboard/dashboard.module#DashboardModule'
  },  */
   /* {
    path: 'testlogin',
    loadChildren: './testlogin/testlogin.module#TestloginModule'
},  */ 
      
  /* {
    path: 'phone',
    loadChildren: './phone/phone.module#PhoneModule'
  }, *///{
  //   path: 'email',
  //   loadChildren: './email/email.module#EmailModule'
  // },
   /*{
    path: 'chat',
    loadChildren: './chat/chat.module#ChatModule'
  },*/
  /* {
    path: 'profile',
    loadChildren: './profile/profile.module#ProfileModule'
  },
  {
    path: 'calendar',
    loadChildren: './calendar/calendar.module#CalendarsModule'
  }, */
  {
    path: 'careerdialer',
    loadChildren: './careerdialer/careerdialer.module#CareerModule'
  },

  /*
  {
    path: 'download',
    loadChildren: './download/download.module#DownloadModule'
  },
  */
    
    
    ]
  }];

  


@NgModule({
    imports: [RouterModule.forRoot(AppRoutes), NgbModule.forRoot()],
    exports: [RouterModule]
})
export class AppRoutingModule { }

