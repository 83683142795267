import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {
  label1:string;
  label2:string;
  label3:string;
  @Input()  value1: string;
  @Input()  value2?: string;
  @Input()  value3: string;
  @Input()  total: string;
  @Input()  error: string;

  size1:number;
  size2:number;
  size3:number;


  constructor() { }

  ngOnInit() {
    let tval: number;
    
    let val1=parseInt(this.value1);
    let val2=parseInt(this.value2);
    let val3=parseInt(this.value3);

    if(this.total=='')
      tval = (val1 + val2) + val3;  
    else
      tval= parseInt(this.total);
    
    if(tval>0){
      this.size1=this.roundval(val1*100/tval);
      //if(this.label1.length==0)
        this.label1=this.size1.toString()+"%";
      console.log(this.value2);
      if(val2==-1)
        this.size2=this.roundval((tval-val1-val3)*100/tval);
      else
        this.size2=this.roundval(val2*100/tval);
      //if(this.label2.length==0)
      this.label2=this.size2.toString()+"%";

      this.size3=this.roundval(val3*100/tval);
      //if(this.label3.length==0)
        this.label3=this.size3.toString()+"%";
    }
    else{
      this.size1=100;
      this.label1=this.error;
    }

      //console.log("Total : "+total+", Value 1 : "+this.value1+", Value 2 : "+this.value2+", Value 3 : "+this.value3+", Size 1 : "+this.size1+", Size 2 : "+this.size2+", Size 3 : "+this.size3);
  }
  
  roundval(n,x=1) {
    return Math.round(n/x)*x;
  }

}
