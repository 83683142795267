import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DemoMaterialModule } from '../demo-material-module';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { TitleNavigationService } from './title-nav/title-navigation.service';
import { ButtonComponent } from './button/button.component';
import { StatusComponent } from './status/status.component';
import { WidgetGroupComponent } from './widget-group/widget-group.component';
import { DataTableComponent } from './data-table/data-table.component';
import { KeysPipe } from './data-table/keypipe/keypipe';
import { SearchPipe } from './data-table/keypipe/searchpipe';

import { UploadviewComponent } from './uploadview/uploadview.component';
import { BarWidgetComponent } from './widget/bar-widget/bar-widget.component';
import { LineWidgetComponent } from './widget/line-widget/line-widget.component';
import { PointWidgetComponent } from './widget/point-widget/point-widget.component';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { PackageComponent } from './package/package.component';
import { DatetimeComponent } from './datetime/datetime.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { PieComponent } from './widget/pie/pie.component';
import { FormdetailComponent } from './formdetail/formdetail.component';
import { PopupformComponent } from './popupform/popupform.component';
import { SidebarComponent} from './sidebar/sidebar.component';
import { NavigationComponent } from './header-navigation/navigation.component';
import { BreadcrumbComponent} from './breadcrumb/breadcrumb.component';
import { MessageComponent } from './message/message.component';
import { ToggleComponent } from './toggle/toggle.component';
/* import { LeadComponent } from './lead/lead.component';
import { LeadlistComponent } from './leadlist/leadlist.component';
import { ContactSearchPipe } from './leadlist/keypipe/searchpipe'; */
import { FooterComponent } from './footer/footer.component';
import { SipJsService } from './service/sipjsServices.class';
import { DialerModule } from './dialer/dialer.module';
import { DialerComponent } from './dialer/dialer.component';
import { AgentComponent } from './agent/agent.component';
import { DialerDispositionComponent } from './dialer-disposition/dialer-disposition.component';
import { DialerReportComponent } from './dialer-report/dialer-report.component';
import { ContactComponent } from './contact/contact.component';
import { GraphicalReportsComponent } from './graphical-reports/graphical-reports.component';
import { RightSidebarComponent } from './right-sidebar/rightsidebar.component';
//import { RightSidebarComponent } from './right-sidebar/rightsidebar.component';
/* import { CustomReportComponent } from './custom/custom.component';
import { CustomService } from './custom/custom.service'; */

@NgModule({
  imports:[
    CommonModule,
    RouterModule,
    DemoMaterialModule,
    FormsModule,
    ChartsModule,
    DialerModule,
    MDBBootstrapModule.forRoot()
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ButtonComponent,
    StatusComponent,
    WidgetGroupComponent,
    DataTableComponent, 
    KeysPipe,
    SearchPipe,
    //ContactSearchPipe,
    BarWidgetComponent,
    LineWidgetComponent,
    PointWidgetComponent,
    UploadviewComponent, 
    ProgressBarComponent, 
    PackageComponent ,
    DatetimeComponent, 
    PieComponent,
    FormdetailComponent,
    PopupformComponent,
    SidebarComponent,
    NavigationComponent,
    BarWidgetComponent,
    BreadcrumbComponent,
    MessageComponent,
    ToggleComponent,
    FooterComponent,
    AgentComponent,
    DialerDispositionComponent,
    DialerReportComponent,
   ContactComponent,
    GraphicalReportsComponent
    
  ],
  
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    ButtonComponent,
    StatusComponent,
    WidgetGroupComponent,
    DataTableComponent,
    KeysPipe,
    SearchPipe,
    //ContactSearchPipe,
    BarWidgetComponent,
    LineWidgetComponent,
    PointWidgetComponent,
    UploadviewComponent,
    ProgressBarComponent,
    PackageComponent ,
    DatetimeComponent,
    PieComponent,
    FormdetailComponent,
    PopupformComponent,
    SidebarComponent,
    NavigationComponent,
    BreadcrumbComponent,
    ToggleComponent,
    FooterComponent,
    DialerComponent,
    DialerDispositionComponent,
    DialerReportComponent,
    ContactComponent,
    RightSidebarComponent 
   ],

  providers: [ TitleNavigationService, SipJsService ]

})

export class SharedModule { }