import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Dialer, DialerForm } from './model/dialer.class';
import { DialerService } from './dialer.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Theme } from '../service/AutoTheme';
import { SipJsService } from '../service/sipjsServices.class';
import { AppComponentClass } from '../service/AppComponent.class';
import { Scripts, Objection } from './model/script.class';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../../authentication/authentication.service';
import { AppointmentComponent } from '../../mortgage/appointment/appointment.component';
@Component({
  selector: 'app-dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.css']
})
export class DialerComponent extends AppComponentClass<Dialer, DialerForm> {
  form : FormGroup;
  insurance : boolean ;
  carrer : boolean;
  custome: boolean;
  insurancepersonal : boolean;
  script_name:string="";
  careerpersonal : boolean;
  leads:Dialer[]; 
  loadleads:boolean=false;
  //scriptstatus:boolean=false;


  leadActionButtons = [
    {
      id: 1,
      title: "Appointment",
      icon: "perm_contact_calendar"
    },
    {
      id: 2,
      title: "Call Back",
      icon: "call"
    },
    {
      id: 3,
      title: "No Contact",
      icon: "contact_support"
    },
    {
      id: 4,
      title: "Not Interested",
      icon: "not_interested"
    },
    {
      id: 5,
      title: "Wrong Number",
      icon: "visibility_off"
    },
    {
      id: 6,
      title: "Make Client",
      icon: "person_add"
    }
  ]


  @Output() dialerreport: EventEmitter<any> = new EventEmitter();
  @Output()  dialdisposition = new EventEmitter();

  @Input() mortgage : boolean =  false;
  //@Input() lead : boolean =  false;
  private __data = new BehaviorSubject<any>(null);
  public solution= this.__data.asObservable();

  private __sdata = new BehaviorSubject<any>(null);
  public sssolution= this.__sdata.asObservable();
  
  private __odata = new BehaviorSubject<any>(null);
  public objsolution= this.__odata.asObservable();

  datas=[];
  public currObjection=[]; 

  @Output()  objections = new EventEmitter();

  @Input() type : string = "";
  @Input() set lead(v : Dialer) {
    console.log("Setting current data");
    console.log(v);
    this.__data.next(v);
  }

  @Input() set sdata(v : Scripts[]) {
    console.log("Setting current scripts");
    console.log(v);
    this.__sdata.next(v);
  }

  @Input() set odata(v : Objection[]) {
    console.log("Setting current obserable");
    console.log(v);
    this.__odata.next(v);
  }  

  //color:string;
  step = 0;
  value :string;
  state:boolean=false;
  dt:string;

  datetimepicker:boolean;

  @Input() event=[{icon:"DH",text:"Disposition History", type:11}, {icon:"CH",text:"Call History", type:12,menu:[{}]}, {icon:"ND",text:"Number Dialed", type:13},{icon:"CA",text:"Call Answered", type:14},{icon:"AS",text:"Appointment Scheduled", type:15},{icon:"ED",text:"Email Details", type:16},{icon:"SD",text:"SMS Details", type:17}];

  menu : boolean;
  animal: string;
  name: string;

  callleads:Dialer[];
 
  showQueue = false;
  scriptmenu:Scripts[];

  menuright : boolean = true;
  //alllead=[];
  public extuser=localStorage.getItem("extuser") || "None";
  public dial_type:number = 0;
  public scriptdata:String;

  constructor(public data: DialerService, protected router: Router,protected auth:AuthenticationService, protected fb: FormBuilder, protected msg: MatSnackBar, public dialog: MatDialog, public theme:Theme,protected http:HttpClient,) {
    super(data, router, fb , msg);
    this.theme.showMenu(true);  
    this.cdata= new DialerForm(null); 
  }

  
  
  ngOnInit() {

    this.insurance = true;
    this.carrer = true;
    this.loadleads=true;
    this.custome =  true;
    this.insurancepersonal = true;
    this.careerpersonal = false;  
    this.solution.subscribe(res=>{
      console.log("Changed data on edit "+JSON.stringify(res));     
      if(res==null)
      {
        this.cdata= new DialerForm(null);        
      }
      else
      {

        console.log('***', res);
        console.log('******',this.cdata);

        this.cdata= new DialerForm(res);
        localStorage.setItem("oldscript", this.cdata.current_script.id.toString());
      }
    }); 

    this.sssolution.subscribe(res =>{
      console.log("Script Data at Dialer Module TS");
      console.log(res);
      let tmp=JSON.parse(localStorage.getItem('scripts'));
      if(tmp !=undefined)
      {
        console.log("Script Data at Dialer Module TS LOcal TEmp");
        this.scriptmenu=tmp; 
        console.log(this.scriptmenu);
        console.log(tmp.length);
        tmp.forEach((opt)=>{
          console.log(opt);
          console.log(this.cdata.current_script.id);
          if(opt.id==this.cdata.current_script.id)
          {              
            this.cdata.script=opt;              
            console.log("SS Local Data :: "+JSON.stringify(this.cdata.current_script));
          }
          else
          {
            if(opt.id==parseInt(JSON.parse(localStorage.getItem("currscript"))))
            {
              this.cdata.script=opt;
            }
          }
        });  
      }
      else
      {    console.log("Script Data at Dialer Module TS RESSSS");        
          if(res!=null){     
            this.scriptmenu=res; 
              res.forEach((opt)=>{
                if(opt.id==this.cdata.current_script.id)
                {              
                  this.cdata.script=opt;              
                  console.log("SS Data :: "+JSON.stringify(this.cdata.current_script));
                }
                else
                {
                  if(opt.id==parseInt(JSON.parse(localStorage.getItem("currscript"))))
                  {
                    this.cdata.script=opt;
                  }
                }
              });      
        } 
        else
        {
          window.location.reload();
        }
      }
    });   

    /* this.theme.scriptsolution.subscribe(res =>{
      this.optmenu=res;
      if(res!=null){
        this.optmenu.forEach((opt)=>{
          if(opt.id==this.cdata.current_script.id)
          {
            //this.scriptdata=opt.script;
            this.cdata.script=opt;
            this.cdata.current_script=opt;
          }
        });
      }
    });  */

    //current page track
    var current_page = localStorage.getItem('pagetrack');
    console.log("current_page");
    console.log(current_page);
    if(current_page!='dialer')
    {
      localStorage.setItem('pagetrack', 'dialer');
      window.location.reload();
    }

    

    this.theme.event.subscribe(res=>{
      console.log("Current changed dial type is : "+res);
      this.dial_type = res;
    });


    let contact_data=JSON.parse(localStorage.getItem('contacts'));

    this.objsolution.subscribe(res =>{
      console.log("Objection fields ::");
      console.log(res);
      if(res != null || res != undefined)
      {
        this.datas=[];
        let objection = res;
        console.log(objection);
        let currobj = localStorage.getItem("objection_id");
        if(currobj != null && currobj != undefined)
        {
          if(currobj.length > 0)
          {
            let obj = currobj.split(",");
            console.log(obj);
            for(let j=0; j < objection.length; j++)
            {
              for(let i=0; i < obj.length; i++)
              {
                console.log("Current Objection :: "+objection[j].id +"== "+parseInt(obj[i]));
                  if(objection[j].id == parseInt(obj[i]))
                  {
                    this.datas[objection[j].indexid] = {index:objection[j].id, value:objection[j].script_name, indexid:objection[j].indexid, abbr:objection[j].abbr, script:objection[j].script_body, color:"warn"};
                  }               
              }
              if(!this.datas[objection[j].indexid])
              {
                this.datas[objection[j].indexid] = {index:objection[j].id, value:objection[j].script_name, indexid:objection[j].indexid, abbr:objection[j].abbr, script:objection[j].script_body, color:"primary"};
              }
            }          
          }
          else
          {
            for(let j=0; j < objection.length; j++)
            {
              this.datas[objection[j].indexid] = {index:objection[j].id, value:objection[j].script_name, indexid:objection[j].indexid, abbr:objection[j].abbr, script:objection[j].script_body, color:"primary"};
            }
          }          
        }
        else
        {
          for(let j=0; j < objection.length; j++)
          {
            this.datas[objection[j].indexid] = {index:objection[j].id, value:objection[j].script_name, indexid:objection[j].indexid, abbr:objection[j].abbr, script:objection[j].script_body, color:"primary"};
          }
        }
        console.log(this.datas);      
      } 
    },
    err=>{  
      this.msg.open('No Objection are found into the system', 'Clear', {
        duration: 5000,
      });
      // console.log(error);
    }); 

  }

  ChangeScript(type:number)
  {
    console.log("changetype: "+type);
    localStorage.setItem("currscript", JSON.stringify(type));
    this.cdata.current_script.id=type;

    let tmp=localStorage.getItem('scripts');
    if(tmp==null || tmp=="undefined")
    {
      this.sssolution.subscribe(rs=>{
        this.scriptmenu=rs;
        rs.forEach((opt)=>{
          if(opt.id==type)
            this.cdata.script=opt;
        });
        this.state=true;
      });
    }
    else
    {
      this.scriptmenu=JSON.parse(tmp);
      this.scriptmenu.forEach((opt)=>{
        if(opt.id==type)
          this.cdata.script=opt;
      });
      this.state=true;
    }
  }

  

  onObClick(obdata:any)
  {
    console.log("Objections click:"+ obdata.index+"  :: Abbr :- "+obdata.abbr+" :::  ObScript : "+obdata.script);
    console.log(obdata);
    if(obdata.script !=null && obdata.script != undefined) 
      this.cdata.script={id:obdata.index, script_name:obdata.value, script:obdata.script};

    this.objections.emit({id:obdata.index, index:obdata.indexid, abbr:obdata.abbr});  
  }
  
  backScript()
  {
    console.log(localStorage.getItem("oldscript"));    
    this.ChangeScript(parseInt(localStorage.getItem("oldscript")));
  }

  onOpen()
	{
        $('.right-sidebar').show();
        console.log("data show here");
  }
  
  closeMenu()
  {
    this.menuright  = true;
    console.log("close");

  }

  onDialerReport(option)
{
    this.dialerreport.emit(option);
}

//   openSidebarNav(number) {
//     if (number === 6) {
//         let c = confirm('Do you really want to make this client?');
//         if (c) {
//             this.dialdisposition.emit(number);
//         }
//     } else {
//       this.dialdisposition.emit(number);
//     }
     
    
// }

 
showRSL() {
  document.querySelector('#__leads').classList.add('shw-rside');
}

onClickButton(option)
{
  console.log("data show in dialer share module")
    this.dialerreport.emit(option);
}


  ngOnDestory(){ }


  openAppointmentDialog() {
    const dialogRef = this.dialog.open(AppointmentComponent, {
      // width: '100%', // Adjust width as needed
      // data: { /* pass any data if needed */ }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // Handle the result if needed
    });
  }


  openSidebarNav(num:number) {
    let classname: String = ".btn" + num;
    if (num === 6) {
        let c = confirm('Do you really want to make this client?');
        if (c) {
            $(classname).click();
        }
    }
    else {
      $(classname).click();
    }  
  }  

  navigateToMortgage() {
    this.router.navigate(['main/mortgage']);
  }
  navigateToLeads() {
    this.router.navigate(['main/leads']);
}
navigateToSetting() {
  this.router.navigate(['main/setting']);
}
navigateToCalendar() {
  this.router.navigate(['main/calendar/googlelogin']);
}
navigateToAppointment() {
  this.router.navigate(['main/leads']);
}
navigateToCallback() {
  this.router.navigate(['main/leads']);
}
navigateToMakeclient() {
  this.router.navigate(['main/leads']);
}
navigateToNocontact() {
  this.router.navigate(['main/leads']);
}
navigateToNotenterrested() {
  this.router.navigate(['main/leads']);
}


}
