import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Dialer } from '../dialer/model/dialer.class';

@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.css']
})

export class LeadComponent implements OnInit {
  name:string;
  status:string;
  dispo_datetime:string;
  color:string;
  @Input() data:Dialer;
  @Output() leadclick: EventEmitter<Dialer> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    //console.log("this lead component"+JSON.stringify(this.data));
    this.name=this.data.edit.first_name + " "+ this.data.edit.last_name;
    this.status=this.data.disposition;
    this.dispo_datetime="";
    if(this.data.disposition=="fresh")
      this.color="#28A745";
    if(this.data.disposition=="NC")
      this.color="gold";
    if(this.data.disposition=="CB")
    {
      this.color="yellow";
      this.dispo_datetime=this.data.dispodate;
    }
    if(this.data.disposition=="APPT" || this.data.disposition=="DK")
    {
      this.color="darkseagreen";
      this.dispo_datetime=this.data.dispodate;
    }
    if(this.data.disposition=="NI")
      this.color="salmon";
    if(this.data.disposition=="W#")
      this.color="tomato";
  }

  onClick()
  {
    this.leadclick.emit(this.data);
    // window.location.reload();
  }
}
