import { Component, OnInit, Input } from '@angular/core';
import { Edit } from '../model/edit.class';
import { BehaviorSubject } from 'rxjs';
import { Phone, PhoneForm } from '../model/phone.class';
import { Email, EmailForm } from '../model/email.class';
import { Mortgage, MortgageForm } from '../model/mortgage.class';

@Component({
  selector: 'dialer-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {

 @Input() type : any;

 @Input() insurance : boolean = false ;
 @Input() mortgage : boolean = false ;
 @Input() career : boolean = false ;
 @Input() custom : boolean = false ;
 
private __data = new BehaviorSubject<any>(null);
public solution= this.__data.asObservable();
public phone : Phone;
public email : Email;
public mortga : Mortgage;
 
 //@Input() editdata : boolean = false ;
  constructor() { }
  @Input() set editdata(v : Edit) {
    console.log("Setting current data");
    console.log(v);
    this.__data.next(v);
  }

  ngOnInit() {
    this.solution.subscribe(res=>{
      console.log("Changed data on edit "+res);     
      if(res==null)
      {
        this.phone=new PhoneForm(null);
        this.email=new EmailForm(null);
        this.mortga=new MortgageForm(null);
        //this.cdata= new DialerForm(null);
        //this.form=this.cdata.makeform(this.fb); 
      }
      else
      {
        this.phone=new PhoneForm(res);
        this.email=new EmailForm(res);
        this.mortga=new MortgageForm(res);    
      } 
    });
  }
  
  togglePopover() {
    const popover = document.querySelector('.popover');
    popover.classList.toggle('popover--active');
  }

  inputValue: string = '';

  updateInput(value: string) {
    this.inputValue += value; // Update the input field with the clicked number
  }
  removeLastInput() {
    this.inputValue = this.inputValue.slice(0, -1); // Remove the last character from the input field
  }

}
