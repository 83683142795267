import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Dialer } from '../dialer/model/dialer.class';
//import {ContactSearchPipe} from './keypipe/searchpipe';


@Component({
  selector: 'app-leadlist',
  templateUrl: './leadlist.component.html',
  styleUrls: ['./leadlist.component.css']
})
export class LeadlistComponent implements OnInit {

  @Input() data:Dialer[];
  @Output() rowclick: EventEmitter<Dialer> = new EventEmitter();
  searchtext="";

  constructor() {
      
  }

  ngOnInit() {
    this.searchtext="";
  }

  onLeadClick(data:Dialer)
  {
    //console.log("this leadliist component"+JSON.stringify(data));
    this.rowclick.emit(data);
    this.searchtext="";
  }


}
