import { FormBuilder, FormGroup } from "@angular/forms";
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
} from "angular-calendar";

// staring for Edit  module
export interface Phone {
  cell_phone: string;
  phone: string;
  work_phone: string;
  //emailtemplate : EmailTemplate,
  //smstemplate : SmsTemplate
}

export interface HTTPPhone {
  cell_phone: string;
  phone: string;
  work_phone: string;
  //emailtemplate : EmailTemplate,
  //smstemplate : SmsTemplate
}

export class PhoneForm implements HTTPPhone {
  public cell_phone: string;
  public phone: string;
  public work_phone: string;
  //public emailtemplate : EmailTemplate;
  //public smstemplate : SmsTemplate;

  constructor(data: Phone) {
    if (data == null) {
      this.cell_phone = "";
      this.phone = "";
      this.work_phone = "";
      //this.emailtemplate=new FormEmailTemplate(null);
      //this.smstemplate=new FormSmsTemplate(null);
    } else {
      var numberPattern = /\d+/g;

      if (data.cell_phone) {
        let cellnum = data.cell_phone;
        if (cellnum) {
          let cellno = cellnum.match(numberPattern);

          if (cellno) {
            cellnum = "";
            cellno.forEach((val) => {
              cellnum += val;
            });
            if (cellnum.length == 10) cellnum = "1" + cellnum;
          }
        }
        this.cell_phone = cellnum;
      } else this.cell_phone = "";

      if (data.phone) {
        let homenum = data.phone;
        if (homenum) {
          let homeno = homenum.match(numberPattern);

          if (homeno) {
            homenum = "";
            homeno.forEach((val) => {
              homenum += val;
            });
            if (homenum.length == 10) homenum = "1" + homenum;
          }
        }
        this.phone = homenum;
      } else this.phone = "";

      if (data.work_phone) {
        let worknum = data.work_phone;
        if (worknum) {
          let workno = worknum.match(numberPattern);

          if (workno) {
            worknum = "";
            workno.forEach((val) => {
              worknum += val;
            });
            if (worknum.length == 10) worknum = "1" + worknum;
          }
        }
        this.work_phone = worknum;
      } else this.work_phone = "";
      //this.emailtemplate=new FormEmailTemplate(data.emailtemplate);
      //this.smstemplate=new FormSmsTemplate(data.smstemplate);
    }
  }

  public makeform(fb: FormBuilder): FormGroup {
    return fb.group({
      cell_phone: [this.cell_phone],
      phone: [this.phone],
      work_phone: [this.work_phone],
      //emailtemplate: [this.emailtemplate]
    });
  }

  public saveForm(fg: FormGroup): boolean {
    if (fg.valid) {
      this.cell_phone = fg.get("cell_phone").value;
      this.phone = fg.get("phone").value;
      //this.emailtemplate=fg.get("emailtemplate").value;
      return true;
    } else {
      return false;
    }
  }
}

//End Edit form
