import { Component, OnInit, Input, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AppComponentClass } from '../../service/AppComponent.class';
import { DialerService } from '../dialer.service';
import { Scripts, FormScripts } from '../model/script.class';
import { Mortgage, MortgageForm } from '../model/mortgage.class';

@Component({
    selector: 'dialer-currentincome',
    templateUrl: './currentincome.component.html',
    styleUrls: ['./currentincome.component.css']
})

export class CurrentincomeComponent extends AppComponentClass<Mortgage, MortgageForm>  {
    public form:FormGroup;
    private __data = new BehaviorSubject<Mortgage>(null);
    public solution = this.__data.asObservable();

    // public optmenu:CareerScripts[];
    public curscript: any;

    constructor(public edata: DialerService, protected router: Router, protected fb: FormBuilder, protected msg: MatSnackBar) {
        super(edata, router, fb, msg);
    }
    @Input() set editdata(v: Mortgage) {
        this.debug_log("Setting current data for script to ");
        this.__data.next(v);
    }

    ngOnInit() {
        this.solution.subscribe(res => {

            if (res == null) 
            {
                this.cdata = new MortgageForm(null);
                this.form=this.cdata.makeform(this.fb);
            }
            else {
                
                this.cdata= new MortgageForm(res);
                this.form=this.cdata.makeform(this.fb);
            }

        });
    }

    onSubmit()
    {
      if(this.cdata.saveForm(this.form))
        super.onSubmit();
    }
    

    warning(){
        if(this.cdata.c_i_salery_a != this.form.get("c_i_salery_a").value)
      this.cdata.c_i_salery_a = this.form.get("c_i_salery_a").value;
        if(this.cdata.c_i_investment_i != this.form.get("c_i_investment_i").value)
      this.cdata.c_i_investment_i = this.form.get("c_i_investment_i").value;
      if(this.cdata.c_i_other != this.form.get("c_i_other").value)
      this.cdata.c_i_other = this.form.get("c_i_other").value;
    }

    changeEvnt(key:string){
        console.log("ashdgkey",key);
        if(key == 'c_i_salery_a')
      this.cdata.c_i_salery_a = this.form.get("c_i_salery_a").value;
        if(key == 'c_i_investment_i')
      this.cdata.c_i_investment_i = this.form.get("c_i_investment_i").value;
      if(key == 'c_i_other')
      this.cdata.c_i_other = this.form.get("c_i_other").value;


      localStorage.setItem("editmod", JSON.stringify(this.cdata));
    console.log("OnChange events called edit data : Key "+key+" Value :"+this.form.get(key).value);
    console.log(localStorage.getItem("editmod"));




      var editFromLocalStorage = JSON.parse(localStorage.getItem("edit"));
      editFromLocalStorage.c_i_salery_a = this.cdata.c_i_salery_a;
      editFromLocalStorage.c_i_investment_i = this.cdata.c_i_investment_i;
      editFromLocalStorage.c_i_other = this.cdata.c_i_other;
      localStorage.setItem("edit", JSON.stringify(editFromLocalStorage));


      let current=parseInt(localStorage.getItem("current_lead"));

    let search_lead=parseInt(localStorage.getItem("search_lead"));
    if(search_lead!=0)
    {
      current = search_lead;
    }
    console.log("current_lead: "+current);

     // update contact localstorage data
     var contactsFromLocalStorage = JSON.parse(localStorage.getItem("contacts"));
     contactsFromLocalStorage[current].edit = JSON.parse(localStorage.getItem("edit"));
     localStorage.setItem("contacts",JSON.stringify(contactsFromLocalStorage));

      
    }

    

}
