import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import socketIo from 'socket.io-client';
import { Socket } from '../model/socket.model';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';

declare var io: {
  connect(url: string): Socket;
};

@Injectable()
export class SocketService<T> {
  private socket: Socket;


  constructor() {
    console.log(environment.WEBSOCKET);
    // this.socket = socketIo(environment.WEBSOCKET);
  }

  public RecieveEvents(event: string) {
    let data = new BehaviorSubject<any>("");
    let value = data.asObservable();

    /* this.socket.on("callback_lead", (res) => {
      console.log("Recieve Socket Called ::"+event+" With Data"+res);
      data.next(res);
      return value;
    }); */

    this.socket.on(event, (res) => {
      console.log("Recieve Socket Called ::" + event + " With Data" + res);
      data.next(res);
      return value;
    });

    return value;
  }

  public SendEvents(event: string, data: T): void {
    console.log("Socket Events Called ::" + event + " ,, with Data::" + data);
    this.socket.emit(event, data);
  }

  private handleError(error) {
    console.error('server error:', error);
    if (error.error instanceof Error) {
      let errMessage = error.error.message;
      return Observable.throw(errMessage);
    }
    return Observable.throw(error || 'Socket.io server error');
  }
}  