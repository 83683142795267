import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';


@Component({
  selector: 'dialer-report',
  templateUrl: './dialer-report.component.html',
  styleUrls: ['./dialer-report.component.scss']
})
export class DialerReportComponent implements OnInit {

  @Input() dialerreport:string[];

  @Output() active: EventEmitter<number> = new EventEmitter();

  modal:string;
  animal: string;
  name: string;
  constructor(public dialog: MatDialog) {}

  ngOnInit() {
  }

  OnClick(type:number)
  {
    //console.log("Current event : "+type);
    var value: number;
    this.active.emit(type);
    //var  value:  string;
     
    /* switch (type) {
        case 10:
      {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {name: this.name, animal: this.animal}
    });

        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.animal = result;
        });
        }
         break;
        case 20:
        console.log('Month Name:2');
        const dialogRef = this.dialog.open(Callback, {
          width: 'auto',
          data: {name: this.name, animal: this.animal}
        });
    
            dialogRef.afterClosed().subscribe(result => {
              console.log('The dialog was closed');
              this.animal = result;
            });
          break;
    } */
  //  console.log('Month Name:');
  }

}

