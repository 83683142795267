
import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AppComponentClass } from '../../../service/AppComponent.class';
import { Theme } from '../../../service/AutoTheme';
import { CareerEditService } from '../../../../careerdialer/careerdialer.services';
import { Insurance, InsuranceForm } from '../../model/insurance.class';
import { DialerService } from '../../dialer.service';


@Component({
  selector: 'dialer-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css']
})

export class InsuranceComponent extends AppComponentClass<Insurance, InsuranceForm> {
 
  //private ssocket: SocketService<string>;
  
  constructor(public data: DialerService,  protected router: Router, protected fb: FormBuilder, protected msg: MatSnackBar, protected theme:Theme)
  {
    super(data, router, fb , msg); 
   // this.ssocket=new SocketService();
  }

  public emailtemp = ['Item1','Item2'];


  ngOnInit() {
  }

  
  clickEmail(value:number) {
    console.log(value);
  }

  qResponses=[
    {value: '1', viewValue: 'Demo SMS1'},
    {value: '2', viewValue: 'Demo SMS2'}
  ];
  vResponses1=[
    {value: '1', viewValue: 'Demo VD1'},
    {value: '2', viewValue: 'Demo VD2'}
  ];
  eResponses2=[
    {value: '1', viewValue: 'Demo Email1'},
    {value: '2', viewValue: 'Demo Email2'}
  ];

  /* login(user: ){
    if (user.userName == '1'  ) { // {3}
      this.loggedIn.next(true);
    }
  } */

}
