import { FormBuilder, FormGroup} from "@angular/forms";
import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent
  } from 'angular-calendar';

// staring for Edit  module
export interface Mortgage 
{
    fullname : string,
    first_name : string,
    last_name : string,
	spouse_full_name : string,
    street_address_1 : string,
    city : string,
    state : string,
    zip : string,
    county : string,
    c_i_salery_a:string;
    c_i_investment_i:string;
    c_i_other:string;
    r_s_name_owner:string;
    r_s_location:string;
    r_s_mortfgage_balence:string;
    r_s_maket_value:string;
    safe_d_bank:string;
    safe_d_location:string;
    safe_d_access:string;
    safe_d_content:string;
    feduciaries_executor:string;
    feduciaries_address:string;
feduciaries_alternet_executor:string;
c_b_name_account:string;
c_b_bank:string;
c_b_type_account:string;
c_b_average_balance:string;
comments_:string;
    /* email : string,
    cell_phone : string,
    phone : string,
    work_phone : string, */
    //emailtemplate : EmailTemplate,
    //smstemplate : SmsTemplate
}

export interface HTTPMortgage
{
    first_name : string,
    last_name : string,
    fullname : string,
	spouse_full_name : string,
    street_address_1 : string,
    city : string,
    state : string,
    zip : string,
    county : string,
    c_i_salery_a:string;
    c_i_investment_i:string;
    c_i_other:string;
    r_s_name_owner:string;
    r_s_location:string;
    r_s_mortfgage_balence:string;
    r_s_maket_value:string;
    safe_d_bank:string;
    safe_d_location:string;
    safe_d_access:string;
    safe_d_content:string;
    feduciaries_executor:string;
feduciaries_address:string;
feduciaries_alternet_executor:string;
c_b_name_account:string;
c_b_bank:string;
c_b_type_account:string;
c_b_average_balance:string;
comments_:string;
    /* email : string,
   /*  email : string,
    cell_phone : string,
    phone : string,
    work_phone : string, */
    //emailtemplate : EmailTemplate,
    //smstemplate : SmsTemplate
}

export class MortgageForm implements HTTPMortgage{
    public first_name : string;
    public  last_name : string;
    public fullname : string;
	public spouse_full_name : string;
    public  street_address_1 : string;
    public  city : string;
    public state : string;
    public zip : string;
    public county : string;
    public c_i_salery_a:string;
    public c_i_investment_i:string;
    public c_i_other:string;
    public r_s_name_owner:string;
    public r_s_location:string;
    public r_s_mortfgage_balence:string;
    public r_s_maket_value:string;
    public safe_d_bank:string;
    public safe_d_location:string;
    public safe_d_access:string;
    public safe_d_content:string;
    public feduciaries_executor:string;
  public feduciaries_address:string;
  public feduciaries_alternet_executor:string;
  public c_b_name_account:string;
  public c_b_bank:string;
  public c_b_type_account:string;
  public c_b_average_balance:string;
  public comments_:string;
    /* public email : string;
    public cell_phone : string;
    public phone : string;
    public work_phone : string; */
    //public emailtemplate : EmailTemplate;
    //public smstemplate : SmsTemplate;

    constructor(data : Mortgage) 
    {
        if(data==null)
        {
            this.first_name="";
            this.last_name="";
            this.fullname=this.first_name+" "+this.last_name;
            this.spouse_full_name="";
            this.street_address_1="";
            this.city="";
            this.state="",
            this.zip="";
            this.county="";
            this.c_i_salery_a="";
            this.c_i_investment_i="";
            this.c_i_other="";
            this.r_s_name_owner="";
            this.r_s_location="";
            this.r_s_mortfgage_balence="";
            this.r_s_maket_value="";
            this.safe_d_bank="";
            this.safe_d_location="";
            this.safe_d_access="";
            this.safe_d_content="";
            this.feduciaries_address;
            this.feduciaries_alternet_executor;
            this.c_b_name_account;
            this.c_b_bank;
            this.c_b_type_account;
            this.c_b_average_balance;
            this.comments_;
            
           /*  this.email="";
            this.cell_phone="";
            this.phone="";
            this.work_phone=""; */
            //this.emailtemplate=new FormEmailTemplate(null);
            //this.smstemplate=new FormSmsTemplate(null);
        }
        else
        {
            this.first_name=data.first_name;
            this.last_name=data.last_name;
            this.fullname=this.first_name.toUpperCase()+" "+this.last_name.toUpperCase();
            this.spouse_full_name=data.spouse_full_name;
            this.street_address_1=data.street_address_1;
            this.city=data.city;
            this.state=data.state;
            this.zip=data.zip;
            this.county=data.county;
            this.c_i_salery_a=data.c_i_salery_a;
            this.c_i_investment_i=data.c_i_investment_i;
            this.c_i_other=data.c_i_other;
            this.r_s_name_owner=data.r_s_name_owner;
            this.r_s_location=data.r_s_location;
            this.r_s_mortfgage_balence=data.r_s_mortfgage_balence;
            this.r_s_maket_value=data.r_s_maket_value;
            this.safe_d_bank=data.safe_d_bank;
            this.safe_d_location=data.safe_d_location;
            this.safe_d_access=data.safe_d_access;
            this.safe_d_content=data.safe_d_content;
            this.feduciaries_executor = data.feduciaries_executor;
      this.feduciaries_address =data.feduciaries_address;
      this.feduciaries_alternet_executor= data.feduciaries_alternet_executor;
      this.c_b_name_account = data.c_b_name_account;
      this.c_b_bank= data.c_b_bank;
      this.c_b_type_account = data.c_b_type_account;
      this.c_b_average_balance = data.c_b_average_balance;
      this.comments_ = data.comments_;

           /*  this.email=data.email; */
           /*  var numberPattern = /\d+/g;

            let cellnum = data.cell_phone;
            if(cellnum)
            {
                let cellno = (cellnum).match(numberPattern);

                cellnum = '';
                cellno.forEach(val=>{
                    cellnum += val;
                })
                if(cellnum.length==10)
                    cellnum = '1'+cellnum;
            }
            this.cell_phone=cellnum;

            let homenum = data.phone;
            if(homenum)
            {
                let homeno = (homenum).match(numberPattern);
                
                homenum = "";
                homeno.forEach(val=>{
                    homenum += val;
                })
                if(homenum.length==10)
                    homenum = '1'+homenum;
            }
            this.phone=homenum;

            let worknum = data.work_phone;
            if(worknum)
            {
                let workno = (worknum).match(numberPattern);

                worknum = '';
                workno.forEach(val=>{
                    worknum += val;
                })
                if(worknum.length==10)
                    worknum = '1'+worknum;
            }
            this.work_phone=worknum; */
            //this.emailtemplate=new FormEmailTemplate(data.emailtemplate);
            //this.smstemplate=new FormSmsTemplate(data.smstemplate);
        }
    }

    public makeform(fb:FormBuilder):FormGroup
    {
        return fb.group({
            first_name: [this.first_name],
            last_name: [this.last_name],
            fullname: [this.fullname],
            spouse_full_name: [this.spouse_full_name],
            street_address_1: [this.street_address_1],
            city: [this.city],
            state: [this.state],
            zip: [this.zip],
            county: [this.county],
            c_i_salery_a:[this.c_i_salery_a],
            c_i_investment_i:[this.c_i_investment_i],
            c_i_other:[this.c_i_other],
            r_s_name_owner:[this.r_s_name_owner],
            r_s_location:[this.r_s_location],
            r_s_mortfgage_balence:[this.r_s_mortfgage_balence],
            r_s_maket_value:[this.r_s_maket_value],
            safe_d_bank:[this.safe_d_bank],
            safe_d_location:[this.safe_d_location],
            safe_d_access:[this.safe_d_access],
            safe_d_content:[this.safe_d_content],
            feduciaries_executor:[this.feduciaries_executor],
      feduciaries_address:[this.feduciaries_address],
      feduciaries_alternet_executor:[this.feduciaries_alternet_executor],
      c_b_name_account:[this.c_b_name_account],
      c_b_bank:[this.c_b_bank],
      c_b_type_account:[this.c_b_type_account],
      c_b_average_balance:[this.c_b_average_balance],
      comments_:[this.comments_],
            
            /* email: [this.email],
            cell_phone: [this.cell_phone],
            phone: [this.phone],
            work_phone: [this.work_phone], */
            //emailtemplate: [this.emailtemplate]
        });
    }

    public saveForm(fg:FormGroup):boolean
    {
        if(fg.valid)
        {

           this.first_name=fg.get("first_name").value;
            this.last_name=fg.get("last_name").value;
            this.fullname=fg.get("fullname").value;
            this.spouse_full_name=fg.get("spouse_full_name").value;
            this.street_address_1=fg.get("street_address_1").value;
            this.city=fg.get("city").value;
            this.state=fg.get("state").value;
            this.zip=fg.get("zip").value;
            this.county=fg.get("county").value;
            this.c_i_salery_a=fg.get("c_i_salery").value;
            this.c_i_investment_i=fg.get("c_i_investment_i").value;
            this.c_i_other=fg.get("c_i_other").value;
            this.r_s_name_owner=fg.get("r_s_name_owner").value;
            this.r_s_location=fg.get("r_s_location").value;
            this.r_s_mortfgage_balence=fg.get("r_s_mortfgage_balence").value;
            this.r_s_maket_value=fg.get("r_s_maket_value").value;
            this.safe_d_bank=fg.get("safe_d_bank").value;
            this.safe_d_location=fg.get("safe_d_location").value;
            this.safe_d_access=fg.get("safe_d_access").value;
            this.safe_d_content=fg.get("safe_d_content").value;
            this.feduciaries_executor=fg.get(this.feduciaries_executor).value;
      this.feduciaries_address=fg.get(this.feduciaries_address).value;
      this.feduciaries_alternet_executor=fg.get(this.feduciaries_alternet_executor).value;
      this.c_b_name_account=fg.get(this.c_b_name_account).value;
      this.c_b_bank=fg.get(this.c_b_bank).value;
      this.c_b_type_account=fg.get(this.c_b_type_account).value;
      this.c_b_average_balance=fg.get(this.c_b_average_balance).value;
      this.comments_=fg.get(this.comments_).value;
           /*  this.email=fg.get("email").value;
            this.cell_phone=fg.get("cell_phone").value;
            this.phone=fg.get("phone").value; */
            //this.emailtemplate=fg.get("emailtemplate").value;
            return true;
        }
        else
        {
            return false;
        }
    }
  }

  //End Edit form