import { Component, OnInit, Input, Output, EventEmitter, forwardRef, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, ControlValueAccessor } from '@angular/forms';


@Component({
  selector: 'ChangeStatus',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.css'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => StatusComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => StatusComponent), multi: true }
  ]
})

export class StatusComponent implements ControlValueAccessor, OnInit, OnChanges{ 
  propagateChange:any = () => {};
  validateFn:any = () => {};

  @Input() textlabel:string;
  @Input('curstatus') _curstatus:number=0;
  @Input() toggleclass:string;
  @Input() toggle:boolean;
  @Output() active: EventEmitter<boolean> = new EventEmitter();
  @Output() inactive: EventEmitter<boolean> = new EventEmitter();

  public optstatus:boolean;
  public state:boolean=true;
  public state2:boolean=false;


  constructor() { 

  }

  get curstatus() {
    return this._curstatus;
  }
  
  set curstatus(val) {
    this._curstatus = val;
    this.propagateChange(val);
  }

  ngOnChanges(inputs) {
      this.propagateChange(this.curstatus);
  }

  writeValue(value) {
    if (value) {
      this.curstatus = value;
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  ngOnInit() 
  {
    if(this.toggle){
      if(this._curstatus==1)
        this.optstatus=true;
      else if(this._curstatus==0)
        this.optstatus=false;
      else{
        this.state=false;
        this.state2=true;
      }
    }
  }

  OnChange(events)
  {
    console.log(this._curstatus);
    if(events.checked===true)
    {
      console.log("Currently checked");
      this.active.emit(true);
      this.optstatus=true;
      this._curstatus=1;
    }
    else
    {
      console.log("Currently unchecked");
      this.inactive.emit(true);
      //this.active.emit(false);
      this.optstatus=false;
      this._curstatus=0;
      //this.checked=false;
    }
    console.log(this._curstatus);
  }

}
