import { Injectable } from "@angular/core";
import { SocketService } from "./socket.service";

@Injectable()
export class ServerSocket extends SocketService<any>
{
  openSocket(events:string, data:any):void
  {
    console.log("Sockets Request initiated");
    this.SendEvents(events, data);
  }
}  