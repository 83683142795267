import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available

    if (request.url.startsWith("http://localhost:8000/api/v1")) {
      let remoteToken = localStorage.getItem("remoteToken");
      if (remoteToken) {
        request = request.clone({
          setHeaders: { Authorization: "Bearer " + remoteToken },
        });
      }
    } else {
      let currentUser = localStorage.getItem("token");
      console.log("JwtInterceptor token(" + currentUser + ")");
      if (currentUser) {
        request = request.clone({
          setHeaders: { Authorization: currentUser },
        });
      }
    }

    return next.handle(request);
  }
}
