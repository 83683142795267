import { Injectable } from '@angular/core';
//import { CanActivate } from '@angular/router';
//import { Router } from '@angular/router';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// import {LogoutURL} from '../../app.routing';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
    //URL = environment.SERVERURL+'logout';
    constructor(private router: Router, private http: HttpClient) {}
    
   /*  getLogin()
    {
        this.http.get(this.URL).subscribe(res => {
            console.log(res);
        }, err => {
            console.log(err);
        });
    } */

    canActivate() {


        console.log("Currently login status : "+localStorage.getItem('isLoggedin'));
        if (localStorage.getItem('isLoggedin')) {
            return true;
        }

        this.router.navigate(['/login']);
        return false;
    }
}
