import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditComponent } from './edit/edit.component';
import { CustomComponent } from './edit/custom/custom.component';
import { CareerComponent } from './edit/career/career.component';
import { InsuranceComponent } from './edit/insurance/insurance.component';
import { EmailComponent } from './edit/email/email.component';
import { CareerpersonalComponent } from './personal/careerpersonal/careerpersonal.component';
import { InsurancepersonalComponent } from './personal/insurancepersonal/insurancepersonal.component';
import { DemoMaterialModule } from '../../demo-material-module';
import { SharedModule } from '../shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MortgageComponent } from './edit/mortgage/mortgage.component';
import { MortgagepersonalComponent } from './personal/mortgagepersonal/mortgagepersonal.component';
import { PhoneComponent } from './edit/phone/phone.component';
import { PersonalComponent } from './personal/personal.component';
import { ScriptComponent } from './script/script.component';
import { CommentComponent } from './comment/comment.component';
import { DialerComponent } from './dialer.component';
import { DialerService } from './dialer.service';
import { DragulaModule } from 'ng2-dragula';
import { QuillModule } from 'ngx-quill';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RightSidebarComponent } from '../right-sidebar/rightsidebar.component';
import { LeadlistComponent } from '../leadlist/leadlist.component';
import { LeadComponent } from '../lead/lead.component';
import { ScriptService } from '../../mortgage/mortgage.service';
import { ContactSearchPipe } from '../leadlist/keypipe/searchpipe';
import { ConferenceComponent } from './conference/conference.component';
import { SafedepositComponent } from './personal/safedeposit/safedeposit.component';
import { FeduciariesComponent } from './personal/Fiduciaries/feduciaries.component';
import { CurrentincomeComponent } from './currentincome/currentincom.ecomponent';
import { RealestateComponent } from './realestate/realestate.component';
import { BankaccountsComponent } from './bankaccounts/bankaccounts.component';
import { FloatingComponent } from './personal/floating/flaoting.component';
import { Message_DialogComponent } from './edit/messagemodel/message_dialog.component';
import { tabComponent } from './personal/tab/Tab.component';


@NgModule({
  imports: [
    CommonModule,
    DemoMaterialModule,
    FormsModule,
    CommonModule,
    DemoMaterialModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    DragulaModule,
    QuillModule,
    NgbModule
  ],
  declarations: [
    EditComponent,
    DialerComponent, 
    CustomComponent, 
    CareerComponent, 
    InsuranceComponent, 
    EmailComponent, 
    CareerpersonalComponent, 
    InsurancepersonalComponent,  
    MortgageComponent, 
    PhoneComponent,
    MortgagepersonalComponent , 
    PersonalComponent,
    ScriptComponent ,
    CommentComponent,
    RightSidebarComponent,
    LeadComponent,
    LeadlistComponent,
    ContactSearchPipe,
    ConferenceComponent,
    SafedepositComponent,
    FeduciariesComponent,
    CurrentincomeComponent,
    RealestateComponent,
    BankaccountsComponent,
    FloatingComponent,
    tabComponent,
    Message_DialogComponent,
  ],
  exports: [
    DialerComponent,
    RightSidebarComponent,
    ContactSearchPipe,
    LeadComponent,
    LeadlistComponent
  ],
  providers: [DialerService, ScriptService],
  entryComponents:[Message_DialogComponent]
})
export class DialerModule { }
