import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { HttpClient, HttpResponse, HttpRequest, 
         HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs/Subscription';
import { of } from 'rxjs/observable/of';
import { catchError, last, map, tap } from 'rxjs/operators';
//import { SpinnerComponent } from '../../spinner.component';


@Component({
      selector: 'app-uploadview',
      templateUrl: './uploadview.component.html',
      styleUrls: ['./uploadview.component.css'],
      animations: [
            trigger('fadeInOut', [
                  state('in', style({ opacity: 100 })),
                  transition('* => void', [
                        animate(300, style({ opacity: 0 }))
                  ])
            ])
      ]
})
export class UploadviewComponent implements OnInit {

      /** Link text */
      @Input() text = 'Upload';
      /** Name used in form which will be sent in HTTP request. */
      @Input() value = '';
      @Input() field = '';
      /** Target URL for file uploading. */
      @Input() target = 'http://192.168.1.25:3010/fileupload';
      /** File extension that accepted, same as 'accept' of <input type="file" />. 
          By the default, it's set to 'image/*'. */
      @Input() accept = 'apllication/pdf';
      /** Allow you to add handler after its completion. Bubble up response text from remote. */
      @Output() complete = new EventEmitter<string>();

      public files: Array<FileUploadModel> = [];
      public state=false;

      constructor(private _http: HttpClient) { }

      ngOnInit() {
      }

      onClick() {
            const fileUpload = document.getElementById('filename') as HTMLInputElement;
            fileUpload.onchange = () => {
                  for (let index = 0; index < fileUpload.files.length; index++) {
                        console.log(fileUpload.files);
                        const file = fileUpload.files[index];
                        this.files.push({ data: file, state: 'in', 
                          inProgress: false, progress: 0, canRetry: false, canCancel: true });
                  }
                  this.uploadFiles();
            };
            fileUpload.click();
      }

      cancelFile(file: FileUploadModel) {
            file.sub.unsubscribe();
            this.removeFileFromArray(file);
      }

      retryFile(file: FileUploadModel) {
            this.uploadFile(file);
            file.canRetry = false;
      }

      private uploadFile(file: FileUploadModel) {
            this.state=true;
            const fd = new FormData();
            fd.append("filename", file.data);
            if(this.field!="")
                  fd.append(this.field, this.value);
            fd.append("id_user", localStorage.getItem('userid'));

            const req = new HttpRequest('POST', this.target, fd, {
                  reportProgress: true
            });

            file.inProgress = true;
            file.sub = this._http.request(req).pipe(
                  map(event => {
                        switch (event.type) {
                              case HttpEventType.UploadProgress:
                                    file.progress = Math.round(event.loaded * 100 / event.total);
                                    break;
                              case HttpEventType.Response:
                              {
                                    console.log("Upload COmpleted in event");
                                    return event;
                              }
                        }
                  }),
                  tap(message => { }),
                  last(),
                  catchError((error: HttpErrorResponse) => {
                        file.inProgress = false;
                        file.canRetry = true;
                        return of(`${file.data.name} upload failed.`);
                  })
            ).subscribe(
                  (event: any) => {
                        console.log("Upload COmpleted on subscriber : "+JSON.stringify(event));
                        this.complete.emit(file.data.name);
                        this.state=false;
                        /* if (typeof (event) === 'object') {
                              console.log("Upload COmpleted");
                              this.removeFileFromArray(file);
                              this.complete.emit(event.body);
                        } */
                  }
            );
      }

      private uploadFiles() {
            const fileUpload = document.getElementById('filename') as HTMLInputElement;
            fileUpload.value = '';
            console.log(this.files);
            this.files.forEach(file => {
                  var event=this.uploadFile(file);
            });
      }

      private removeFileFromArray(file: FileUploadModel) {
            const index = this.files.indexOf(file);
            if (index > -1) {
                  this.files.splice(index, 1);
            }
      }

}

export class FileUploadModel {
      data: File;
      state: string;
      inProgress: boolean;
      progress: number;
      canRetry: boolean;
      canCancel: boolean;
      sub?: Subscription;
}