import { FormBuilder, FormGroup } from "@angular/forms";
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
} from "angular-calendar";

export interface Dialer {
  id: number;
  lead_level: string;
  salse_rep_name: string;
  created_date: string;
  disposition: string;
  queued: number;
  didpick: number;
  dispoattempts: number;
  callback_time: number;
  dispodate: string;
  edit: Edit;
  personalinfo: PersonalInfo;
  current_script: Scripts;
  comments: Comments;
  dispodata: DispoData;
  apptcalendardata: ApptCalendar;
  objection_id: string;
}

export interface Soktdialer {
  name: string;
}

export interface HTTPDialer {
  id: number;
  lead_level: string;
  salse_rep_name: string;
  created_date: string;
  disposition: string;
  queued: number;
  didpick: number;
  dispoattempts: number;
  callback_time: number;
  dispodate: string;
  edit: Edit;
  personalinfo: PersonalInfo;
  current_script: Scripts;
  comments: Comments;
  dispodata: DispoData;
  apptcalendardata: ApptCalendar;
  objection_id: string;
}

export class DialerForm implements HTTPDialer {
  public id: number;
  public lead_level: string;
  public salse_rep_name: string;
  public created_date: string;
  public disposition: string;
  public queued: number;
  public didpick: number;
  public dispoattempts: number;
  public callback_time: number;
  public dispodate: string;
  public edit: Edit;
  public personalinfo: PersonalInfo;
  public current_script: Scripts;
  public comments: Comments;
  public dispodata: DispoData;
  public apptcalendardata: ApptCalendar;
  public objection_id: string;

  constructor(data: Dialer) {
    if (data == null) {
      this.id = -1;
      this.lead_level = "testing level";
      this.salse_rep_name = "test sales ";
      this.created_date = "testing created date";
      this.disposition = "";
      this.queued = -1;
      this.didpick = -1;
      this.dispoattempts = -1;
      this.callback_time = -1;
      this.dispodate = "";
      this.edit = new EditForm(null);
      this.personalinfo = new PersonalInfoForm(null);
      this.current_script = null;
      this.comments = new CommentsForm(null);
      this.dispodata = new FormDispoData(null);
      this.apptcalendardata = new ApptCalendarForm(null);
      this.objection_id = "-1";
    } else {
      this.id = data.id;
      this.lead_level = data.lead_level;
      this.salse_rep_name = data.salse_rep_name;
      this.created_date = data.created_date;
      this.disposition = data.disposition;
      this.queued = data.queued;
      this.didpick = data.didpick;
      this.dispoattempts = data.dispoattempts;
      this.callback_time = data.callback_time;
      this.dispodate = data.dispodate;
      this.edit = new EditForm(data.edit);
      this.personalinfo = new PersonalInfoForm(data.personalinfo);
      this.current_script = data.current_script;
      this.comments = new CommentsForm(data.comments);
      this.dispodata = new FormDispoData(data.dispodata);
      this.apptcalendardata = new ApptCalendarForm(data.apptcalendardata);
      this.objection_id = data.objection_id;
    }
  }

  /*  set script(val:Scripts)
    {
        val.script = val.script.replace(/{AGENT_FIRSTNAME}/g, localStorage.getItem("firstname"));
        if(this.lead_level)
            val.script = val.script.replace(/{LEAD_LEVEL}/g, this.lead_level);
        if(this.created_date)
            val.script = val.script.replace(/{CREATED_DATE}/g, this.created_date);
        if(this.disposition)
            val.script = val.script.replace(/{DISPOSITION}/g, this.disposition);
        if(this.edit.first_name)
            val.script = val.script.replace(/{FIRST_NAME}/g, this.edit.first_name);
        if(this.edit.last_name)
            val.script = val.script.replace(/{LAST_NAME}/g, this.edit.last_name);
        if(this.edit.spouse_full_name)
            val.script = val.script.replace(/{SPOUSE_FULL_NAME}/g, this.edit.spouse_full_name);
        if(this.edit.street_address_1)
            val.script = val.script.replace(/{STREET_ADDRESS_1}/g, this.edit.street_address_1);
        if(this.edit.city)
            val.script = val.script.replace(/{CITY}/g, this.edit.city);
        if(this.edit.state)
            val.script = val.script.replace(/{STATE}/g, this.edit.state);
        if(this.edit.zip)
            val.script = val.script.replace(/{ZIP}/g, this.edit.zip);
        if(this.edit.county)
            val.script = val.script.replace(/{COUNTY}/g, this.edit.county);
        if(this.edit.email)
            val.script = val.script.replace(/{EMAIL}/g, this.edit.email);
        if(this.edit.cell_phone)
            val.script = val.script.replace(/{CELL_PHONE}/g, this.edit.cell_phone);
        if(this.edit.phone)
            val.script = val.script.replace(/{PHONE}/g, this.edit.phone);
        if(this.edit.work_phone)
            val.script = val.script.replace(/{WORK_PHONE}/g, this.edit.work_phone);
        if(this.personalinfo.birth_date)
            val.script = val.script.replace(/{BIRTH_DATE}/g, this.personalinfo.birth_date);
        if(this.personalinfo.spouse_birth_date)
            val.script = val.script.replace(/{SPOUSE_BIRTH_DATE}/g, this.personalinfo.spouse_birth_date);
        if(this.personalinfo.age)
            val.script = val.script.replace(/{AGE}/g, this.personalinfo.age);
        if(this.personalinfo.spouse_age)
            val.script = val.script.replace(/{SPOUSE_AGE}/g, this.personalinfo.spouse_age);
        if(this.personalinfo.occupation)
            val.script = val.script.replace(/{OCCUPATION}/g, this.personalinfo.occupation);
        if(this.personalinfo.spouse_occupation)
            val.script = val.script.replace(/{SPOUSE_OCCUPATION}/g, this.personalinfo.spouse_occupation);
        if(this.personalinfo.height)
            val.script = val.script.replace(/{HEIGHT}/g, this.personalinfo.height);
        if(this.personalinfo.spouse_height)
            val.script = val.script.replace(/{SPOUSE_HEIGHT}/g, this.personalinfo.spouse_height);
        if(this.personalinfo.weight)
            val.script = val.script.replace(/{WEIGHT}/g, this.personalinfo.weight);
        if(this.personalinfo.spouse_weight)
            val.script = val.script.replace(/{SPOUSE_WEIGHT}/g, this.personalinfo.spouse_weight);
        if(this.personalinfo.smoker)
            val.script = val.script.replace(/{SMOKER}/g, this.personalinfo.smoker);
        if(this.personalinfo.spouse_smoker)
            val.script = val.script.replace(/{SPOUSE_SMOKER}/g, this.personalinfo.spouse_smoker);
        if(this.personalinfo.health_issue)
            val.script = val.script.replace(/{HEALTH_ISSUE}/g, this.personalinfo.health_issue);
        if(this.personalinfo.spouse_health_issue)
            val.script = val.script.replace(/{SPOUSE_HEALTH_ISSUE}/g, this.personalinfo.spouse_health_issue);
        if(this.personalinfo.lender)
            val.script = val.script.replace(/{LENDER}/g, this.personalinfo.lender);
        if(this.personalinfo.beneficiary)
            val.script = val.script.replace(/{BENEFICIARY}/g, this.personalinfo.beneficiary);
        if(this.personalinfo.mortgage_amount)
            val.script = val.script.replace(/{MORTGAGE_AMOUNT}/g, this.personalinfo.mortgage_amount);
        if(this.personalinfo.lead_level)
            val.script = val.script.replace(/{LEAD_LEVEL}/g, this.personalinfo.lead_level);
        if(this.personalinfo.purchase_refi)
            val.script = val.script.replace(/{PURCHASE_REFI}/g, this.personalinfo.purchase_refi);
        if(this.personalinfo.assign_date)
            val.script = val.script.replace(/{ASSIGN_DATE}/g, this.personalinfo.assign_date);
        if(this.personalinfo.home_value)
            val.script = val.script.replace(/{HOME_VALUE}/g, this.personalinfo.home_value);
        if(this.personalinfo.status)
            val.script = val.script.replace(/{STATUS}/g, this.personalinfo.status);
        if(this.personalinfo.mortgage_term)
            val.script = val.script.replace(/{MORTGAGE_TERM}/g, this.personalinfo.mortgage_term);
        if(this.personalinfo.referrer)
            val.script = val.script.replace(/{REFERRER}/g, this.personalinfo.referrer);
        if(this.personalinfo.monthly_mortage_payment)
            val.script = val.script.replace(/{MONTHLY_MORTAGE_PAYMENT}/g, this.personalinfo.monthly_mortage_payment);
        
        //console.log(val);
        this.current_script=val;
    } */
}

// staring for Edit  module
export interface Edit {
  fullname: string;
  first_name: string;
  last_name: string;
  spouse_full_name: string;
  street_address_1: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  email: string;
  cell_phone: string;
  phone: string;
  work_phone: string;
  c_i_salery_a:string;
  c_i_investment_i:string;
  c_i_other:string;
  r_s_name_owner:string;
  r_s_location:string;
  r_s_mortfgage_balence:string;
  r_s_maket_value:string;
  safe_d_location:string;
safe_d_bank:string;
safe_d_access:string;
safe_d_content:string;
feduciaries_executor:string;
feduciaries_address:string;
feduciaries_alternet_executor:string;
c_b_name_account:string;
c_b_bank:string;
c_b_type_account:string;
c_b_average_balance:string;
comments_:string;

  //emailtemplate : EmailTemplate,
  //smstemplate : SmsTemplate
}

export interface HTTPEdit {
  first_name: string;
  last_name: string;
  fullname: string;
  spouse_full_name: string;
  street_address_1: string;
  city: string;
  state: string;
  zip: string;
  county: string;
  email: string;
  cell_phone: string;
  phone: string;
  work_phone: string;
  c_i_salery_a:string;
  c_i_investment_i:string;
  c_i_other:string;
  //emailtemplate : EmailTemplate,
  //smstemplate : SmsTemplate
}

export class EditForm implements HTTPEdit {
  public first_name: string;
  public last_name: string;
  public fullname: string;
  public spouse_full_name: string;
  public street_address_1: string;
  public city: string;
  public state: string;
  public zip: string;
  public county: string;
  public email: string;
  public cell_phone: string;
  public phone: string;
  public work_phone: string;
  public c_i_salery_a:string;
  public c_i_investment_i:string;
  public c_i_other:string;
  public r_s_name_owner:string;
  public r_s_location:string;
  public r_s_mortfgage_balence:string;
  public r_s_maket_value:string;
  public safe_d_location:string;
  public safe_d_bank:string;
  public safe_d_access:string;
  public safe_d_content:string;
  public feduciaries_executor:string;
  public feduciaries_address:string;
  public feduciaries_alternet_executor:string;
  public c_b_name_account:string;
  public c_b_bank:string;
  public c_b_type_account:string;
  public c_b_average_balance:string;
  public comments_:string;
  //public emailtemplate : EmailTemplate;
  //public smstemplate : SmsTemplate;

  constructor(data: Edit) {
    if (data == null) {
      this.first_name = "";
      this.last_name = "";
      this.fullname = this.first_name + " " + this.last_name;
      this.spouse_full_name = "";
      this.street_address_1 = "";
      this.city = "";
      (this.state = ""), (this.zip = "");
      this.county = "";
      this.email = "";
      this.cell_phone = "";
      this.phone = "";
      this.work_phone = "";
      this.c_i_salery_a="";
      this.c_i_investment_i="";
      this.c_i_other="";
      this.r_s_name_owner;
      this.r_s_location;
      this.r_s_mortfgage_balence;
      this.r_s_maket_value;
      this.safe_d_bank;
      this.safe_d_location;
      this.safe_d_access;
      this.safe_d_content;
      this.feduciaries_executor;
      
      this.feduciaries_address;
      this.feduciaries_alternet_executor;
      this.c_b_name_account;
      this.c_b_bank;
      this.c_b_type_account;
      this.c_b_average_balance;
      this.comments_;


      //this.emailtemplate=new FormEmailTemplate(null);
      //this.smstemplate=new FormSmsTemplate(null);
    } else {
      this.first_name = data.first_name;
      this.last_name = data.last_name;
      this.fullname =
        this.first_name.toUpperCase() + " " + this.last_name.toUpperCase();
      this.spouse_full_name = data.spouse_full_name;
      this.street_address_1 = data.street_address_1;
      this.city = data.city;
      this.state = data.state;
      this.zip = data.zip;
      this.county = data.county;
      this.email = data.email;
      this.c_i_salery_a = data.c_i_salery_a;
      this.c_i_investment_i = data.c_i_investment_i;
      this.c_i_other = data.c_i_other;
      this.r_s_name_owner = data.r_s_name_owner;
      this.r_s_location = data.r_s_location;
      this.r_s_mortfgage_balence = data.r_s_mortfgage_balence;
      this.r_s_maket_value = data.r_s_maket_value;
      this.safe_d_bank = data.safe_d_bank;
      this.safe_d_access = data.safe_d_access;
      this.safe_d_location = data.safe_d_location;
      this.safe_d_content = data.safe_d_content;
      this.feduciaries_executor = data.feduciaries_executor;
      this.feduciaries_address =data.feduciaries_address;
      this.feduciaries_alternet_executor= data.feduciaries_alternet_executor;
      this.c_b_name_account = data.c_b_name_account;
      this.c_b_bank= data.c_b_bank;
      this.c_b_type_account = data.c_b_type_account;
      this.c_b_average_balance = data.c_b_average_balance;
      this.comments_ = data.comments_;
      var numberPattern = /\d+/g;

      if (data.cell_phone) {
        let cellnum = data.cell_phone;
        if (cellnum) {
          let cellno = cellnum.toString().match(numberPattern);

          if (cellno) {
            cellnum = "";
            cellno.forEach((val) => {
              cellnum += val;
            });
            if (cellnum.length == 10) cellnum = "1" + cellnum;
          }
        }
        this.cell_phone = cellnum;
      } else this.cell_phone = "";

      if (data.phone) {
        let homenum = data.phone;
        if (homenum) {
          let homeno = homenum.toString().match(numberPattern);

          if (homeno) {
            homenum = "";
            homeno.forEach((val) => {
              homenum += val;
            });
            if (homenum.length == 10) homenum = "1" + homenum;
          }
        }
        this.phone = homenum;
      } else this.phone = "";

      if (data.work_phone) {
        let worknum = data.work_phone;
        if (worknum) {
          let workno = worknum.toString().match(numberPattern);

          if (workno) {
            worknum = "";
            workno.forEach((val) => {
              worknum += val;
            });
            if (worknum.length == 10) worknum = "1" + worknum;
          }
        }
        this.work_phone = worknum;
      } else this.work_phone = "";
      //this.emailtemplate=new FormEmailTemplate(data.emailtemplate);
      //this.smstemplate=new FormSmsTemplate(data.smstemplate);
    }
  }

  public makeform(fb: FormBuilder): FormGroup {
    return fb.group({
      first_name: [this.first_name],
      last_name: [this.last_name],
      fullname: [this.fullname],
      spouse_full_name: [this.spouse_full_name],
      street_address_1: [this.street_address_1],
      city: [this.city],
      state: [this.state],
      zip: [this.zip],
      county: [this.county],
      email: [this.email],
      cell_phone: [this.cell_phone],
      phone: [this.phone],
      work_phone: [this.work_phone],
      c_i_salery_a : [this.c_i_salery_a],
      c_i_investment_i:[this.c_i_investment_i],
      c_i_other:[this.c_i_other],
      //emailtemplate: [this.emailtemplate]
    });
  }

  public saveForm(fg: FormGroup): boolean {
    if (fg.valid) {
      this.first_name = fg.get("first_name").value;
      this.last_name = fg.get("last_name").value;
      this.fullname = fg.get("fullname").value;
      this.spouse_full_name = fg.get("spouse_full_name").value;
      this.street_address_1 = fg.get("street_address_1").value;
      this.city = fg.get("city").value;
      this.state = fg.get("state").value;
      this.zip = fg.get("zip").value;
      this.county = fg.get("county").value;
      this.email = fg.get("email").value;
      this.cell_phone = fg.get("cell_phone").value;
      this.phone = fg.get("phone").value;
      this.c_i_salery_a = fg.get("c_i_salery_a").value;
      this.c_i_investment_i = fg.get("c_i_investment_i").value;
      this.c_i_other = fg.get("c_i_other").value;
      //this.emailtemplate=fg.get("emailtemplate").value;
      return true;
    } else {
      return false;
    }
  }
}

//End Edit form

// Starting  PersonalInfo Module

export interface PersonalInfo {
  birth_date: string;
  spouse_birth_date: string;
  age: string;
  spouse_age: string;
  occupation: string;
  spouse_occupation: string;
  height: string;
  spouse_height: string;
  weight: string;
  spouse_weight: string;
  smoker: string;
  spouse_smoker: string;
  health_issue: string;
  spouse_health_issue: string;
  lender: string;
  beneficiary: string;
  mortgage_amount: string;
  lead_level: string;
  purchase_refi: string;
  assign_date: string;
  home_value: string;
  status: string;
  mortgage_term: string;
  referrer: string;
  monthly_mortage_payment: string;
  reason: string;
}

export interface HTTPPersonalInfo {
  birth_date: string;
  spouse_birth_date: string;
  age: string;
  spouse_age: string;
  occupation: string;
  spouse_occupation: string;
  height: string;
  spouse_height: string;
  weight: string;
  spouse_weight: string;
  smoker: string;
  spouse_smoker: string;
  health_issue: string;
  spouse_health_issue: string;
  lender: string;
  beneficiary: string;
  mortgage_amount: string;
  lead_level: string;
  purchase_refi: string;
  assign_date: string;
  home_value: string;
  status: string;
  mortgage_term: string;
  referrer: string;
  monthly_mortage_payment: string;
  reason: string;
}

export class PersonalInfoForm implements HTTPPersonalInfo {
  public birth_date: string;
  public age: string;
  public occupation: string;
  public spouse_occupation: string;
  public spouse_birth_date: string;
  public spouse_age: string;
  public lender: string;
  public mortgage_amount: string;
  public purchase_refi: string;
  public home_value: string;
  public mortgage_term: string;
  public monthly_mortage_payment: string;
  public beneficiary: string;
  public smoker: string;
  public height: string;
  public weight: string;
  public health_issue: string;
  public spouse_smoker: string;
  public spouse_height: string;
  public spouse_weight: string;
  public spouse_health_issue: string;
  public lead_level: string;
  public assign_date: string;
  public status: string;
  public referrer: string;
  public reason: string;

  constructor(data: PersonalInfo) {
    if (data == null) {
      this.birth_date = "";
      this.age = "";
      this.occupation = "";
      (this.spouse_occupation = ""), (this.spouse_birth_date = "");
      this.spouse_age = "";
      this.lender = "";
      this.mortgage_amount = "";
      this.purchase_refi = "";
      this.home_value = "";
      this.mortgage_term = "";
      this.monthly_mortage_payment = "";
      this.beneficiary = "";
      this.smoker = "";
      this.height = "";
      this.weight = "";
      this.health_issue = "";
      this.spouse_smoker = "";
      this.spouse_height = "";
      this.spouse_weight = "";
      this.spouse_health_issue = "";
      this.lead_level = "";
      this.assign_date = "";
      this.status = "";
      this.referrer = "";
      this.reason = "";
    } else {
      this.birth_date = data.birth_date;
      this.age = data.age;
      this.occupation = data.occupation;
      this.spouse_occupation = data.spouse_occupation;
      this.spouse_birth_date = data.spouse_birth_date;
      this.spouse_age = data.spouse_age;

      this.lender = data.lender;
      if (data.lender) this.lender = data.lender.replace(/\&amp;/g, "&");

      this.mortgage_amount = data.mortgage_amount;
      this.purchase_refi = data.purchase_refi;
      this.home_value = data.home_value;
      this.mortgage_term = data.mortgage_term;
      this.monthly_mortage_payment = data.monthly_mortage_payment;
      this.beneficiary = data.beneficiary;
      this.smoker = data.smoker;

      this.height = data.height;
      if (data.height) this.height = data.height.replace(/\&quot;/g, '"');

      this.weight = data.weight;
      this.health_issue = data.health_issue;
      if (data.health_issue)
        this.health_issue = data.health_issue.replace(/\&amp;/g, "&");
      this.spouse_smoker = data.spouse_smoker;

      this.spouse_height = data.spouse_height;
      if (data.spouse_height)
        this.spouse_height = data.spouse_height.replace(/\&quot;/g, '"');

      this.spouse_weight = data.spouse_weight;

      this.spouse_health_issue = data.spouse_health_issue;
      if (data.spouse_health_issue)
        this.spouse_health_issue = data.spouse_health_issue.replace(
          /\&amp;/g,
          "&"
        );

      this.lead_level = data.lead_level;
      this.assign_date = data.assign_date;
      this.status = data.status;
      this.referrer = data.referrer;
      this.reason = data.reason;
    }
  }

  public makeform(fb: FormBuilder): FormGroup {
    return fb.group({
      birth_date: [this.birth_date],
      age: [this.age],
      occupation: [this.occupation],
      spouse_occupation: [this.spouse_occupation],
      spouse_birth_date: [this.spouse_birth_date],
      spouse_age: [this.spouse_age],
      lender: [this.lender],
      mortgage_amount: [this.mortgage_amount],
      purchase_refi: [this.purchase_refi],
      home_value: [this.home_value],
      mortgage_term: [this.mortgage_term],
      monthly_mortage_payment: [this.monthly_mortage_payment],
      beneficiary: [this.beneficiary],
      smoker: [this.smoker],
      height: [this.height],
      weight: [this.weight],
      health_issue: [this.health_issue],
      spouse_smoker: [this.spouse_smoker],
      spouse_height: [this.spouse_height],
      spouse_weight: [this.spouse_weight],
      spouse_health_issue: [this.spouse_health_issue],
      lead_level: [this.lead_level],
      assign_date: [this.assign_date],
      status: [this.status],
      referrer: [this.referrer],
      reason: [this.reason],
    });
  }

  public saveForm(fg: FormGroup): boolean {
    if (fg.valid) {
      this.birth_date = fg.get("birth_date").value;
      this.age = fg.get("age").value;
      this.occupation = fg.get("occupation").value;
      this.spouse_occupation = fg.get("spouse_occupation").value;
      this.spouse_birth_date = fg.get("spouse_birth_date").value;
      this.spouse_age = fg.get("spouse_age").value;
      this.lender = fg.get("lender").value;
      this.mortgage_amount = fg.get("mortgage_amount").value;
      this.purchase_refi = fg.get("purchase_refi").value;
      this.home_value = fg.get("home_value").value;
      this.mortgage_term = fg.get("mortgage_term").value;
      this.monthly_mortage_payment = fg.get("monthly_mortage_payment").value;
      this.beneficiary = fg.get("beneficiary").value;
      this.smoker = fg.get("smoker").value;
      this.height = fg.get("height").value;
      this.weight = fg.get("weight").value;
      this.health_issue = fg.get("health_issue").value;
      this.spouse_smoker = fg.get("spouse_smoker").value;
      this.spouse_height = fg.get("spouse_height").value;
      this.spouse_weight = fg.get("spouse_weight").value;
      this.spouse_health_issue = fg.get("spouse_health_issue").value;
      this.lead_level = fg.get("lead_level").value;
      this.assign_date = fg.get("assign_date").value;
      this.status = fg.get("status").value;
      this.referrer = fg.get("referrer").value;
      this.reason = fg.get("reason").value;
      return true;
    } else {
      return false;
    }
  }
}

//End PersonalInfo form

// Starting Script Form

export interface Scripts {
  id: number;
  script_name: string;
  script: string;
}

export interface HTTPScripts {
  id: number;
  script_name: string;
  script: string;
}

export class FormScripts implements HTTPScripts {
  public id: number;
  public script_name: string;
  public script: string;

  constructor(data: Scripts) {
    console.log("Script current data");
    console.log(data);
    if (data == null) {
      this.id = -1;
      this.script_name = "";
      this.script = "";
    } else {
      this.id = data.id;
      this.script_name = data.script_name;
      this.script = data.script;
    }
  }
}

//End Script form

// Starting Comment Form

export interface Comments {
  comments: string;
}

export interface HTTPComments {
  comments: string;
}

export class CommentsForm implements HTTPComments {
  public comments: string;

  constructor(data: Comments) {
    if (data == null) {
      this.comments = "";
    } else {
      this.comments = data.comments;
    }
  }

  public makeform(fb: FormBuilder): FormGroup {
    return fb.group({
      comments: [this.comments],
    });
  }

  public saveForm(fg: FormGroup): boolean {
    if (fg.valid) {
      this.comments = fg.get("comments").value;

      return true;
    } else {
      return false;
    }
  }
}

//End Comment form

// Starting ContactQueue Form

export interface ContactQueue {
  id: number;
  queueurl: string;
  county: string;
  disposition: string;
}

export interface HTTPContactQueue {
  queueurl: string;
  county: string;
  disposition: string;
}

export class ContactQueue implements HTTPContactQueue {
  public queueurl: string;
  public county: string;
  public disposition: string;

  constructor(data: ContactQueue) {
    if (data == null) {
      this.queueurl = "";
      this.county = "";
      this.disposition = "";
    } else {
      this.queueurl = data.queueurl;
      this.county = data.county;
      this.disposition = data.disposition;
    }
  }

  public makeform(fb: FormBuilder): FormGroup {
    return fb.group({
      queueurl: [this.queueurl],
      county: [this.county],
      disposition: [this.disposition],
    });
  }

  public saveForm(fg: FormGroup): boolean {
    if (fg.valid) {
      this.queueurl = fg.get("queueurl").value;
      this.county = fg.get("county").value;
      this.disposition = fg.get("disposition").value;
      return true;
    } else {
      return false;
    }
  }
}

//End ContactQueue form

// Starting Disposition Data Form

export interface DispoData {
  id: number;
  id_lead: number;
  id_script: number;
  title: string;
  disposition: string;
  comments: string;
  date: string;
  from_or_contact_time: string;
  to_or_agent_time: string;
  from_or_contact_timezone: string;
  to_or_agent_timezone: string;
  place: string;
  client_notify: number;
  iscalendar: number;
  start_time: string;
  end_time: string;
}

export interface HTTPDispoData {
  id: number;
  id_lead: number;
  id_script: number;
  disposition: string;
  comments: string;
  date: string;
  from_or_contact_time: string;
  to_or_agent_time: string;
  from_or_contact_timezone: string;
  to_or_agent_timezone: string;
  place: string;
  client_notify: number;
  iscalendar: number;
  start_time: string;
  end_time: string;
}

export class FormDispoData implements HTTPDispoData {
  public id: number;
  public id_lead: number;
  public id_script: number;
  public title: string;
  public purpose: string;
  public disposition: string;
  public comments: string;
  public date: string;
  public from_or_contact_time: string;
  public to_or_agent_time: string;
  public from_or_contact_timezone: string;
  public to_or_agent_timezone: string;
  public place: string;
  public client_notify: number;
  public iscalendar: number;
  public start_time: string;
  public end_time: string;

  constructor(data: DispoData) {
    if (data == null) {
      this.id = -1;
      this.id_lead = -1;
      this.id_script = -1;
      this.title = "";
      this.purpose = "";
      this.disposition = "";
      this.comments = "";
      this.date = "";
      this.from_or_contact_time = "";
      this.to_or_agent_time = "";
      this.from_or_contact_timezone = "";
      this.to_or_agent_timezone = "";
      this.place = "";
      this.client_notify = 0;
      this.iscalendar = 0;
      this.start_time = "";
      this.end_time = "";
    } else {
      this.id = data.id;
      this.id_lead = data.id_lead;
      this.id_script = data.id_script;
      this.disposition = data.disposition;
      this.comments = data.comments;
      this.date = data.date;
      this.from_or_contact_time = data.from_or_contact_time;
      this.to_or_agent_time = data.to_or_agent_time;
      this.from_or_contact_timezone = data.from_or_contact_timezone;
      this.to_or_agent_timezone = data.to_or_agent_timezone;
      this.place = data.place;
      this.client_notify = data.client_notify;
      this.iscalendar = data.iscalendar;
      this.start_time = data.start_time;
      this.end_time = data.end_time;
    }
  }

  public makeform(fb: FormBuilder): FormGroup {
    return fb.group({
      id: [this.id],
      id_lead: [this.id_lead],
      id_script: [this.id_script],
      title: [this.title],
      purpose:[this.purpose],
      disposition: [this.disposition],
      comments: [this.comments],
      date: [this.date],
      from_or_contact_time: [this.from_or_contact_time],
      to_or_agent_time: [this.to_or_agent_time],
      from_or_contact_timezone: [this.from_or_contact_timezone],
      to_or_agent_timezone: [this.to_or_agent_timezone],
      place: [this.place],
      client_notify: [this.client_notify],
      iscalendar: [this.iscalendar],
      start_time: [this.start_time],
      end_time: [this.end_time],
    });
  }

  public saveForm(fg: FormGroup): boolean {
    if (fg.valid) {
      this.id = fg.get("id").value;
      this.id_lead = fg.get("id_lead").value;
      this.id_script = fg.get("id_script").value;
      this.disposition = fg.get("disposition").value;
      this.comments = fg.get("comments").value;
      this.date = fg.get("date").value;
      this.from_or_contact_time = fg.get("from_or_contact_time").value;
      this.to_or_agent_time = fg.get("to_or_agent_time").value;
      this.from_or_contact_timezone = fg.get("from_or_contact_timezone").value;
      this.to_or_agent_timezone = fg.get("to_or_agent_timezone").value;
      this.place = fg.get("place").value;
      this.client_notify = fg.get("client_notify").value;
      this.iscalendar = fg.get("iscalendar").value;
      this.start_time = fg.get("start_time").value;
      this.end_time = fg.get("end_time").value;
      return true;
    } else {
      return false;
    }
  }
}

//End Disposition Data form

export interface ApptCalendar {
  id: number;
  id_lead: number;
  id_script: number;
  disposition: string;
  comments: string;
  date: string;
  from_or_contact_time: string;
  to_or_agent_time: string;
  from_or_contact_timezone: string;
  to_or_agent_timezone: string;
  place: string;
  client_notify: number;
  calendar_id: string;
  event_id: number;
  title: string;
  start: Date;
  end: Date;
  email: string;
  alocation: string;
  description: string;
  summview: string;
  start_time: string;
  end_time: string;
  email_reminder: number;
  reminder: number;
  className: string;
}

export interface HTTPApptCalendar {
  id: number;
  id_lead: number;
  id_script: number;
  disposition: string;
  comments: string;
  date: string;
  from_or_contact_time: string;
  to_or_agent_time: string;
  from_or_contact_timezone: string;
  to_or_agent_timezone: string;
  place: string;
  client_notify: number;
  calendar_id: string;
  event_id: number;
  title: string;
  start: Date;
  end: Date;
  email: string;
  alocation: string;
  description: string;
  summview: string;
  start_time: string;
  end_time: string;
  email_reminder: number;
  reminder: number;
  className: string;
}

export class HTTPApptCalendar {
  public id: number;
  public id_lead: number;
  public id_script: number;
  public disposition: string;
  public comments: string;
  public date: string;
  public from_or_contact_time: string;
  public to_or_agent_time: string;
  public from_or_contact_timezone: string;
  public to_or_agent_timezone: string;
  public place: string;
  public client_notify: number;
  public calendar_id: string;
  public event_id: number;
  public title: string;
  public start: Date;
  public end: Date;
  public email: string;
  public alocation: string;
  public description: string;
  public summview: string;
  public start_time: string;
  public end_time: string;
  public email_reminder: number;
  public reminder: number;
  public className: string;

  constructor(data: ApptCalendar) {
    if (data == null) {
      this.id = -1;
      this.id_lead = -1;
      this.id_script = -1;
      this.disposition = "";
      this.comments = "";
      this.date = "";
      this.from_or_contact_time = "";
      this.to_or_agent_time = "";
      this.from_or_contact_timezone = "";
      this.to_or_agent_timezone = "";
      this.place = "";
      this.client_notify = 0;
      this.calendar_id = "";
      this.event_id = -1;
      this.title = "";
      this.start = new Date();
      this.end = new Date();
      this.email = "";
      this.alocation = "";
      this.description = "";
      this.summview = "";
      this.start_time = "";
      this.end_time = "";
      this.email_reminder = -1;
      this.reminder = -1;
      this.className = "";
    } else {
      this.id = data.id;
      this.id_lead = data.id_lead;
      this.id_script = data.id_script;
      this.disposition = data.disposition;
      this.comments = data.comments;
      this.date = data.date;
      this.from_or_contact_time = data.from_or_contact_time;
      this.to_or_agent_time = data.to_or_agent_time;
      this.from_or_contact_timezone = data.from_or_contact_timezone;
      this.to_or_agent_timezone = data.to_or_agent_timezone;
      this.place = data.place;
      this.client_notify = data.client_notify;
      this.event_id = data.event_id;
      this.calendar_id = data.calendar_id;
      this.title = data.title;
      this.start = new Date(data.start);
      this.end = new Date(data.end);
      this.email = data.email;
      this.alocation = data.alocation;
      this.description = data.description;
      this.summview = data.summview;
      this.start_time = data.start_time;
      this.end_time = data.end_time;
      this.email_reminder = data.email_reminder;
      this.reminder = data.reminder;
      this.className = data.className;
    }
  }

  public MakeEvent(
    data: ApptCalendar[],
    action: CalendarEventAction[],
    colors: any
  ): CalendarEvent[] {
    let ret: CalendarEvent[] = [];
    colors = {
      green: {
        primary: "#0ca12e",
        secondary: "#0ca12e",
      },
      yellow: {
        primary: "#D88E08",
        secondary: "#D88E08",
      },
      blue: {
        primary: "#3a87ad",
        secondary: "#3a87ad",
      },
    };
    for (let row of data) {
      let eventcolor: any = colors.blue;
      if (row.className == "green") eventcolor = colors.green;
      else if (row.className == "yellow") eventcolor = colors.yellow;
      else if (row.className == "blue") eventcolor = colors.blue;
      let tmp: CalendarEvent = {
        id: row.event_id,
        //title:row.title,
        title: "<b>" + row.title + "</b><br/>" + row.summview,
        start: new Date(row.start),
        end: new Date(row.end),
        //email:row.email,
        //location:row.alocation,
        //description:row.description,
        //content:row.summview,
        color: eventcolor,
        actions: action,
      };

      ret.push(tmp);
    }
    return ret;
  }

  public makeform(fb: FormBuilder): FormGroup {
    return fb.group({
      id: [this.id],
      id_lead: [this.id_lead],
      id_script: [this.id_script],
      disposition: [this.disposition],
      comments: [this.comments],
      date: [this.date],
      from_or_contact_time: [this.from_or_contact_time],
      to_or_agent_time: [this.to_or_agent_time],
      from_or_contact_timezone: [this.from_or_contact_timezone],
      to_or_agent_timezone: [this.to_or_agent_timezone],
      place: [this.place],
      client_notify: [this.client_notify],
      calendar_id: [this.calendar_id],
      title: [this.title],
      start_time: [this.start_time],
      end_time: [this.end_time],
      email: [this.email],
      email_reminder: [this.email_reminder],
      reminder: [this.reminder],
    });
  }

  public saveForm(fg: FormGroup): boolean {
    if (fg.valid) {
      this.id = fg.get("id").value;
      this.id_lead = fg.get("id_lead").value;
      this.id_script = fg.get("id_script").value;
      this.disposition = fg.get("disposition").value;
      this.comments = fg.get("comments").value;
      this.date = fg.get("date").value;
      this.from_or_contact_time = fg.get("from_or_contact_time").value;
      this.to_or_agent_time = fg.get("to_or_agent_time").value;
      this.from_or_contact_timezone = fg.get("from_or_contact_timezone").value;
      this.to_or_agent_timezone = fg.get("to_or_agent_timezone").value;
      this.place = fg.get("place").value;
      this.calendar_id = fg.get("calendar_id").value;
      this.title = fg.get("title").value;
      this.start_time = fg.get("start_time").value;
      this.end_time = fg.get("end_time").value;
      this.email = fg.get("email").value;
      this.email_reminder = fg.get("email_reminder").value;
      this.reminder = fg.get("reminder").value;
      return true;
    } else {
      return false;
    }
  }
}
// Starting VoiceDropTemplate  Form

export interface VoiceDropTemplate {
  id: number;
  vd_msg: string;
  name: string;
}

export interface HTTPVoiceDropTemplate {
  vd_msg: string;
  name: string;
}

export class FormVoiceDropTemplate implements HTTPVoiceDropTemplate {
  public vd_msg: string;
  public name: string;

  constructor(data: VoiceDropTemplate) {
    if (data == null) {
      this.vd_msg = "";
      this.name = "";
    } else {
      this.vd_msg = data.vd_msg;
      this.name = data.name;
    }
  }

  public makeform(fb: FormBuilder): FormGroup {
    return fb.group({
      vd_msg: [this.vd_msg],
      name: [this.name],
    });
  }

  public saveForm(fg: FormGroup): boolean {
    if (fg.valid) {
      this.vd_msg = fg.get("vd_msg").value;
      this.name = fg.get("name").value;
      return true;
    } else {
      return false;
    }
  }
}

//End VoiceDropTemplate form

// Starting EmailTemplate  Form

export interface EmailTemplate {
  id: number;
  name: string;
  template: string;
}

export interface HTTPEmailTemplate {
  id: number;
  name: string;
  template: string;
}

export class FormEmailTemplate implements HTTPEmailTemplate {
  public id: number;
  public name: string;
  public template: string;

  constructor(data: EmailTemplate) {
    if (data == null) {
      this.id = -1;
      this.name = "";
      this.template = "";
    } else {
      this.id = data.id;
      this.name = data.name;
      this.template = data.template;
    }
  }

  public makeform(fb: FormBuilder): FormGroup {
    return fb.group({
      id: [this.id],
      name: [this.name],
      template: [this.template],
    });
  }

  public saveForm(fg: FormGroup): boolean {
    if (fg.valid) {
      this.id = fg.get("id").value;
      this.name = fg.get("name").value;
      this.template = fg.get("template").value;
      return true;
    } else {
      return false;
    }
  }
}

//End EmailTemplate form

// Starting SmsTemplate  Form

export interface SmsTemplate {
  id: number;
  name: string;
  template: string;
}

export interface HTTPSmsTemplate {
  id: number;
  name: string;
  template: string;
}

export class FormSmsTemplate implements HTTPSmsTemplate {
  public id: number;
  public name: string;
  public template: string;

  constructor(data: SmsTemplate) {
    if (data == null) {
      this.id = -1;
      this.name = "";
      this.template = "";
    } else {
      this.id = data.id;
      this.name = data.name;
      this.template = data.template;
    }
  }

  public makeform(fb: FormBuilder): FormGroup {
    return fb.group({
      id: [this.id],
      name: [this.name],
      template: [this.template],
    });
  }

  public saveForm(fg: FormGroup): boolean {
    if (fg.valid) {
      this.id = fg.get("id").value;
      this.name = fg.get("name").value;
      this.template = fg.get("template").value;
      return true;
    } else {
      return false;
    }
  }
}

//End SmsTemplate form

//End Disposition Data form

export interface ApptCalendar {
  id: number;
  id_lead: number;
  id_script: number;
  disposition: string;
  comments: string;
  date: string;
  from_or_contact_time: string;
  to_or_agent_time: string;
  from_or_contact_timezone: string;
  to_or_agent_timezone: string;
  place: string;
  client_notify: number;
  calendar_id: string;
  event_id: number;
  title: string;
  start: Date;
  end: Date;
  email: string;
  alocation: string;
  description: string;
  summview: string;
  start_time: string;
  end_time: string;
  email_reminder: number;
  reminder: number;
  className: string;
}

export interface HTTPApptCalendar {
  id: number;
  id_lead: number;
  id_script: number;
  disposition: string;
  comments: string;
  date: string;
  from_or_contact_time: string;
  to_or_agent_time: string;
  from_or_contact_timezone: string;
  to_or_agent_timezone: string;
  place: string;
  client_notify: number;
  calendar_id: string;
  event_id: number;
  title: string;
  start: Date;
  end: Date;
  email: string;
  alocation: string;
  description: string;
  summview: string;
  start_time: string;
  end_time: string;
  email_reminder: number;
  reminder: number;
  className: string;
}

export class ApptCalendarForm implements HTTPApptCalendar {
  public id: number;
  public id_lead: number;
  public id_script: number;
  public disposition: string;
  public comments: string;
  public date: string;
  public from_or_contact_time: string;
  public to_or_agent_time: string;
  public from_or_contact_timezone: string;
  public to_or_agent_timezone: string;
  public place: string;
  public client_notify: number;
  public calendar_id: string;
  public event_id: number;
  public title: string;
  public start: Date;
  public end: Date;
  public email: string;
  public alocation: string;
  public description: string;
  public summview: string;
  public start_time: string;
  public end_time: string;
  public email_reminder: number;
  public reminder: number;
  public className: string;

  constructor(data: ApptCalendar) {
    if (data == null) {
      this.id = -1;
      this.id_lead = -1;
      this.id_script = -1;
      this.disposition = "";
      this.comments = "";
      this.date = "";
      this.from_or_contact_time = "";
      this.to_or_agent_time = "";
      this.from_or_contact_timezone = "";
      this.to_or_agent_timezone = "";
      this.place = "";
      this.client_notify = 0;
      this.calendar_id = "";
      this.event_id = -1;
      this.title = "";
      this.start = new Date();
      this.end = new Date();
      this.email = "";
      this.alocation = "";
      this.description = "";
      this.summview = "";
      this.start_time = "";
      this.end_time = "";
      this.email_reminder = -1;
      this.reminder = -1;
      this.className = "";
    } else {
      this.id = data.id;
      this.id_lead = data.id_lead;
      this.id_script = data.id_script;
      this.disposition = data.disposition;
      this.comments = data.comments;
      this.date = data.date;
      this.from_or_contact_time = data.from_or_contact_time;
      this.to_or_agent_time = data.to_or_agent_time;
      this.from_or_contact_timezone = data.from_or_contact_timezone;
      this.to_or_agent_timezone = data.to_or_agent_timezone;
      this.place = data.place;
      this.client_notify = data.client_notify;
      this.event_id = data.event_id;
      this.calendar_id = data.calendar_id;
      this.title = data.title;
      this.start = new Date(data.start);
      this.end = new Date(data.end);
      this.email = data.email;
      this.alocation = data.alocation;
      this.description = data.description;
      this.summview = data.summview;
      this.start_time = data.start_time;
      this.end_time = data.end_time;
      this.email_reminder = data.email_reminder;
      this.reminder = data.reminder;
      this.className = data.className;
    }
  }

  public MakeEvent(
    data: ApptCalendar[],
    action: CalendarEventAction[],
    colors: any
  ): CalendarEvent[] {
    let ret: CalendarEvent[] = [];
    colors = {
      green: {
        primary: "#0ca12e",
        secondary: "#0ca12e",
      },
      yellow: {
        primary: "#D88E08",
        secondary: "#D88E08",
      },
      blue: {
        primary: "#3a87ad",
        secondary: "#3a87ad",
      },
    };
    for (let row of data) {
      let eventcolor: any = colors.blue;
      if (row.className == "green") eventcolor = colors.green;
      else if (row.className == "yellow") eventcolor = colors.yellow;
      else if (row.className == "blue") eventcolor = colors.blue;
      let tmp: CalendarEvent = {
        id: row.event_id,
        //title:row.title,
        title: "<b>" + row.title + "</b><br/>" + row.summview,
        start: new Date(row.start),
        end: new Date(row.end),
        //email:row.email,
        //location:row.alocation,
        //description:row.description,
        //content:row.summview,
        color: eventcolor,
        actions: action,
      };

      ret.push(tmp);
    }
    return ret;
  }

  public makeform(fb: FormBuilder): FormGroup {
    return fb.group({
      id: [this.id],
      id_lead: [this.id_lead],
      id_script: [this.id_script],
      title: [this.title],
      disposition: [this.disposition],
      comments: [this.comments],
      date: [this.date],
      from_or_contact_time: [this.from_or_contact_time],
      to_or_agent_time: [this.to_or_agent_time],
      from_or_contact_timezone: [this.from_or_contact_timezone],
      to_or_agent_timezone: [this.to_or_agent_timezone],
      place: [this.place],
      client_notify: [this.client_notify],
      calendar_id: [this.calendar_id],
      start_time: [this.start_time],
      end_time: [this.end_time],
      email: [this.email],
      email_reminder: [this.email_reminder],
      reminder: [this.reminder],
    });
  }

  public saveForm(fg: FormGroup): boolean {
    if (fg.valid) {
      this.id = fg.get("id").value;
      this.id_lead = fg.get("id_lead").value;
      this.id_script = fg.get("id_script").value;
      this.disposition = fg.get("disposition").value;
      this.comments = fg.get("comments").value;
      this.date = fg.get("date").value;
      this.from_or_contact_time = fg.get("from_or_contact_time").value;
      this.to_or_agent_time = fg.get("to_or_agent_time").value;
      this.from_or_contact_timezone = fg.get("from_or_contact_timezone").value;
      this.to_or_agent_timezone = fg.get("to_or_agent_timezone").value;
      this.place = fg.get("place").value;
      this.calendar_id = fg.get("calendar_id").value;
      this.title = fg.get("title").value;
      this.start_time = fg.get("start_time").value;
      this.end_time = fg.get("end_time").value;
      this.email = fg.get("email").value;
      this.email_reminder = fg.get("email_reminder").value;
      this.reminder = fg.get("reminder").value;
      return true;
    } else {
      return false;
    }
  }
}

// Starting VoiceDropTemplate  Form
