import { Injectable } from '@angular/core';
import { AppService } from '../service/AppService.class';
import { HttpClient } from '@angular/common/http';
import { Option } from '../model/model.class';
import { Observable } from 'rxjs';
import { APIURL } from '../../app-routing.module';
import { Dialer } from './model/dialer.class';

@Injectable()
export class DialerService extends AppService<any>{
  //constructor() { }
  constructor(protected http:HttpClient) {
    super(http);
    this.appmod="";
   }  

}


