import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'ContactSearch'})
export class ContactSearchPipe implements PipeTransform
{
    transform(items:any[], search:string): any {
        //console.log(items);
        if (items && items.length){
            return items.filter(item =>{
                var search_number = search.match(/\d+/g);
                var search_string = search.match(/[a-zA-Z]+/g);
                if(search_number && !search_string)
                {   var searchno = '';
                    search_number.forEach(val=>{
                        searchno += val;
                    })

                    var cellnum = item.edit.cell_phone;
                    if(cellnum)
                    {
                        var numberPattern = /\d+/g;
                        let cellno = (cellnum).match(numberPattern);
                        cellnum = '';
                        cellno.forEach(val=>{
                            cellnum += val;
                        })
                        if(cellnum.length==10)
                            cellnum = '1'+cellnum;
                    }
                    
                    var homenum = item.edit.phone;
                    if(homenum)
                    {
                        var numberPattern = /\d+/g;
                        let homeno = (homenum).match(numberPattern);
                        homenum = '';
                        homeno.forEach(val=>{
                            homenum += val;
                        })
                        if(homenum.length==10)
                            homenum = '1'+homenum;
                    }

                    var worknum = item.edit.work_phone;
                    if(worknum)
                    {
                        var numberPattern = /\d+/g;
                        let workno = (worknum).match(numberPattern);
                        worknum = '';
                        workno.forEach(val=>{
                            worknum += val;
                        })
                        if(worknum.length==10)
                            worknum = '1'+worknum;
                    }

                    var zip_code=item.edit.zip;
                    if(zip_code)
                    {
                        var czip = zip_code.match(/\d+/g);
                        if(czip)
                        {
                            zip_code = '';
                            czip.forEach(val=>{
                                zip_code += val;
                            })
                        }
                    }

                    var assign_date='';
                    if(item.personalinfo.assign_date)
                        assign_date = item.personalinfo.assign_date;
                   
                    if((cellnum && cellnum.indexOf(searchno) !=-1) ||
                    (homenum && homenum.indexOf(searchno) !=-1) ||
                    (worknum && worknum.indexOf(searchno) !=-1) || (zip_code && zip_code.indexOf(searchno) !=-1) || (assign_date.toLowerCase().indexOf(search.toLowerCase()) !=-1))
                        return true;
                    else
                        return false;
                }
                else
                {
                    if(search=='fresh' || search == 'NC' || search == 'CB' || search == 'APPT' || search == 'NI' || search == 'W#' || search == 'DK')
                    {
                        if(item.disposition===search)
                            return true;
                        else
                            return false;
                    }
                    else
                    {
                        var first_name='';
                        if(item.edit.first_name)
                            first_name = item.edit.first_name;

                        var last_name='';
                        if(item.edit.last_name)
                            last_name = item.edit.last_name;

                        var fullname = first_name+" "+last_name;

                        var street_address_1='';
                        if(item.edit.street_address_1)
                            street_address_1 = item.edit.street_address_1;

                        var county='';
                        if(item.edit.county)
                            county = item.edit.county;

                        var state='';
                        if(item.edit.state)
                            state = item.edit.state;

                        var city='';
                        if(item.edit.city)
                            city = item.edit.city;

                        var lead_level='';
                        if(item.personalinfo.lead_level)
                            lead_level = item.personalinfo.lead_level;

                        var spouse_full_name='';
                        if(item.edit.spouse_full_name)
                            spouse_full_name = item.edit.spouse_full_name;

                        if (search && first_name.toLowerCase().indexOf(search.toLowerCase()) === -1 &&
                        last_name.toLowerCase().indexOf(search.toLowerCase()) ===-1  &&
                        fullname.toLowerCase().indexOf(search.toLowerCase()) ===-1  && 
                        street_address_1.toLowerCase().indexOf(search.toLowerCase()) ===-1  &&
                        county.toLowerCase().indexOf(search.toLowerCase()) ===-1  &&
                        state.toLowerCase().indexOf(search.toLowerCase()) ===-1  &&
                        city.toLowerCase().indexOf(search.toLowerCase()) ===-1  &&
                        lead_level.toLowerCase().indexOf(search.toLowerCase()) === -1 &&
                        spouse_full_name.toLowerCase().indexOf(search.toLowerCase()) === -1){
                            return false;
                        }
                        else
                            return true;
                    }
                }
           })
        }
        else{
            return items;
        }
    }
}