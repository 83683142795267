import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Theme } from '../../shared/service/AutoTheme';

interface Events
{
  id:number;
}



@Component({
    selector: 'full-layout',
    templateUrl: './full.component.html',
    styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
    color="default";
    showSettings = false;
    showMinisidebar = false;   
    showDarktheme = false;
    @Output() event: EventEmitter<Events> = new EventEmitter();
    public  footer :  boolean;

   //@Input() color:string;
//	@Output() getContact: EventEmitter<Dialer> = new EventEmitter();

    constructor(public router: Router, public theme:Theme) { }
    Open()
	{
        $('.right-sidebar').show();
        console.log("data show here");
	}

    ngOnInit() {
        
        this.theme.showmenu.subscribe(res=>{
            console.log(res);
            this.footer= res;
        })

        this.theme.themecolor.subscribe(res=>{
            console.log("New theme is : "+res);
            this.color=res;
        });

        if (this.router.url === '/') {
            this.router.navigate(['/dashboard/dashboard1']);
        }
        
    }


    onclickSchedule()
    {
        console.log("working layout");
        //this.event.emit();
    }
}
