
import { Injectable } from '@angular/core';
import { AppService } from '../shared/service/AppService.class';
import { HttpClient } from '@angular/common/http';
import { APIURL } from '../app-routing.module';
import { Observable }   from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Option } from '../shared/model/model.class';
import { Dialer, Scripts, ApptCalendar } from './model/model.class';
import { DispoData } from './model/model.class';


@Injectable()
export class MortgageService  extends AppService<Dialer>{
  constructor(protected http:HttpClient) {
    super(http);
    this.appmod="mortgage";
   }  

  public getContact():Observable<Dialer[]>{
    let tmp = this.appmod;
    this.appmod="custom/mortgage";   
    let data = this.getService();
    this.appmod=tmp;
    return data;
  }

  public getAllContact():Observable<Dialer[]>{
    let tmp = this.appmod;
    this.appmod="custom/allmortgage";   
    let data = this.getService();
    this.appmod=tmp;
    return data;
  }

  public searchMultipleContact(sdata:any):Observable<Dialer[]>{
    let tmp = this.appmod;
    this.appmod="custom/searchmortgage";   
    let data = this.getService(null, null, sdata);
    this.appmod=tmp;
    return data;
  }

  getBalance():Observable<any>
  {
    let tmp = this.appmod;
    this.appmod="balance";   
    let data = this.getService();
    this.appmod=tmp;
    return data;
    
  }

  public saveEmail(sdata:any):Observable<any>{
    let tmp = this.appmod;
    this.appmod="custom/saveemail";   
    let data = this.saveService(sdata);
    this.appmod=tmp;
    return data;   
  }

  public saveSMS(sdata:any):Observable<any>{
    let tmp = this.appmod;
    this.appmod="custom/savesms";   
    let data = this.saveService(sdata);
    this.appmod=tmp;
    return data;   
  }

  public smsSignalwire(sdata:any):Observable<any>{
    let tmp = this.appmod;
    this.appmod="custom/smssignalwire";   
    let data = this.saveService(sdata);
    this.appmod=tmp;
    return data;   
  }

  public smsTelnyx(sdata:any):Observable<any>{
    let tmp = this.appmod;
    this.appmod="custom/smstelnyx";   
    let data = this.saveService(sdata);
    this.appmod=tmp;
    return data;   
  }

  public smsThinkQ(sdata:any):Observable<any>{
    let tmp = this.appmod;
    this.appmod="custom/smsthinkq";   
    let data = this.saveService(sdata);
    this.appmod=tmp;
    return data;   
  }

  public saveDispoService(sdata:any):Observable<any>{
    let tmp = this.appmod;
    this.appmod="custom/mortgage";   
    let data = this.saveService(sdata);
    this.appmod=tmp;
    return data;   
  }

  public saveVDService(sdata:any):Observable<any>{
    let tmp = this.appmod;
    this.appmod="custom/savevdsms";   
    let data = this.saveService(sdata);
    this.appmod=tmp;
    return data;   
  }

  public getCurrentDid(leaddata:any):Observable<any>
  {
    if(leaddata) 
    {
      let tmp = this.appmod;
      this.appmod="custom/currentdid";
      let data = this.getService(null, null, {leadid:leaddata});
      this.appmod=tmp;
      return data;    
    }
  }
  public savePref(sdata:any):Observable<any>
  {
    let tmp = this.appmod;
    this.appmod="custom/changedialertype";   
    let data = this.custupdateService(sdata);
    this.appmod=tmp;
    return data;
  }

 public getPreferences():Observable<any>{
    let tmp = this.appmod;
    this.appmod="setting";   
    let data = this.getService();
    this.appmod=tmp;
    return data;   
  }

  public getObjections():Observable<any>{
    let tmp = this.appmod;
    this.appmod="objections";   
    let data = this.getService();
    this.appmod=tmp;
    return data;   
  }

  public setObjection(sdata:any):Observable<any>{
    let tmp = this.appmod;
    this.appmod="custom/setobjections";   
    let data = this.saveService(sdata);
    this.appmod=tmp;
    return data;   
  }


  getOptionM(data:string):Observable<Option[]>{
    return this.getOption(data);
  }

  /* getScripts():Observable<Scripts[]>
  {
    return this.http.get<Scripts[]>(APIURL+"custom/script");
  } */
  
  leadUpdate(ldata:any, key:number):Observable<any>
  {    
    let tmp = this.appmod;
    this.appmod="custom/leadupdate";   
    let leaddata = this.updateService(ldata, key);
    this.appmod=tmp;
    return leaddata;
  }

  getAuthToken():Observable<any>
  {
    let tmp = this.appmod;
    this.appmod="agentauthtoken";   
    let auth = this.getService();
    this.appmod=tmp;
    return auth;   
  }

  public setAppmod(key:string)
  {
    this.appmod="";
  }

}

@Injectable()
export class DispoDataService extends AppService<DispoData>{
  constructor(protected http:HttpClient) {
    super(http);
    this.appmod="";
   }

 public getScriptOption():Observable<any[]>
  {
    let tmp = this.appmod;
    this.appmod="scripts";   
    let data = this.getService();
    this.appmod=tmp;
    return data;   
  } 
}

@Injectable()
export class ApptCalendarService extends AppService<ApptCalendar>{
  constructor(protected http:HttpClient) {
    super(http);
    this.appmod="custom/agentgoogleevent";
  }

  public getScriptOption():Observable<any[]>
  {
    let tmp = this.appmod;
    this.appmod="scripts";   
    let data = this.getService();
    this.appmod=tmp;
    return data;    
  }

  getCalendarList():Observable<any>
  {
    let tmp = this.appmod;
    this.appmod="calendarlist";   
    let data = this.getService();
    this.appmod=tmp;
    return data;
    
  }

  getAuthToken():Observable<any>
  {
    let tmp = this.appmod;
    this.appmod="agentauthtoken";   
    let auth = this.getService();
    this.appmod=tmp;
    return auth;   
  }
}

@Injectable()
export class ScriptService extends AppService<Scripts>{
  constructor(protected http:HttpClient) {
    super(http);
    this.appmod="scripts";
   }  

   getScripts():Observable<Scripts[]>
   {
     return this.getService();
   }
}





  
 



