import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { DialerForm } from '../../../../mortgage/model/model.class';
import { extend } from 'jquery';
import { AppComponentClass } from '../../../service/AppComponent.class';
import { Dialer } from '../../model/dialer.class';
import { BehaviorSubject } from 'rxjs';
import { FormScripts, Scripts } from '../../model/script.class';


@Component({
    selector: 'dialer-floating',
    templateUrl: './flaoting.component.html',
    styleUrls: ['./flaoting.component.css']

})
export class FloatingComponent {

    script_name: string = "";
    cdata = null;
    showQueue = false;
    scriptmenu: Scripts[];

    private __data = new BehaviorSubject<Scripts>(null);
    public solution = this.__data.asObservable();
    public curscript: any;
    public curscripttitle: String;

    constructor(public dialog: MatDialog,) {
        this.cdata = new DialerForm(null);
    }

    @Input() set scriptdata(v: Scripts) {
        this.__data.next(v);
    }


    public show: boolean = false;
    public buttonName: any = 'Show';

    ngOnInit() {
        this.solution.subscribe(res => {
            if (res != null) {
                this.cdata = new FormScripts(res);
                // console.log("Current on script ts " + this.cdata.id);
                // console.log(res);
                this.curscript = res.script;
                this.curscripttitle = res.script_name;
            }
            else {
                console.log("NUll Script has been called");
                this.cdata = new FormScripts(res);
                this.curscript = null;
                this.curscripttitle = '';
            }
        });
    }

    toggle() {
        this.show = !this.show;

        // Change the name of the button.
        if (this.show)
            this.buttonName = "Hide";
        else
            this.buttonName = "Show";
    }

    showRSL() {
        document.querySelector('#__leads').classList.add('shw-rside');
    }
}
