import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { CareerDialer } from '../../careerdialer/model/careermodel.class';
import { Dialer } from '../dialer/model/dialer.class';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent {
	otherthem: boolean = false;
	@Input() data:Dialer[];
	@Output() getContact: EventEmitter<Dialer> = new EventEmitter();

	close()
	{
		$('.right-sidebar').toggle();
	}

	changethem()

	
	{
		this.otherthem = !this.otherthem;
	}

	onRowClick(data:Dialer)
	{
		console.log("this rightsidebar component"+JSON.stringify(data));
		this.getContact.emit(data);
		console.log("heloo");
	}
	
}
