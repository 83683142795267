import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Theme } from '../service/AutoTheme';

@Component({
  selector: 'app-dialer-disposition',
  templateUrl: './dialer-disposition.component.html',
  styleUrls: ['./dialer-disposition.component.css']
})

export class DialerDispositionComponent implements OnInit {

  public shwdialpad:boolean=false;

  constructor( public theme:Theme) { }

  @Output()  dialdisposition = new EventEmitter();

  ngOnInit() {
    this.theme.showdialpad.subscribe(ress =>{
      console.log("Show Dialpad");
      console.log(ress);
      this.shwdialpad=ress;
  });
  }


  onClick(number)
  {
    console.log("footer page dispo click:"+ number)
    this.dialdisposition.emit(number);
  }
}
