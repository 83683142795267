
import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AppComponentClass } from '../../../service/AppComponent.class';
import { Theme } from '../../../service/AutoTheme';
import { Custom, CustomForm } from '../../model/custom.class';
import { DialerService } from '../../dialer.service';

@Component({
  selector: 'dialer-custom',
  templateUrl: './custom.component.html',
  styleUrls: ['./custom.component.css']
})
export class CustomComponent extends AppComponentClass<Custom, CustomForm> {
 
  //private ssocket: SocketService<string>;
  
  constructor(public data: DialerService,  protected router: Router, protected fb: FormBuilder, protected msg: MatSnackBar, protected theme:Theme)
  {
    super(data, router, fb , msg); 
   // this.ssocket=new SocketService();
  }

}
