import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { Dialer } from '../dialer/model/dialer.class';

@Component({
  selector: 'ap-rightsidebar',
  templateUrl: './rightsidebar.component.html'
})
export class RightSidebarComponent {
	otherthem: boolean = false;
	@Input() data:Dialer[];
	@Output() getContact: EventEmitter<Dialer> = new EventEmitter();

	close()
	{
		$('.right-sidebar').toggle();
	}

	changethem()	
	{
		this.otherthem = !this.otherthem;
	}

	onRowClick(data:Dialer)
	{
		//console.log("this rightsidebar component"+JSON.stringify(data));
		this.getContact.emit(data);
		console.log("heloo");
	}
	
}
