import { FormBuilder, FormGroup} from "@angular/forms";
import {
    CalendarEvent,
    CalendarEventAction,
    CalendarEventTimesChangedEvent
  } from 'angular-calendar';


  
// staring for Edit  module
export interface Email 
{
  
    email : string,
  
}

export interface HTTPEmail
{
  
    email : string,
  
}

export class EmailForm implements HTTPEmail{
  
    public email : string;
  

    constructor(data : Email) 
    {
        if(data==null)
        {
           
            this.email="";
          
        }
        else
        {
           
            this.email=data.email;
            
        }
    }

    public makeform(fb:FormBuilder):FormGroup
    {
        return fb.group({
           
            email: [this.email],
            
        });
    }

    public saveForm(fg:FormGroup):boolean
    {
        if(fg.valid)
        {

        
            this.email=fg.get("email").value;
          
            return true;
        }
        else
        {
            return false;
        }
    }
  }

  //End Edit form
