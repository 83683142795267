import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
    selector: 'message_dialog',
    templateUrl: './message_dialog.component.html',
    styleUrls: ['./message_dialog.component.css']
})

export class Message_DialogComponent {
    constructor(public dialogRef: MatDialogRef<Message_DialogComponent>,  @Inject(MAT_DIALOG_DATA) public data: any){
        console.log(data.cell_phone)
    }
}