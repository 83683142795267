import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements OnInit {

  @Input() message:string[];
  @Output() active: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  OnClick(type:number)
  {
    console.log("Current event : "+type);
  }

}
