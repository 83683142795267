import {Component,ElementRef, ViewChild} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';


@Component({
  selector: 'email-dialog',
  templateUrl: 'email-dialog.component.html',
  styleUrls: ['email-dialog.component.css'],
})
export class EmailDialogComponent {
    @ViewChild('selectElement') selectElement: ElementRef;
  @ViewChild('textareaElement') textareaElement: ElementRef;
  
  ngAfterViewInit() {
    this.selectElement.nativeElement.addEventListener('change', () => {
      if (this.selectElement.nativeElement.value === '1') {
        this.textareaElement.nativeElement.value = 'Hello ' + this.data.name + ' '+ 'We are try to contact you';
      }
    });
  }
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        console.log(data.number); // Access the number data passed to the dialog
      }
}
