//import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
//import { Routes, RouterModule } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { SIDEBAR_TOGGLE_DIRECTIVES } from './shared/sidebar.directive';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import { Theme } from './shared/service/AutoTheme';
import { JwtInterceptor } from './authentication/helper/jwt.interceptor';
import { AuthGuard } from './authentication/guard/auth.guard';
import { AuthenticationService } from './authentication/authentication.service';
import { ErrorInterceptor } from './authentication/helper/error.interceptor';
import { ServerSocket } from './shared/service/Activity.socket';
import { MatDialogModule } from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

/* import { ServerSocket } from './shared/service/Activity.socket'; */





@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    AppBlankComponent,
    SIDEBAR_TOGGLE_DIRECTIVES
    
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,   
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    HttpModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule
      
  ],
 /*  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  }, Theme, fakeBackendProvider], */
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    /* fakeBackendProvider, */AuthenticationService, Theme, AuthGuard, ServerSocket
],
  bootstrap: [AppComponent]
})
export class AppModule { }
