import { Component, OnInit, Input, Optional } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AppComponentClass } from '../../service/AppComponent.class';
import { DialerService } from '../dialer.service';
import { Scripts, FormScripts } from '../model/script.class';

@Component({
  selector: 'dialer-script',
  templateUrl: './script.component.html',
  styleUrls: ['./script.component.css']
})

export class ScriptComponent extends AppComponentClass<Scripts, FormScripts>  {
  private __data = new BehaviorSubject<Scripts>(null);
  public solution= this.__data.asObservable();

  // public optmenu:CareerScripts[];
   public curscript:any;

  constructor(public data: DialerService, protected router: Router, protected fb: FormBuilder, protected msg: MatSnackBar)
  {
    super(data, router, fb , msg); 
  }
  @Input() set scriptdata(v : Scripts) {
    this.debug_log("Setting current data for script to "+v);
    this.__data.next(v);
  }

  ngOnInit() {
    this.solution.subscribe(res=>{
      if(res !=null)
      {
        this.cdata=new FormScripts(res);
        console.log("Current on script ts "+this.cdata.id);
        console.log(res);
        this.curscript=res.script;    
      }
      else
      {
        console.log("NUll Script has been called");
        this.cdata=new FormScripts(res);
        this.curscript=null; 
      } 
    });
  }

  
}
