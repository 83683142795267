import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AppComponentClass } from '../../../service/AppComponent.class';
import { Option } from '../../../model/model.class';
import { Theme } from '../../../service/AutoTheme';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DialerService } from '../../dialer.service';


import { Edit, EditForm } from '../../model/edit.class';
import { MortgageForm, Mortgage } from '../../model/mortgage.class';


@Component({
  selector: 'dialer-mortgage',
  templateUrl: './mortgage.component.html',
  styleUrls: ['./mortgage.component.css']
})


export class MortgageComponent extends AppComponentClass<Mortgage, MortgageForm> {
  private __data = new BehaviorSubject<Mortgage>(null);
  public solution= this.__data.asObservable();
  public form:FormGroup;
  public leadid=localStorage.getItem("LeadID") || -1;
  public callerid=localStorage.getItem("callerid") || "None";
  public currscript=localStorage.getItem("currscript") || -1;
  public extaccount=localStorage.getItem("extaccount") || "None";
  public extuser=localStorage.getItem("extuser")|| "NA";
  public emailtemp:Option[];
  public smstemp:Option[];
  public vdmsg:Option[];
  event;
  value: string;
  viewValue: string;
  public __click2dial:boolean=false;
  public __hangup:boolean=false;

  protected cellnostatus=new BehaviorSubject<boolean>(false);
  public cellnoview = this.cellnostatus.asObservable();
  protected homenostatus=new BehaviorSubject<boolean>(false);
  public homenoview = this.homenostatus.asObservable();
  protected worknostatus=new BehaviorSubject<boolean>(false);
  public worknoview = this.worknostatus.asObservable();
  protected emailstatus=new BehaviorSubject<boolean>(false);
  public emailview = this.emailstatus.asObservable();
  
  //private ssocket: SocketService<string>;
  
  constructor(public edata: DialerService, protected router: Router, protected fb: FormBuilder, protected msg: MatSnackBar, protected theme:Theme)
  {
    super(edata, router, fb , msg); 
   // this.ssocket=new SocketService();
  }

  @Input() set mortgagedata(v : Mortgage) {
    this.debug_log("Setting current data");
    this.__data.next(v);
  }
 

  ngOnInit() {
    this.solution.subscribe(res=>{
      console.log("Changed data on edit "+res);
      if(res==null)
      {
        this.cdata= new MortgageForm(null);
        this.form=this.cdata.makeform(this.fb);
      }
      else
      {
        this.cdata= new MortgageForm(res);
        this.form=this.cdata.makeform(this.fb);
        localStorage.setItem("editmod", JSON.stringify(this.cdata));
      }       
    });

    this.theme.event.subscribe(res=>{
      console.log("Current changed dial type is : "+res);

      console.log(this.cdata);
      if(res==1)
      {
        this.leadid=localStorage.getItem("LeadID");
        this.__click2dial=false;
        this.__hangup=false;      
      }
      else if(res==0)
      {
        // this.ddsocket.openSocket("hangup", this.extuser);
        this.__click2dial=true;
        this.__hangup=true;
      }
      else
      {
        this.__click2dial=false;
        this.__hangup=false;
      }
    });
    
  }

  onSubmit()
  {
    if(this.cdata.saveForm(this.form))
      super.onSubmit();
  }


  warning()
  {
    if(this.cdata.first_name != this.form.get("first_name").value)
      this.cdata.first_name = this.form.get("first_name").value;
    if(this.cdata.last_name != this.form.get("last_name").value)
      this.cdata.last_name = this.form.get("last_name").value;
    if(this.cdata.spouse_full_name != this.form.get("spouse_full_name").value)
      this.cdata.spouse_full_name = this.form.get("spouse_full_name").value;
    if(this.cdata.street_address_1 != this.form.get("street_address_1").value)
      this.cdata.street_address_1 = this.form.get("street_address_1").value;
    if(this.cdata.city != this.form.get("city").value)
      this.cdata.city = this.form.get("city").value;
    if(this.cdata.state != this.form.get("state").value)
      this.cdata.state = this.form.get("state").value;
    if(this.cdata.zip != this.form.get("zip").value)
      this.cdata.zip = this.form.get("zip").value;
    if(this.cdata.county != this.form.get("county").value)
      this.cdata.county = this.form.get("county").value;
      if(this.cdata.c_i_salery_a != this.form.get("c_i_salery_a").value)
      this.cdata.c_i_salery_a = this.form.get("c_i_salery_a").value;

      //alert('click on plain place of panel to save data')
  }

  changeEvnt(key:string)
  { 
    console.log(key);
    if(key == 'first_name')
      this.cdata.first_name = this.form.get("first_name").value;
    if(key == 'last_name')
      this.cdata.last_name = this.form.get("last_name").value;
    if(key == 'spouse_full_name')
      this.cdata.spouse_full_name = this.form.get("spouse_full_name").value;
    if(key == 'street_address_1')
      this.cdata.street_address_1 = this.form.get("street_address_1").value;
    if(key == 'city')
      this.cdata.city = this.form.get("city").value;
    if(key == 'state')
    {
      this.cdata.state = this.form.get("state").value;
      if(this.cdata.state=='CA')
        this.theme.blockAutodial(false);
      else
        this.theme.blockAutodial(true);
    }
    if(key == 'zip')
      this.cdata.zip = this.form.get("zip").value;
    if(key == 'county')
      this.cdata.county = this.form.get("county").value;
      if(key == 'c_i_salery_a')
      this.cdata.c_i_salery_a = this.form.get("c_i_salery_a").value;    
    
    localStorage.setItem("editmod", JSON.stringify(this.cdata));
    console.log("OnChange events called edit data : Key "+key+" Value :"+this.form.get(key).value);
    console.log(localStorage.getItem("editmod"));

    // update edit localstorage data
    var editFromLocalStorage = JSON.parse(localStorage.getItem("edit"));
    editFromLocalStorage.first_name = this.cdata.first_name;
    editFromLocalStorage.last_name = this.cdata.last_name;
    editFromLocalStorage.spouse_full_name = this.cdata.spouse_full_name;
    editFromLocalStorage.street_address_1 = this.cdata.street_address_1;
    editFromLocalStorage.city = this.cdata.city;
    editFromLocalStorage.zip = this.cdata.zip;
    editFromLocalStorage.county = this.cdata.county;
    editFromLocalStorage.c_i_salery_a = this.cdata.c_i_salery_a;
    localStorage.setItem("edit", JSON.stringify(editFromLocalStorage));

    //Current lead
    let current=parseInt(localStorage.getItem("current_lead"));

    let search_lead=parseInt(localStorage.getItem("search_lead"));
    if(search_lead!=0)
    {
      current = search_lead;
    }
    console.log("current_lead: "+current);

     // update contact localstorage data
     var contactsFromLocalStorage = JSON.parse(localStorage.getItem("contacts"));
     contactsFromLocalStorage[current].edit = JSON.parse(localStorage.getItem("edit"));
     localStorage.setItem("contacts",JSON.stringify(contactsFromLocalStorage));
  }
}
