import { Component, OnInit, Input } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatSnackBar } from "@angular/material";
import { AppComponentClass } from "../../../service/AppComponent.class";
import { Option } from "../../../model/model.class";
import { Theme } from "../../../service/AutoTheme";
import { Phone, PhoneForm } from "../../model/phone.class";
import { DialerService } from "../../dialer.service";
import { SipJsService } from "../../../service/sipjsServices.class";
import { Message_DialogComponent } from "../messagemodel/message_dialog.component";
import { Message_DialogComponent_cellphone } from "../messagemodelcell_phone/messagedialog_cellphone.component";

@Component({
  selector: "dialer-phone",
  templateUrl: "./phone.component.html",
  styleUrls: ["./phone.component.css"],
})
export class PhoneComponent extends AppComponentClass<Phone, PhoneForm> {
  private __data = new BehaviorSubject<Phone>(null);
  public solution = this.__data.asObservable();
  public form: FormGroup;
  public leadid = localStorage.getItem("LeadID") || -1;
  public callerid = localStorage.getItem("callerid") || "None";
  public currscript = localStorage.getItem("currscript") || -1;
  public extaccount = localStorage.getItem("extaccount") || "None";
  public extuser = localStorage.getItem("extuser") || "NA";
  public emailtemp: Option[];
  public smstemp: Option[];
  public vdmsg: Option[];
  event: any;
  value: string;
  viewValue: string;
  public __click2dial: boolean = false;
  public __hangup: boolean = false;

  protected cellnostatus = new BehaviorSubject<boolean>(false);
  public cellnoview = this.cellnostatus.asObservable();
  protected homenostatus = new BehaviorSubject<boolean>(false);
  public homenoview = this.homenostatus.asObservable();
  protected worknostatus = new BehaviorSubject<boolean>(false);
  public worknoview = this.worknostatus.asObservable();

  constructor(
    public edata: DialerService,
    protected router: Router,
    protected fb: FormBuilder,
    protected msg: MatSnackBar,
    protected theme: Theme,
    private sipdata: SipJsService,
    public dialog: MatDialog,
  ) {
    super(edata, router, fb, msg);
    // this.ssocket=new SocketService();
  }

  @Input() set phonedata(v: Phone) {
    this.debug_log("Setting current data");
    this.__data.next(v);
  }

  clickSms(value: number, type: string) {
    if (value && value > 0) {
      this.theme.ChangeLead(-5);
      this.leadid = localStorage.getItem("LeadID");
      this.callerid = localStorage.getItem("callerid");

      let to_number: string;
      switch (type) {
        case "1": {
          to_number = "+" + this.cdata.cell_phone;
          localStorage.setItem("current_number", this.cdata.cell_phone);
          localStorage.setItem("number_type", "mdi-cellphone");
          break;
        }
        case "2": {
          to_number = "+" + this.cdata.phone;
          localStorage.setItem("current_number", this.cdata.phone);
          localStorage.setItem("number_type", "mdi-home");
          break;
        }
        case "3": {
          to_number = "+" + this.cdata.work_phone;
          localStorage.setItem("current_number", this.cdata.work_phone);
          localStorage.setItem("number_type", "mdi-worker");
        }
      }

      let sdata = {
        id_lead: this.leadid,
        id_template: value,
        to_number: to_number,
        from_number: "+" + localStorage.getItem("callerid"),
      };

      this.theme.changeSms(sdata);
    } else {
      this.openSnackBar("No SMS Option is available", "");
    }
  }

  clickVoiceDrop(value: number, type: string) {
    if (value && value > 0) {
      this.theme.ChangeLead(-5);
      console.log("working: " + value);
      this.leadid = localStorage.getItem("LeadID");
      let to_number: string;
      switch (type) {
        case "1": {
          localStorage.setItem("current_number", this.cdata.cell_phone);
          localStorage.setItem("number_type", "mdi-cellphone");
          to_number = this.cdata.cell_phone;
          break;
        }
        case "2": {
          localStorage.setItem("current_number", this.cdata.phone);
          localStorage.setItem("number_type", "mdi-home");
          to_number = this.cdata.phone;
          break;
        }
        case "3": {
          localStorage.setItem("current_number", this.cdata.work_phone);
          localStorage.setItem("number_type", "mdi-worker");
          to_number = this.cdata.work_phone;
        }
      }
      let sdata = {
        id_lead: this.leadid,
        id_message: 0,
        audio: value,
        to_number: to_number,
        from_number: localStorage.getItem("callerid"),
      };
      this.theme.SaveVdSms(JSON.stringify(sdata));
    } else {
      this.openSnackBar("No VoiceDrop Option is available", "");
    }
  }

  ngOnInit() {
    this.solution.subscribe((res) => {
      console.log("Changed data on edit " + res);
      if (res == null) {
        this.cdata = new PhoneForm(null);
        this.form = this.cdata.makeform(this.fb);
      } else {
        this.cdata = new PhoneForm(res);
        this.form = this.cdata.makeform(this.fb);

        if (this.cdata.cell_phone)
          localStorage.setItem("current_number", this.cdata.cell_phone);
        else {
          if (this.cdata.phone)
            localStorage.setItem("current_number", this.cdata.phone);
          else {
            if (this.cdata.work_phone)
              localStorage.setItem("current_number", this.cdata.work_phone);
          }
        }
        if (this.cdata.cell_phone) this.cellnostatus.next(true);
        if (this.cdata.phone) this.homenostatus.next(true);
        if (this.cdata.work_phone) this.worknostatus.next(true);
        console.log("phone page");
        console.log(this.cdata);
        localStorage.setItem("phone", JSON.stringify(this.cdata));
      }
    });

    this.theme.smsolution.subscribe((res) => {
      console.log("sms option");
      console.log(res);
      if (res && res != null && res.length > 0) this.smstemp = res;
      else this.smstemp = [{ label: "No option", value: 0 , message:"no message"}];
    });

    this.theme.vdsolution.subscribe((res) => {
      console.log("vd option");
      console.log(res);
      if (res && res != null && res.length > 0) this.vdmsg = res;
      else this.vdmsg = [{ label: "No option", value: 0 , message:"no message"}];
    });

    this.theme.event.subscribe((res) => {
      console.log("Current changed dial type is : " + res);

      console.log(this.cdata);
      if (res == 1) {
        this.leadid = localStorage.getItem("LeadID");
        this.__click2dial = false;
        this.__hangup = false;
        //this.ddsocket.openSocket("autodial", JSON.stringify({phone:'917532973604', callerid:this.callerid, account:this.extaccount, campaign:this.currscript, lead:this.leadid, phone2:'917532973604', phone3:'917532973604'}));
        let cellnos = this.cdata.cell_phone;
        if (!cellnos) cellnos = "";

        let homenos = this.cdata.phone;
        if (!homenos) homenos = "";

        let worknos = this.cdata.work_phone;
        if (!worknos) worknos = "";

        // this.ddsocket.openSocket("autodial", JSON.stringify({phone:cellnos, callerid:this.callerid, account:this.extaccount, campaign:this.currscript, lead:this.leadid, phone2:homenos, phone3:worknos}));

        console.log("cellnos: " + cellnos);
        console.log("homenos: " + homenos);
        console.log("worknos: " + worknos);
      } else if (res == 0) {
        //  this.ddsocket.openSocket("hangup", this.extuser);
        this.__click2dial = true;
        this.__hangup = true;
      } else {
        this.__click2dial = false;
        this.__hangup = false;
      }
    });

    this.theme.startautodial.subscribe((res) => {
      if (res != undefined) {
        let prefdata = JSON.parse(localStorage.getItem("preferences"));
        let attampts = parseInt(localStorage.getItem("callattampts"));
        let type = parseInt(localStorage.getItem("phonetype"));
        /* let autodelay=JSON.parse(localStorage.getItem('preferences')).autodialing_delay;
        autodelay = parseInt(autodelay);
          if(autodelay==0)
            autodelay=2000;
          else
            autodelay = autodelay * 1000; */
        console.log("Current phone type ::" + type);
        console.log("Current phone Attampts ::" + attampts);
        console.log(
          "Current Autodial are :: " + localStorage.getItem("isautodial")
        );

        if (parseInt(localStorage.getItem("isautodial")) == 1) {
          switch (type) {
            case 1: {
              if (prefdata.cellphone > attampts) {
                if (this.cdata.cell_phone != "") {
                  if (parseInt(localStorage.getItem("callattampts")) > 1) {
                    setTimeout(() => {
                      this.makeCall(1);
                    }, 2000);
                  } else this.makeCall(1);
                  localStorage.setItem(
                    "callattampts",
                    (attampts + 1).toString()
                  );
                } else {
                  localStorage.setItem("phonetype", (type + 1).toString());
                  localStorage.setItem("callattampts", (0).toString());
                  this.theme.StartAutodial(2);
                }
              } else {
                localStorage.setItem("phonetype", (type + 1).toString());
                console.log("current type::" + (type + 1).toString()),
                  localStorage.setItem("callattampts", (0).toString());
                this.theme.StartAutodial(2);
              }
              break;
            }
            case 2: {
              if (prefdata.homephone > attampts) {
                if (this.cdata.phone != "") {
                  if (parseInt(localStorage.getItem("callattampts")) > 1) {
                    setTimeout(() => {
                      this.makeCall(2);
                    }, 2000);
                  } else this.makeCall(2);
                  localStorage.setItem(
                    "callattampts",
                    (attampts + 1).toString()
                  );
                } else {
                  localStorage.setItem("phonetype", (type + 1).toString());
                  localStorage.setItem("callattampts", (0).toString());
                  this.theme.StartAutodial(3);
                }
              } else {
                localStorage.setItem("phonetype", (type + 1).toString());
                localStorage.setItem("callattampts", (0).toString());
                this.theme.StartAutodial(3);
              }
              break;
            }
            case 3: {
              if (prefdata.workphone > attampts) {
                if (this.cdata.work_phone != "") {
                  if (parseInt(localStorage.getItem("callattampts")) > 1) {
                    setTimeout(() => {
                      this.makeCall(3);
                    }, 2000);
                  } else this.makeCall(3);
                  localStorage.setItem(
                    "callattampts",
                    (attampts + 1).toString()
                  );
                } else {
                  localStorage.setItem("phonetype", (0).toString());
                  //this.theme.ChangeAutodial(1);
                }
              } else {
                localStorage.setItem("phonetype", (0).toString());
                localStorage.setItem("callattampts", (0).toString());
                localStorage.setItem("totalcallattempts", (0).toString());
              }
              break;
            }
          }

          if (type > 3) {
            localStorage.setItem("phonetype", (0).toString());
            localStorage.setItem("callattampts", (0).toString());
            localStorage.setItem("totalcallattempts", (0).toString());
          }
        }
      }
    });

    this.theme.stopautodial.subscribe((res) => {
      if (res == 1) {
        this.Hangup();
      }
    });
  }

  onSubmit() {
    if (this.cdata.saveForm(this.form)) super.onSubmit();
  }

  makeCall(type: number) {
    this.leadid = localStorage.getItem("LeadID");
    let totalattempts = parseInt(localStorage.getItem("totalcallattempts"));
    localStorage.setItem("totalcallattempts", (totalattempts + 1).toString());
    switch (type) {
      case 1: {
        console.log("Current Number" + this.cdata.cell_phone);
        //this.ddsocket.openSocket("makecall", this.cdata.cell_phone);

        if (this.cdata.cell_phone != "") {
          localStorage.setItem("current_number", this.cdata.cell_phone);
          this.sipdata.MakeOutboundCall(parseInt(this.cdata.cell_phone));
        }
        //localStorage.setItem('number_type', 'Cell Phone');
        localStorage.setItem("number_type", "mdi-cellphone");
        //this.ddsocket.openSocket("makecall", JSON.stringify({phone:'917532973604', type:'Cell Phone', callerid:this.callerid, account:this.extaccount, campaign:this.currscript, lead:this.leadid}));
        // this.ddsocket.openSocket("makecall", JSON.stringify({phone:this.cdata.cell_phone, type:'Cell Phone', callerid:this.callerid, account:this.extaccount, campaign:this.currscript, lead:this.leadid}));
        break;
      }
      case 2: {
        console.log("Current Number" + this.cdata.phone);
        if (this.cdata.phone != "") {
          localStorage.setItem("current_number", this.cdata.phone);
          this.sipdata.MakeOutboundCall(parseInt(this.cdata.phone));
        }
        //localStorage.setItem('number_type', 'Home Phone');
        localStorage.setItem("number_type", "mdi-home");
        //this.ddsocket.openSocket("makecall", JSON.stringify({phone:'917532973604', type:'Home Phone', callerid:this.callerid, account:this.extaccount, campaign:this.currscript, lead:this.leadid}));
        // this.ddsocket.openSocket("makecall", JSON.stringify({phone:this.cdata.phone, type:'Home Phone', callerid:this.callerid, account:this.extaccount, campaign:this.currscript, lead:this.leadid}));
        break;
      }
      case 3: {
        console.log("Current Number" + this.cdata.work_phone);
        if (this.cdata.work_phone != "") {
          localStorage.setItem("current_number", this.cdata.work_phone);
          this.sipdata.MakeOutboundCall(parseInt(this.cdata.work_phone));
        }
        //localStorage.setItem('number_type', 'Work Phone');
        localStorage.setItem("number_type", "mdi-worker");
        //this.ddsocket.openSocket("makecall", JSON.stringify({phone:'917532973604', type:'Work Phone', callerid:this.callerid, account:this.extaccount, campaign:this.currscript, lead:this.leadid}));
        // this.ddsocket.openSocket("makecall", JSON.stringify({phone:this.cdata.work_phone, type:'Home Phone', callerid:this.callerid, account:this.extaccount, campaign:this.currscript, lead:this.leadid}));
        break;
      }
    }
    //this.sipdata.RecieveSipjsEvents();
  }

  Hangup() {
    //alert("Call is hangup.");
    this.sipdata.HangupCall(parseInt(localStorage.getItem("current_number")));
  }

  warning() {
    if (this.cdata.cell_phone != this.form.get("cell_phone").value) {
      if (this.form.get("cell_phone").value) {
        let cellnum = this.form.get("cell_phone").value;
        if (cellnum.length >= 10) {
          var numberPattern: RegExp = /\d+/g;
          let cellno = cellnum.match(numberPattern);
          cellnum = "";
          cellno.forEach((val: any) => {
            cellnum += val;
          });
          if (cellnum.length == 10) cellnum = "1" + cellnum;
        }
        this.cdata.cell_phone = cellnum;
      } else this.cdata.cell_phone = "";
    }
    if (this.cdata.phone != this.form.get("phone").value) {
      if (this.form.get("phone").value) {
        let homenum = this.form.get("phone").value;
        if (homenum.length >= 10) {
          var numberPattern: RegExp = /\d+/g;
          let homeno = homenum.match(numberPattern);
          homenum = "";
          homeno.forEach((val: any) => {
            homenum += val;
          });
          if (homenum.length == 10) homenum = "1" + homenum;
        }
        this.cdata.phone = homenum;
      } else this.cdata.phone = "";
    }
    if (this.cdata.work_phone != this.form.get("work_phone").value) {
      if (this.form.get("work_phone").value) {
        let worknum = this.form.get("work_phone").value;
        if (worknum.length >= 10) {
          var numberPattern: RegExp = /\d+/g;
          let workno = worknum.match(numberPattern);
          worknum = "";
          workno.forEach((val: any) => {
            worknum += val;
          });
          if (worknum.length == 10) worknum = "1" + worknum;
        }
        this.cdata.work_phone = worknum;
      } else this.cdata.work_phone = "";
    }
    //alert('click on plain place of panel to save data');
  }

  changeEvnt(key: string) {
    console.log(key);
    if (key == "cell_phone") {
      if (this.form.get("cell_phone").value) {
        let cellnum = this.form.get("cell_phone").value;
        if (cellnum.length >= 10) {
          var numberPattern : RegExp = /\d+/g;
          let cellno = cellnum.match(numberPattern);
          cellnum = "";
          cellno.forEach((val: any) => {
            cellnum += val;
          });
          if (cellnum.length == 10) cellnum = "1" + cellnum;
        }
        this.cdata.cell_phone = cellnum;
        //this.cdata.cell_phone = this.form.get("cell_phone").value;
      } else this.cdata.cell_phone = "";
    }
    if (key == "phone") {
      if (this.form.get("phone").value) {
        let homenum = this.form.get("phone").value;
        if (homenum.length >= 10) {
          var numberPattern: RegExp = /\d+/g;
          let homeno = homenum.match(numberPattern);
          homenum = "";
          homeno.forEach((val: any) => {
            homenum += val;
          });
          if (homenum.length == 10) homenum = "1" + homenum;
        }
        this.cdata.phone = homenum;
        //this.cdata.phone = this.form.get("phone").value;
      } else this.cdata.phone = "";
    }
    if (key == "work_phone") {
      if (this.form.get("work_phone").value) {
        let worknum = this.form.get("work_phone").value;
        if (worknum.length >= 10) {
          var numberPattern: RegExp = /\d+/g;
          let workno = worknum.match(numberPattern);
          worknum = "";
          workno.forEach((val: any) => {
            worknum += val;
          });
          if (worknum.length == 10) worknum = "1" + worknum;
        }
        this.cdata.work_phone = worknum;
        //this.cdata.work_phone = this.form.get("work_phone").value;
      } else this.cdata.work_phone = "";
    }

    if (this.cdata.cell_phone) this.cellnostatus.next(true);
    else this.cellnostatus.next(false);
    if (this.cdata.phone) this.homenostatus.next(true);
    else this.homenostatus.next(false);
    if (this.cdata.work_phone) this.worknostatus.next(true);

    localStorage.setItem("phone", JSON.stringify(this.cdata));
    console.log(
      "OnChange events called phone data : Key " +
        key +
        " Value :" +
        this.form.get(key).value
    );
    console.log(localStorage.getItem("phone"));

    // update edit localstorage data
    var editFromLocalStorage = JSON.parse(localStorage.getItem("edit"));
    editFromLocalStorage.cell_phone = this.cdata.cell_phone;
    editFromLocalStorage.phone = this.cdata.phone;
    editFromLocalStorage.work_phone = this.cdata.work_phone;
    localStorage.setItem("edit", JSON.stringify(editFromLocalStorage));

    //Current lead
    let current = parseInt(localStorage.getItem("current_lead"));

    let search_lead = parseInt(localStorage.getItem("search_lead"));
    if (search_lead != 0) {
      current = search_lead;
    }
    console.log("current_lead: " + current);

    // update contact localstorage data
    var contactsFromLocalStorage = JSON.parse(localStorage.getItem("contacts"));
    contactsFromLocalStorage[current].edit = JSON.parse(
      localStorage.getItem("edit")
    );
    localStorage.setItem("contacts", JSON.stringify(contactsFromLocalStorage));
  }
openMessageModel(){
  let dialogRef = this.dialog.open(Message_DialogComponent, {
    width: "30%",
    height: "calc(100vh - 50vh)",
    panelClass: 'comment-dialog',
    data: this.cdata,
  });
console.log("dialogRef",this.cdata)
}

openMessageModel_cellphone(){
  let dialogRef = this.dialog.open(Message_DialogComponent_cellphone, {
    width: "30%",
    height: "calc(100vh - 50vh)",
    panelClass: 'comment-dialog',
    data: this.cdata,
  });
console.log("dialogRef",this.cdata)
}
}
