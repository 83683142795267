
import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
/* import { ServerSocket } from '../../shared/service/Activity.socket'; */

import * as SIP from 'sip.js';
import parsip from 'parsip';
import { AppComponentClass } from '../../../service/AppComponent.class';
import { EditPersonal, EditPersonalForm } from '../../model/editpersonal.class';
import { Option } from '../../../model/model.class';
import { Theme } from '../../../service/AutoTheme';
import { SipJsService } from '../../../service/sipjsServices.class';
import { CareerEditService } from '../../../../careerdialer/careerdialer.services';
import { DialerService } from '../../dialer.service';

@Component({
  selector: 'dialer-careerpersonal',
  templateUrl: './careerpersonal.component.html',
  styleUrls: ['./careerpersonal.component.css']
})


export class CareerpersonalComponent extends AppComponentClass<EditPersonal, EditPersonalForm> {
  private __data = new BehaviorSubject<EditPersonal>(null);
  public solution= this.__data.asObservable();
  public form:FormGroup;
  public leadid=localStorage.getItem("LeadID") || -1;
  public callerid=localStorage.getItem("callerid") || "None";
  public currscript=localStorage.getItem("currscript") || -1;
  public extaccount=localStorage.getItem("extaccount") || "None";
  public extuser=localStorage.getItem("extuser")|| "NA";
  public emailtemp:Option[];
  public smstemp:Option[];
  public vdmsg:Option[];
  event;
  value: string;
  viewValue: string;
  public __click2dial:boolean=false;
  public __hangup:boolean=false;

  protected cellnostatus=new BehaviorSubject<boolean>(false);
  public cellnoview = this.cellnostatus.asObservable();
  protected homenostatus=new BehaviorSubject<boolean>(false);
  public homenoview = this.homenostatus.asObservable();
  protected worknostatus=new BehaviorSubject<boolean>(false);
  public worknoview = this.worknostatus.asObservable();
  protected emailstatus=new BehaviorSubject<boolean>(false);
  public emailview = this.emailstatus.asObservable();
  
  //private ssocket: SocketService<string>;
  
  constructor(public edata: DialerService, protected router: Router, protected fb: FormBuilder, protected msg: MatSnackBar, protected theme:Theme, protected sipdata:SipJsService)
  {
    super(edata, router, fb , msg); 
    this.form=null;
   // this.ssocket=new SocketService();
  }

  /* @Input() set compdata(v : EditPersonal) {
    this.debug_log("Setting current data");
    this.__data.next(v);
  }

  qResponses=[
    {value: '1', viewValue: 'Demo SMS1'},
    {value: '2', viewValue: 'Demo SMS2'}
  ];
  vResponses1=[
    {value: '1', viewValue: 'Demo VD1'},
    {value: '2', viewValue: 'Demo VD2'}
  ];
  eResponses2=[
    {value: '1', viewValue: 'Demo Email1'},
    {value: '2', viewValue: 'Demo Email2'}
  ]; */

  /* login(user: ){
    if (user.userName == '1'  ) { // {3}
      this.loggedIn.next(true);
    }
  } */

 /*  clickEmail(value:number) {
    console.log(value);
    this.leadid=localStorage.getItem("LeadID");
    // console.log(this.leadid);
    // console.log(this.cdata.email);
    let sdata={id_lead:this.leadid, id_template:value};
   // this.edata.setAppmod("custom/emailtmp");
    this.edata.saveService(JSON.stringify(sdata)).subscribe( rs =>  {
      console.log("Save Email data");
      console.log(rs);
      this.openSnackBar(rs.message,'');
    });
  }
 */
  /* clickSms(value:string) {
    console.log(value);
    this.leadid=localStorage.getItem("LeadID");
    // console.log(this.leadid);
    // console.log(this.cdata.cell_phone);
    let smsdata = value.split('_');
    console.log(smsdata[0]);
    let to_number;
    switch (smsdata[1])
    {
      case '1': {
        to_number = '+' + this.cdata.candidate_phone;
        localStorage.setItem('current_number', this.cdata.candidate_phone);
        localStorage.setItem('number_type', 'mdi-cellphone');
        break;
      }
    }
    let sdata={id_lead:this.leadid, id_template:smsdata[0], to_number:to_number, from_number:'+' + this.callerid};
   // this.edata.setAppmod("custom/smstmp");
    this.edata.saveService(JSON.stringify(sdata)).subscribe( rs =>  {
      console.log("Save SMS data");
      console.log(rs);
      this.openSnackBar(rs.message,'');
      /* this.ddsocket.openSocket("minutes", this.extuser); */
  /*   });
  }
 */
  /* clickVoiceDrop(value:string) {
    console.log("working: "+value);
    this.leadid=localStorage.getItem("LeadID");
    let vddata = value.split('-');
    console.log(vddata[0]);
    let to_number;
    switch (vddata[1])
    {
      case '1': {
        localStorage.setItem('current_number', this.cdata.candidate_phone);
        localStorage.setItem('number_type', 'mdi-cellphone');
        to_number=this.cdata.candidate_phone;
        break;
      }
     
    }
    let sdata={id_lead:this.leadid, id_message:0, audio:vddata[0], to_number:to_number, from_number:this.callerid};
  //  this.edata.setAppmod("custom/vdmsg");
    this.edata.saveService(JSON.stringify(sdata)).subscribe( rs =>  {
      console.log("Save SMS data");
      console.log(rs);
      this.openSnackBar(rs.message,'');
    }); */

    //this.ddsocket.openSocket("voicedrop", JSON.stringify({phone:'917532973604', callerid:this.callerid, account:this.extaccount, campaign:this.currscript, lead:this.leadid, vdmsg:value}));
    /* this.ddsocket.openSocket("voicedrop", JSON.stringify({phone:to_number, callerid:this.callerid, account:this.extaccount, campaign:this.currscript, lead:this.leadid, vdmsg:vddata[0]})); */
  /* } */


  ngOnInit() {  
    /* this.cdata= new EditPersonalForm(null);
    this.form=this.cdata.makeform(this.fb); */
    this.form=null;
    //this.sipdata.RegisterUA(localStorage.getItem("exten"),localStorage.getItem("password"));    
    /* this.solution.subscribe(res=>{
      console.log("Changed data on edit "+res);
      if(res==null)
      {
        this.cdata= new EditPersonalForm(null);
        this.form=this.cdata.makeform(this.fb);
      }
      else
      {
        this.cdata= new EditPersonalForm(res);
        this.form=this.cdata.makeform(this.fb);
        if(this.cdata.candidate_phone)
          this.cellnostatus.next(true);
     
      }
    }); */

    this.theme.event.subscribe(res=>{
      console.log("Current changed dial type is : "+res);

      console.log(this.cdata);
      if(res==1)
      {
        this.leadid=localStorage.getItem("LeadID");
        this.__click2dial=false;
        this.__hangup=false;
        //this.ddsocket.openSocket("autodial", JSON.stringify({phone:'917532973604', callerid:this.callerid, account:this.extaccount, campaign:this.currscript, lead:this.leadid, phone2:'917532973604', phone3:'917532973604'}));
        let cellnos = this.cdata.candidate_phone;
        if(!cellnos)
          cellnos = '';
        
        
        /* this.ddsocket.openSocket("autodial", JSON.stringify({phone:cellnos, callerid:this.callerid, account:this.extaccount, campaign:this.currscript, lead:this.leadid, })); */

        console.log("cellnos: "+ cellnos);
      }
      else if(res==0)
      {
        /* this.ddsocket.openSocket("hangup", this.extuser); */
        this.__click2dial=true;
        this.__hangup=true;
      }
      else
      {
        this.__click2dial=false;
        this.__hangup=false;
      }
    });

    // this.edata.getEmailtmp().subscribe(res => {
    //   console.log("All EmailTemplate Option Loaded");
    //   console.log(res);
    //   this.emailtemp=res;
    // })

    // this.edata.getSmstmp().subscribe(res => {
    //   console.log("All SMS Template Option Loaded");
    //   console.log(res);
    //   this.smstemp=res;
    // })

    // this.edata.getVoiceDrop().subscribe(res => {
    //   console.log("All VoiceDrop Messgae Option Loaded");
    //   console.log(res);
    //   this.vdmsg=res;
    // })
  }

  onSubmit()
  {
    if(this.cdata.saveForm(this.form))
      super.onSubmit();
  }


  makeCall()
  {
    
     var phone = this.form.get("candidate_phone").value;
    this.sipdata.MakeOutboundCall(phone);
    this.sipdata.RecieveSipjsEvents(); 
   
  }

  Hangup() {
    var phone = this.form.get("candidate_phone").value;
    this.sipdata.HangupCall(phone);
  }

  warning()
  {
    if(this.cdata.candidate_name != this.form.get("candidate_name").value || this.cdata.candidate_phone != this.form.get("candidate_phone").value || this.cdata.candidate_phone != this.form.get("candidate_phone").value || this.cdata.job_title != this.form.get("job_title").value || this.cdata.City != this.form.get("City").value || this.cdata.State != this.form.get("State").value || this.cdata.Zip != this.form.get("Zip").value  || this.cdata.candidate_email != this.form.get("candidate_email").value)
      alert('click on plain place of panel to save data')
  }

  changeEvnt(key:string)
  { 
    console.log(key);
    if(key == 'candidate_name')
      this.cdata.candidate_name = this.form.get("candidate_name").value;
    if(key == 'City')
      this.cdata.City = this.form.get("City").value;
    if(key == 'State')
      this.cdata.State = this.form.get("State").value;
    if(key == 'Zip')
      this.cdata.Zip = this.form.get("Zip").value;
    if(key == 'candidate_phone')
      this.cdata.candidate_phone = this.form.get("candidate_phone").value;
    if(key == 'home_loc')
      this.cdata.home_loc = this.form.get("home_loc").value;
    if(key == 'resume_title')
      this.cdata.resume_title = this.form.get("resume_title").value;
    if(key == 'job_title')
      this.cdata.job_title = this.form.get("job_title").value;
    if(key == 'candidate_email')
      this.cdata.candidate_email = this.form.get("candidate_email").value;
    if(key == 'recent_comp')
      this.cdata.recent_comp = this.form.get("recent_comp").value;
    if(key == 'recent_post')
      this.cdata.recent_post = this.form.get("recent_post").value;
    if(key == 'recent_pay')
      this.cdata.recent_pay = this.form.get("recent_pay").value;
    if(key == 'degree')
      this.cdata.degree = this.form.get("degree").value;

    if(key == 'candidate_phone')
    {
      let cellnum = this.form.get("candidate_phone").value;
      if(cellnum)
      {
        var numberPattern = /\d+/g;
        let cellno = (cellnum).match(numberPattern);
        cellnum = '';
        cellno.forEach(val=>{
            cellnum += val;
        })
        if(cellnum.length==10)
            cellnum = '1'+cellnum;
      }
      this.cdata.candidate_phone =cellnum;
      //this.cdata.cell_phone = this.form.get("cell_phone").value; 
    }  
    
    if(this.cdata.candidate_phone)
      this.cellnostatus.next(true);
    else
      this.cellnostatus.next(false);

    
    localStorage.setItem("editpersonal", JSON.stringify(this.cdata));
    console.log("OnChange events called edit data : Key "+key+" Value :"+this.form.get(key).value);
    console.log(localStorage.getItem("editpersonal"));
  }
}

