import { Component, AfterViewInit, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { APIURL } from '../../app-routing.module';
import { AuthenticationService } from '../../authentication/authentication.service';
import { Theme } from '../service/AutoTheme';
import { id } from '@swimlane/ngx-datatable/release/utils';

@Component({
  selector: 'ap-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']

  
})

export class SidebarComponent implements OnInit, AfterViewInit {
	//this is for the open close
    isActive: boolean = true;
    showMenu: string = '';
    showSubMenu: string = '';
    usertype:boolean=false;

    @Output()  dialdisposition = new EventEmitter();

    constructor(protected router:Router, protected auth:AuthenticationService){}

    ngOnInit()
    {
                
        
        if(localStorage.getItem('type')=="2")
            this.usertype=true;
        else
            this.usertype = false;

    }
    
    addExpandClass(element: any) {
        if (element === this.showMenu) {
            this.showMenu = '0';
        } else {
            this.showMenu = element; 
        }
    }
    addActiveClass(element: any) {
        if (element === this.showSubMenu) {
            this.showSubMenu = '0';
        } else {
            this.showSubMenu = element; 
        }
    }
    eventCalled() {
        this.isActive = !this.isActive;
        
    }
    // End open close
    ngAfterViewInit() { 
        $(function () {

            $(".sidebartoggler").on('click', function () {
                if ($("body").hasClass("mini-sidebar")) {
                    $("body").trigger("resize");
                    $(".scroll-sidebar, .slimScrollDiv").css("overflow", "hidden").parent().css("overflow", "visible");
                    $("body").removeClass("mini-sidebar");
                    $('.navbar-brand span').show();
                    $("app-rightview aside").removeClass("postion");  //using for hide menu on roght view
                      //  console.log("value  valid");  //     using for hide menu on roght view
                   $('.subhide-menu').hide();  //  using for hide menu on roght view
                   $('.right_status').hide(); //  using for hide menu on roght view
                   // console.log("value invalid");
                    //$(".sidebartoggler i").addClass("ti-menu");
                }
                else {
                    $("body").trigger("resize");
                    $(".scroll-sidebar, .slimScrollDiv").css("overflow-x", "visible").parent().css("overflow", "visible");
                    $("body").addClass("mini-sidebar");
                    $('.navbar-brand span').hide();
                   // $('.right').hide();
               

                    $("app-rightview aside").removeClass("postion");
                    //$(".sidebartoggler i").removeClass("ti-menu");
                }
            });

        });
    }

    UserLogout()
    {
        this.auth.logout();
        /* localStorage.removeItem('LeadID'); 
        localStorage.removeItem('minutes');
        localStorage.removeItem('callerid');
        localStorage.removeItem('comment');
        localStorage.removeItem('contacts');
        localStorage.removeItem("current_lead");
        localStorage.removeItem("current_number");
        localStorage.removeItem("currscript");
        localStorage.removeItem('edit');
        localStorage.removeItem('extaccount');
        localStorage.removeItem('extpass');
        localStorage.removeItem('extuser');
        localStorage.removeItem('firstname');
        localStorage.removeItem('isLoggedin'); 
        localStorage.removeItem('number_type'); 
        localStorage.removeItem('personaldata');
        localStorage.removeItem('preferences');
        localStorage.removeItem('scripts');
        localStorage.removeItem('token');
        localStorage.removeItem('type');
        this.router.navigate(["login"]); */
    }

    onClick(num:number) {
        let classname: String = ".btn" + num;
        if (num === 6) {
            let c = confirm('Do you really want to make this client?');
            if (c) {
                $(classname).click();
            }
        }
        else {
            $(classname).click();
        }
        
    }
  

}
