import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar"; // {{ edit_1 }}

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { MortgageService } from "../../../../mortgage/mortgage.service";

@Component({
    selector: 'message_dialog',
    templateUrl: './message_dialog.component.html',
    styleUrls: ['./message_dialog.component.css']
})

export class Message_DialogComponent {

    
    SMStempate:any[];
    selectedTemplate: string = '';

@ViewChild('selectElement') selectElement: ElementRef;
  @ViewChild('textareaElement') textareaElement: ElementRef;

  
  ngAfterViewInit() {
    this.selectElement.nativeElement.addEventListener('change', () => {
      const selectedTemplate = this.SMStempate.find(template =>template.id === Number(this.selectElement.nativeElement.value));
      if (selectedTemplate) {
        this.textareaElement.nativeElement.value = selectedTemplate.template;
      }
    });
  }

    constructor(public dialogRef: MatDialogRef<Message_DialogComponent>,  @Inject(MAT_DIALOG_DATA) public data: any,private mortgageService: MortgageService,private snackBar: MatSnackBar ){
       console.log(data)
    }
    ngOnInit(): void{


        this.mortgageService.getSMStempateuserid().subscribe(SMStempate=>{
            this.SMStempate = SMStempate
             //alert(JSON.stringify(this.SMStempate));
          });
    }

    onSubmit() {
        const data = {
            "id_agent":localStorage.getItem("userid"),
            "id_lead":localStorage.getItem("LeadID"),
            "to_number":"+"+this.data.phone,
            "from_number":"+19415386460",
            "msg_body":this.textareaElement.nativeElement.value,
            "message_sid":"",
            "characters":"",
            "minutes":"",
            "status":""
        }
       this.mortgageService.sendSMS(data).subscribe(response => {
            this.snackBar.open('SMS sent successfully!', 'Close', { // {{ edit_3 }}
                duration: 2000,
            });
            this.dialogRef.close();
        }, error => { // {{ edit_4 }}
            this.snackBar.open('Failed to send SMS. Please try again.', 'Close', {
                duration: 2000,
            });
            console.error('Error sending SMS:', error); // Log the error for debugging
        });
        

    }
}