import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.css']
})
export class PackageComponent implements OnInit {
  @Input() data: any[]; 
  @Output()  package : EventEmitter<number> = new EventEmitter();

  list:any;
  selected :any;
  item : any;
  activepkg:any;
  public newarr=[];
  constructor() {  }

  select(item) {
      this.selected = item; 
  };

  isActive(id) {
      return this.selected === id;
  };

  selectedpkg(rowdata:any)
  {
    this.isActive(rowdata.id);
    this.package.emit(rowdata);
  }
  
  ngOnInit() {
    

  }

  
}
